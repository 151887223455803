import { useMemo, useState } from "react";
import "./ProgressBar.css";

export interface IProgressBarProps {
    value: number;
    className?: string;
}

const ProgressBar = ({ value, className }: IProgressBarProps) => {
    return (
        <div className={className || "shpiguni-progress-bar-wrap"}>
            <div
                className="shpiguni-progress-bar"
                style={{ width: `${value}%` }}
            ></div>
        </div>
    );
};

export default ProgressBar;
