import PlayerColor from "../PlayerColor";
import { IGuessCandidate, IPlayerUser } from "../../store/entities/game/model";
import "./EndTurnButton.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProgressBar from "../ProgressBar";
import { useTranslation } from "react-i18next";

interface IEndTurnButton {
    voteSkipPlayers: IPlayerUser[];
    onClick?: () => void;
    disabled: boolean;
    className?: string;
    timeLeft: number;
    guessCandidate: IGuessCandidate | null;
    guessConfirmationSeconds: number;
}

const EndTurnButton = ({ voteSkipPlayers, ...props }: IEndTurnButton) => {
    const { t } = useTranslation();

    const [prevPlayers, setPrevPlayers] = useState<IPlayerUser[]>([]);
    const [animation, setAnimation] = useState<boolean>(false);
    const [guessProgress, setGuessProgress] = useState<number | null>(null);

    useEffect(() => {
        if (voteSkipPlayers.length > prevPlayers.length) {
            if (animation) {
                setPrevPlayers(voteSkipPlayers);
                setAnimation(false);
                const timeout = setTimeout(() => {
                    setAnimation(true);
                }, 10);
                return () => {
                    clearTimeout(timeout);
                };
            } else {
                setAnimation(true);
            }
        } else {
            setAnimation(false);
        }
        setPrevPlayers(voteSkipPlayers);
    }, [JSON.stringify(voteSkipPlayers)]);

    const className = useMemo(() => {
        let classes = "end-turn-btn game-bottom-input unselectable";
        if (!props.disabled) {
            classes = `${classes} end-turn-btn-enabled`;
        }
        if (props.className) {
            classes = `${classes} ${props.className}`;
        }
        if (animation) {
            classes = `${classes} pulse`;
        }
        return classes;
    }, [props.disabled, animation]);

    const onClick = useCallback(() => {
        if (props.disabled) {
            return;
        }
        props.onClick?.();
    }, [props.disabled, props.onClick]);

    const isCandidate = useMemo(() => {
        return props.guessCandidate && props.guessCandidate.word == null;
    }, [props.guessCandidate]);

    useEffect(() => {
        if (props.guessCandidate && isCandidate) {
            const period = props.guessConfirmationSeconds;
            const guessCandidateTimeLeft = props.guessCandidate?.timeLeft || 0;
            const progress =
                (guessCandidateTimeLeft - props.timeLeft) / (period * 10);
            if (progress < 100) {
                setGuessProgress(progress || 0);
            } else {
                setGuessProgress(100);
            }
        } else {
            setGuessProgress(null);
        }
    }, [
        isCandidate,
        props.guessCandidate,
        props.timeLeft,
        props.guessConfirmationSeconds,
    ]);

    return (
        <div className={className} onClick={onClick}>
            {t("end of turn", { ns: "lobby" })}

            {guessProgress !== null && (
                <ProgressBar
                    value={guessProgress}
                    className="end-turn-progress"
                />
            )}

            <div className="end-turn-votes">
                {voteSkipPlayers.map((p) => {
                    return <PlayerColor key={p.id} player={p} />;
                })}
            </div>
        </div>
    );
};

export default EndTurnButton;
