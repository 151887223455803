import { createAction, unwrapResult } from "@reduxjs/toolkit";
import {
    GameEventType,
    IClueApi,
    IGameEvent,
    IGameEventApi,
    IPlayer,
    IPlayerApi,
    IScoreApi,
    IWordApi,
    IWordVoteApi,
} from "./model";
import { AppDispatch, RootState } from "../../store";
import { selectUsersById } from "../users/selectors";
import { getUsers } from "../users/thunk";
import { selectLobbyById } from "../lobby/selectors";

export const lobbyGameHeartbeat = createAction("game/lobbyGameHeartbeat");
export const lobbyGameEvent = createAction<IGameEvent>("game/lobbyGameEvent");
export const serverClockOffset = createAction<number>("game/serverClockOffset");

export const lobbyGameEventReceived = (event: IGameEventApi) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        if (
            event.type !== GameEventType.game_state_updated &&
            event.type !== GameEventType.game_finished
        ) {
            return;
        }
        const state = getState();
        const lobbyStore = selectLobbyById(event.lobby_id)(state);
        const lobbyStorePlayers = (lobbyStore && lobbyStore.players) || [];
        const exIds = lobbyStorePlayers.map((pl) => pl.id);
        const newIds = event.data.players
            .map((p: IPlayerApi) => p.id)
            .filter((id: string) => !exIds.includes(id));
        let promise = null;
        if (newIds.length > 0) {
            promise = dispatch(getUsers(newIds)).then(unwrapResult);
        } else {
            promise = Promise.resolve();
        }
        promise.then(() => {
            const usersById = selectUsersById(getState());
            const event_data = {
                words: event.data.words.map((word: IWordApi) => {
                    return {
                        text: word.text,
                        teamId: word.team_id,
                        isActive: word.is_active,
                    };
                }),
                players: event.data.players.map((p: IPlayerApi) => {
                    const u = usersById[p.id];
                    return {
                        id: p.id,
                        teamId: p.team_id,
                        isConnected: p.is_connected,
                        isSpyMaster: p.is_master,
                        username: u.username,
                        color: u.color,
                    };
                }),
                wordVotes: event.data.word_votes.map((vote: IWordVoteApi) => {
                    return {
                        playerId: vote.player_id,
                        word: vote.word,
                    };
                }),
                skipVotes: event.data.skip_votes,
                clues: event.data.clues.map((clue: IClueApi) => {
                    return {
                        teamId: clue.team_id,
                        text: clue.text,
                    };
                }),
                score: event.data.score.map((score: IScoreApi) => {
                    return {
                        teamId: score.team_id,
                        score: score.score,
                    };
                }),
                gamePhase: event.data.game_phase,
                activeTeamId: event.data.active_team_id,
                gameClock: event.data.game_clock,
                timeLeft: event.data.time_left * 1000,
                isPaused: event.data.is_paused,
                isFinished: event.data.is_finished,
                isTeamLocked: event.data.is_team_locked,
                guessCandidate: event.data.guess_candidate && {
                    word: event.data.guess_candidate.word,
                    timeLeft: event.data.guess_candidate.time_left * 1000,
                },
                guessConfirmationSeconds: event.data.guess_confirmation_seconds,
                clueCanTakeGuessTime: event.data.clue_can_take_guess_time,
                clueHasTakenGuessTime: event.data.clue_has_taken_guess_time,
                winnerTeamId: event.data.winner_team_id,
                turnNumber: event.data.turn_number,
            };
            dispatch(
                lobbyGameEvent({
                    lobbyId: event.lobby_id,
                    matchId: event.match_id,
                    type: event.type,
                    data: event_data,
                    timestamp: event.timestamp,
                })
            );
        });
    };
};
