import { ApiService } from "../../../services/apiService";
import { createAsyncApiThunk } from "../../utils";
import { IReplayGameState } from "./model";

import { unwrapResult } from "@reduxjs/toolkit";
import {
    GameEventType,
    IClueApi,
    IGameEvent,
    IGameEventApi,
    IPlayer,
    IPlayerApi,
    IScoreApi,
    IWordApi,
    IWordVoteApi,
} from "../game/model";
import { selectUsersById } from "../users/selectors";
import { getUsers } from "../users/thunk";

interface IGetMatchEvents {
    matchId: string;
}

export const getMatchEvents = createAsyncApiThunk(
    "getMatchEvents",
    async ({ matchId }: IGetMatchEvents, thunkAPI) => {
        const service = ApiService.fromConfig();
        const response = await service.getMatchEvents(matchId);
        const apiGameEvents = response
            .map((ev) => {
                const data = JSON.parse(ev.data) as IReplayGameState;
                return {
                    lobby_id: "43cbe605-4f47-46b8-9054-14710f0815b3",
                    match_id: matchId,
                    type: ev.eventType as GameEventType,
                    data: data,
                    timestamp: data.game_clock * 1000,
                };
            })
            .sort((el1, el2) => {
                const diff = el1.data.game_clock - el2.data.game_clock;
                if (diff === 0) {
                    if (el1.type === GameEventType.game_finished) {
                        return 1;
                    } else if (el2.type === GameEventType.game_finished) {
                        return -1;
                    }

                    if (el1.type === GameEventType.candidate_changed) {
                        return 1;
                    } else if (el2.type === GameEventType.candidate_changed) {
                        return -1;
                    }

                    if (el1.type === GameEventType.phase_changed) {
                        return 1;
                    } else if (el2.type === GameEventType.phase_changed) {
                        return -1;
                    }
                }
                return diff;
            });

        const events = await getEventsFromApiEvents(apiGameEvents, thunkAPI);
        return {
            matchId: matchId,
            events: events,
        };
    }
);

const getEventsFromApiEvents = (
    events: IGameEventApi[],
    thunkAPI: any
): Promise<IGameEvent[]> => {
    const players: IPlayerApi[] = [];
    events.map((ev) => ev.data.players).forEach((el) => players.push(...el));
    const newIds: string[] = players.map((p) => p.id);
    let promise: Promise<any>;
    if (newIds.length > 0) {
        promise = thunkAPI.dispatch(getUsers(newIds)).then(unwrapResult);
    } else {
        promise = Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        promise.then(() => {
            const usersById = selectUsersById(thunkAPI.getState());
            const replayEvents = events
                .map((event) => {
                    return {
                        lobbyId: event.lobby_id,
                        matchId: event.match_id,
                        type: event.type,
                        data: {
                            words: event.data.words.map((word: IWordApi) => {
                                return {
                                    text: word.text,
                                    teamId: word.team_id,
                                    isActive: word.is_active,
                                };
                            }),
                            players: event.data.players.map((p: IPlayerApi) => {
                                const u = usersById[p.id];
                                return {
                                    id: p.id,
                                    teamId: p.team_id,
                                    isConnected: p.is_connected,
                                    isSpyMaster: p.is_master,
                                    username: u.username,
                                    color: u.color,
                                };
                            }),
                            wordVotes: event.data.word_votes.map(
                                (vote: IWordVoteApi) => {
                                    return {
                                        playerId: vote.player_id,
                                        word: vote.word,
                                    };
                                }
                            ),
                            skipVotes: event.data.skip_votes,
                            clues: event.data.clues.map((clue: IClueApi) => {
                                return {
                                    teamId: clue.team_id,
                                    text: clue.text,
                                };
                            }),
                            score: event.data.score.map((score: IScoreApi) => {
                                return {
                                    teamId: score.team_id,
                                    score: score.score,
                                };
                            }),
                            gamePhase: event.data.game_phase,
                            activeTeamId: event.data.active_team_id,
                            gameClock: event.data.game_clock,
                            timeLeft: event.data.time_left * 1000,
                            isPaused: event.data.is_paused,
                            isFinished: event.data.is_finished,
                            isTeamLocked: event.data.is_team_locked,
                            guessCandidate: event.data.guess_candidate && {
                                word: event.data.guess_candidate.word,
                                timeLeft:
                                    event.data.guess_candidate.time_left * 1000,
                            },
                            guessConfirmationSeconds:
                                event.data.guess_confirmation_seconds,
                            clueCanTakeGuessTime:
                                event.data.clue_can_take_guess_time,
                            clueHasTakenGuessTime:
                                event.data.clue_has_taken_guess_time,
                            winnerTeamId: event.data.winner_team_id,
                            turnNumber: event.data.turn_number,
                        },
                        timestamp: event.timestamp,
                    };
                })
                .sort((a, b) => a.timestamp - b.timestamp);
            resolve(replayEvents);
        });
    });
};
