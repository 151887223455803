import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { ApiService, IUserResponse } from "../../../services/apiService";
import { RootState } from "../../store";
import { selectUsersById } from "./selectors";

export const getUsers = createAsyncThunk(
    "getUsers",
    async (userIds: string[]) => {
        const service = ApiService.fromConfig();
        const response = await service.getUsers(userIds);
        return response as IUserResponse[];
    }
);

interface IGetUsersCached {
    maxAge: number;
    userIds: string[];
}

export const getUsersCached = createAsyncThunk(
    "getUsersCached",
    async ({ userIds, maxAge }: IGetUsersCached, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const userState = selectUsersById(state);
        const now = Date.now();

        const newIds = userIds.filter((userId) => {
            const user = userState[userId];
            return user === undefined || now - user.timestamp > maxAge;
        });
        const exIds = userIds.filter((userId) => !newIds.includes(userId));

        let promise: Promise<IUserResponse[]>;
        if (newIds.length > 0) {
            promise = new Promise((resolve, reject) => {
                thunkAPI
                    .dispatch(getUsers(newIds))
                    .then(unwrapResult)
                    .then((response) => {
                        resolve([
                            ...response,
                            exIds.map(
                                (userId) => userState[userId] as IUserResponse
                            ),
                        ] as IUserResponse[]);
                    });
            });
        } else {
            promise = Promise.resolve(
                userIds.map((userId) => userState[userId] as IUserResponse)
            );
        }
        const result = await promise;
        return result as IUserResponse[];
    }
);
