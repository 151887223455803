import { IStore } from "../../store";

export const selectUsersStore = (state: IStore) => state.users;

export const selectIsUsersLoading = (state: IStore) =>
    selectUsersStore(state).isLoading;

export const selectUsersById = (state: IStore) =>
    selectUsersStore(state).data.userById;

export const selectUserById = (userId: string) => (state: IStore) =>
    selectUsersStore(state).data.userById[userId];
