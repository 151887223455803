import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
    GameEventType,
    IClue,
    IGameEvent,
    IGuessCandidate,
    IPlayerUser,
    IWord,
    IWordVote,
} from "../game/model";
import { getMatchEvents } from "./thunk";
import { WritableDraft } from "immer/dist/internal";
import { lobbyGameHeartbeat } from "../game/actions";
import {
    replayProgressChange,
    replaySetPause,
    replaySetTurn,
    resetReplay,
} from "./action";
import { GamePhase } from "../game/model";

interface ReplayState {
    matchId: string | null;
    turnNumber: number;
    serverGameClockStart: number;
    gameClockStart: number;
    gameClockOffset: number;

    replayLength: number;
    replayProgress: number;
    replayPauseGameClock: number;
    replayPausedTime: number;
    isReplayPaused: boolean;

    events: IGameEvent[] | null;
    currentEventNumber: number;

    players: IPlayerUser[];
    words: {
        list: IWord[];
    };
    wordVotes: {
        list: IWordVote[];
    };
    skipVotes: {
        list: string[];
    };
    clues: {
        list: IClue[];
    };
    score: Record<number, number>;
    gamePhase: string;
    activeTeamId: number;
    winnerTeamId: number | null;
    serverGameClock: number;
    serverTimeLeft: number;
    gameClock: number;
    timeLeft: number;
    isPaused: boolean;
    isFinished: boolean;
    clueCanTakeGuessTime: boolean;
    clueHasTakenGuessTime: boolean;
    guessCandidate: IGuessCandidate | null;
    guessConfirmationSeconds: number;
}

const initialState = {
    matchId: null,
    turnNumber: 0,
    serverGameClockStart: 0,
    gameClockStart: 0,
    gameClockOffset: 0,
    replayLength: 0,
    replayProgress: 0,
    replayPauseGameClock: 0,
    replayPausedTime: 0,
    isReplayPaused: false,

    players: [],
    events: null,
    currentEventNumber: 0,

    guessCandidate: null,
    words: {
        list: [] as IWord[],
    },
    wordVotes: {
        list: [],
    },
    skipVotes: {
        list: [],
    },
    clues: {
        list: [] as IClue[],
    },
    score: {},
    gamePhase: "hint",
    activeTeamId: 0,
    winnerTeamId: null,
    serverGameClock: 0,
    serverTimeLeft: 0,
    gameClock: 0,
    timeLeft: 0,
    isPaused: false,
    isFinished: true,
    guessConfirmationSeconds: 2,
    clueCanTakeGuessTime: false,
    clueHasTakenGuessTime: false,
} as ReplayState;

const replayReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(lobbyGameHeartbeat, (state, action) => {
            if (!state.matchId) {
                return;
            }
            const now = Date.now();
            if (state.isReplayPaused) {
                const replayPausedTime = now - state.replayPauseGameClock;
                return {
                    ...state,
                    replayPauseGameClock: now,
                    replayPausedTime: state.replayPausedTime + replayPausedTime,
                    gameClockOffset: state.gameClockOffset - replayPausedTime,
                };
            }
            const timeElapsed =
                now - state.gameClockStart + state.gameClockOffset;
            const newState = checkNewEvent(state, timeElapsed);
            if (!newState.isFinished) {
                newState.replayProgress = Math.floor(
                    (100 * timeElapsed) / newState.replayLength
                );
            }
            if (!newState.isPaused && !newState.isFinished) {
                newState.timeLeft = getLocalTimeLeft(
                    newState.gameClock + newState.replayPausedTime,
                    newState.serverTimeLeft
                );
            }
            return newState;
        })
        .addCase(replaySetPause, (state, action) => {
            return {
                ...state,
                isReplayPaused: action.payload,
                replayPauseGameClock: action.payload ? Date.now() : 0,
            };
        })
        .addCase(replayProgressChange, (state, action) => {
            const newProgress = action.payload;
            const offset = (newProgress * state.replayLength) / 100;
            const newSate = {
                ...state,
                gameClockOffset: offset,
                gameClockStart: Date.now(),
                replayProgress: newProgress,
            };
            return newSate;
        })
        .addCase(getMatchEvents.fulfilled, (state, action) => {
            const data = action.payload;
            const events = data.events.filter(
                (ev) =>
                    ev.type === GameEventType.game_finished ||
                    !ev.data.isFinished
            );
            const newState = {
                ...initialState,
                matchId: data.matchId,
                players: [],
                serverGameClockStart: 0,
                gameClockStart: Date.now(),
                replayLength: 0,
                events: events,
            };
            if (events.length > 0) {
                const firstState = events[0];
                const lastState = events[events.length - 1];
                newState.currentEventNumber = 0;
                newState.serverGameClockStart = firstState.data.gameClock;
                newState.replayLength =
                    lastState.data.gameClock - newState.serverGameClockStart;
                const event = events[0] as IGameEvent;
                return handleSync(newState, event);
            }
            return newState;
        })
        .addCase(replaySetTurn, (state, action) => {
            const newState = checkTurnNumberEvent(state, action.payload);
            newState.isReplayPaused = true;
            return newState;
        })
        .addCase(resetReplay, (state, action) => {
            return initialState;
        });
});

function handleSync(state: WritableDraft<ReplayState>, event: IGameEvent) {
    const newState = {
        ...state,
        matchId: event.matchId,
        lobbyId: event.lobbyId,
        turnNumber: event.data.turnNumber,
        words: { list: event.data.words },
        wordVotes: { list: event.data.wordVotes },
        skipVotes: { list: event.data.skipVotes },
        clues: { list: event.data.clues },
        guessCandidate: event.data.guessCandidate,
        guessConfirmationSeconds: event.data.guessConfirmationSeconds,
        clueCanTakeGuessTime: event.data.clueCanTakeGuessTime,
        clueHasTakenGuessTime: event.data.clueHasTakenGuessTime,
        gamePhase: event.data.gamePhase,
        activeTeamId: event.data.activeTeamId,
        winnerTeamId: event.data.winnerTeamId,
        isPaused: event.data.isPaused,
        isFinished: event.data.isFinished,
        score: {},
        players: event.data.players,
        serverTimeLeft: event.data.timeLeft,
        timeLeft: event.data.timeLeft,
        serverGameClock: event.data.gameClock,
        gameClock: Date.now(),
        replayPausedTime: 0,
    } as ReplayState;
    event.data.score.forEach((team) => {
        newState.score[team.teamId] = team.score;
    });
    return newState;
}

const getNewEventNumber = (
    state: WritableDraft<ReplayState>,
    timeElapsed: number
): number | undefined => {
    const targetServerGameClock = state.serverGameClockStart + timeElapsed;
    let newEventNumber = 0;
    state.events?.forEach((ev, index) => {
        const event = ev as IGameEvent;
        if (event.data.gameClock < targetServerGameClock) {
            newEventNumber = index;
        }
    });
    if (newEventNumber !== state.currentEventNumber && state.events) {
        return newEventNumber;
    }
};

const checkNewEvent = (
    state: WritableDraft<ReplayState>,
    timeElapsed: number
): ReplayState => {
    const newEventNumber = getNewEventNumber(state, timeElapsed);
    if (newEventNumber !== undefined && state.events) {
        const newEvent = state.events[newEventNumber] as IGameEvent;
        const newState = handleSync(state, newEvent);
        newState.currentEventNumber = newEventNumber;
        newState.replayProgress = Math.floor(
            (timeElapsed * 100) / state.replayLength
        );
        return newState;
    }
    return state;
};

const getTurnNumberEvent = (
    state: WritableDraft<ReplayState>,
    turnNumber: number
): number | undefined => {
    let newEventNumber = 0;
    state.events?.forEach((ev, index) => {
        const event = ev as IGameEvent;
        if (
            newEventNumber == 0 &&
            event.data.turnNumber === turnNumber &&
            event.data.gamePhase == GamePhase.guess
        ) {
            newEventNumber = index;
        }
    });
    if (newEventNumber !== state.currentEventNumber && state.events) {
        return newEventNumber;
    }
};

const checkTurnNumberEvent = (
    state: WritableDraft<ReplayState>,
    turnNumber: number
): ReplayState => {
    const newEventNumber = getTurnNumberEvent(state, turnNumber);
    if (newEventNumber !== undefined && state.events) {
        const newEvent = state.events[newEventNumber] as IGameEvent;
        const newState = handleSync(state, newEvent);
        newState.currentEventNumber = newEventNumber;
        const now = Date.now();
        const offset = newState.serverGameClock - state.serverGameClock;
        newState.gameClockOffset = newState.gameClockOffset + offset;
        newState.replayPauseGameClock = now;
        newState.replayPausedTime = newState.replayPausedTime - offset;
        newState.gameClock = newState.gameClock - newState.replayPausedTime;
        const timeElapsed =
            now - newState.gameClockStart + newState.gameClockOffset;
        newState.replayProgress = Math.floor(
            (100 * timeElapsed) / newState.replayLength
        );
        newState.timeLeft = getLocalTimeLeft(
            newState.gameClock + newState.replayPausedTime,
            newState.serverTimeLeft
        );
        return newState;
    }
    return state;
};

const getLocalTimeLeft = (
    gameClock: number,
    serverTimeLeft: number
): number => {
    const now = Date.now();
    const diff = now - gameClock;
    const timeLeft = serverTimeLeft - diff;
    if (timeLeft < 0) {
        return 0;
    }
    return timeLeft;
};

const isReplayLoadingReducer = createReducer(false, (builder) => {
    builder
        .addCase(getMatchEvents.pending, (state, action) => {
            return true;
        })
        .addCase(getMatchEvents.fulfilled, (state, action) => {
            return false;
        });
});

const reducer = combineReducers({
    data: replayReducer,
    isLoading: isReplayLoadingReducer,
});

export default reducer;
