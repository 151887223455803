import { IStore } from "../../store";

export const selectReplayStore = (state: IStore) => state.replay;

export const selectReplayData = (state: IStore) =>
    selectReplayStore(state).data;

export const selectIsReplayLoading = (state: IStore) =>
    selectReplayStore(state).isLoading;

export const filterByTeam = (teamId: number) => {
    const lambda = (item: any) => {
        return item.teamId == teamId;
    };
    return lambda;
};

export const selectReplayTeamPlayers = (teamId: number) => (state: IStore) => {
    const filter = filterByTeam(teamId);
    const replayState = selectReplayData(state);
    return replayState.players.filter(filter);
};

export const selectReplayTeamClues = (teamId: number) => (state: IStore) => {
    const filter = filterByTeam(teamId);
    return selectReplayData(state).clues.list.filter(filter);
};

export const selectReplayPlayerIds = (state: IStore) => {
    const replayState = selectReplayData(state);
    const storePlayers = (replayState && replayState.players) || [];
    const exIds = storePlayers.map((pl) => pl.id);
    return exIds;
};
