import { useMemo } from "react";
import "./MainMenuSelectedIcon.css";

interface MainMenuSelectedIconProsp {
    isActive?: boolean;
}

const MainMenuSelectedIcon = ({ isActive }: MainMenuSelectedIconProsp) => {
    const className = useMemo(() => {
        if (isActive) {
            return "bi bi-caret-right-fill main-menu-icon";
        }
        return "bi bi-caret-right-fill main-menu-icon hidden";
    }, [isActive]);
    return <i className={className}></i>;
};

export default MainMenuSelectedIcon;
