import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAuthStore } from "./entities/identity/selectors";
import {
    selectIsLobbyLoading,
    selectLobbyById,
    selectTeamPlayers,
} from "./entities/lobby/selectors";
import { selectGameStore, selectTeamClues } from "./entities/game/selectors";
import {
    GameNotificaitonType,
    GamePhase,
    IGameNotificaiton,
    IPlayerUser,
    IWordVote,
    PlayerState,
} from "./entities/game/model";
import { arrayToRecordById } from "../utils";
import turnAudioFile from "../assets/turn.mp3";
import guessAudioFile from "../assets/guess.mp3";
import { msInMinute } from "../constants";
import { AppDispatch } from "./store";
import { ApiService } from "../services/apiService";
import { loadAuth, refreshAuth } from "./entities/identity/thunk";
import {
    selectIsReplayLoading,
    selectReplayData,
    selectReplayTeamClues,
    selectReplayTeamPlayers,
} from "./entities/replay/selectors";

interface IUserPlayerVotes {
    player: IPlayerUser;
    wordVote: string | null;
    skipVote: boolean;
}

export const useLobbyGameState = (lobbyId: string) => {
    const authStore = useSelector(selectAuthStore);
    const isLobbyLoading = useSelector(selectIsLobbyLoading);
    const lobby = useSelector(selectLobbyById(lobbyId as string));
    const gameStore = useSelector(selectGameStore);

    const team1Players = useSelector(selectTeamPlayers(lobbyId, 1));
    const team2Players = useSelector(selectTeamPlayers(lobbyId, 2));
    const team1Clues = useSelector(selectTeamClues(1));
    const team2Clues = useSelector(selectTeamClues(2));

    const playerById = (lobby && arrayToRecordById(lobby.players)) || {};

    const votePlayers = gameStore.skipVotes.list.map(
        (playerId) => playerById[playerId]
    );
    const team1VoteSkipPlayers = votePlayers.filter((p) => p.teamId == 1);
    const team2VoteSkipPlayers = votePlayers.filter((p) => p.teamId == 2);

    let wordVotePlayers: Record<string, IPlayerUser[]> = {};
    gameStore.wordVotes.list.forEach((vote) => {
        if (wordVotePlayers[vote.word] === undefined) {
            wordVotePlayers[vote.word] = [];
        }
        const player = playerById[vote.playerId];
        if (player !== undefined) {
            wordVotePlayers[vote.word].push(player);
        }
    });

    const userPlayerVotes = useMemo(() => {
        const user = playerById[authStore.data.user && authStore.data.user.id];
        if (user === undefined) {
            return;
        }
        const skipVote = gameStore.skipVotes.list.includes(user.id);
        const wordVote = gameStore.wordVotes.list.filter((v) => {
            return v.playerId == user.id;
        })[0];
        return {
            player: user,
            wordVote: (wordVote && wordVote.word) || null,
            skipVote: skipVote,
        } as IUserPlayerVotes;
    }, [playerById, authStore.data.user && authStore.data.user.id]);

    const userPlayer = userPlayerVotes && userPlayerVotes.player;
    const playerState = useMemo(() => {
        if (gameStore.isFinished) {
            return PlayerState.Wait;
        }
        switch (gameStore.gamePhase) {
            case GamePhase.clue: {
                if (
                    userPlayer &&
                    userPlayer.isSpyMaster &&
                    userPlayer.teamId == gameStore.activeTeamId
                ) {
                    return PlayerState.Clue;
                }
                return PlayerState.Wait;
            }
            case GamePhase.guess: {
                if (
                    userPlayer &&
                    !userPlayer.isSpyMaster &&
                    userPlayer.teamId == gameStore.activeTeamId
                ) {
                    return PlayerState.Guess;
                }
                return PlayerState.WatchGuess;
            }
            default: {
                return PlayerState.Wait;
            }
        }
    }, [gameStore.isFinished, gameStore.gamePhase, userPlayer]);
    const getTeamListState = useCallback(
        (teamId: number) => {
            if (playerState == PlayerState.Wait) {
                return PlayerState.Wait;
            }
            if (gameStore.activeTeamId == teamId) {
                return playerState;
            }
            return PlayerState.Wait;
        },
        [gameStore.activeTeamId, playerState]
    );

    return [
        {
            isLobbyLoading: isLobbyLoading,
            isGameLoading: isLobbyLoading || gameStore.lobbyId === null,
            isPaused: gameStore.isPaused,
            hostUserId: lobby && lobby.hostUserId,
            matchId: lobby && lobby.matchId,
            gameServer: lobby && lobby.gameServer,
            userPlayerVotes: userPlayerVotes,
            team1Players: team1Players,
            team2Players: team2Players,
            team1Clues: team1Clues,
            team2Clues: team2Clues,
            team1VoteSkipPlayers: team1VoteSkipPlayers,
            team2VoteSkipPlayers: team2VoteSkipPlayers,
            wordVotePlayers: wordVotePlayers,
            isTeamLocked: (lobby && lobby.isTeamLocked) || false,
            playerState: playerState,
            team1ListState: getTeamListState(1),
            team2ListState: getTeamListState(2),
            lobbySettings: lobby && lobby.settings,
        },
    ];
};

const turnAudio = new Audio(turnAudioFile);
const guessAudio = new Audio(guessAudioFile);

export const useTurnAudio = (notification: IGameNotificaiton | null) => {
    useEffect(() => {
        switch (notification?.type) {
            case GameNotificaitonType.turn_changed: {
                guessAudio.currentTime = 0;
                guessAudio.pause();

                turnAudio.currentTime = 0;
                turnAudio.pause();
                turnAudio.volume = 0.5;
                turnAudio.play();
                return;
            }
            case GameNotificaitonType.word_guess: {
                turnAudio.currentTime = 0;
                turnAudio.pause();

                guessAudio.currentTime = 0;
                guessAudio.pause();
                guessAudio.volume = 0.1;
                guessAudio.play();
                return;
            }
        }
    }, [notification]);
};

export const useLoadAuth = (dispatch: AppDispatch) => {
    useEffect(() => {
        dispatch(loadAuth(false));
    }, []);

    const authStore = useSelector(selectAuthStore);

    const refreshTokens = useCallback(() => {
        if (!authStore.data.exp) {
            return;
        }
        if (ApiService.isExpiresSoon(authStore.data.exp)) {
            dispatch(refreshAuth(authStore.data.refresh_token));
        }
    }, [authStore.data.refresh_token, authStore.data.exp, dispatch]);

    useEffect(() => {
        const ptr = setInterval(refreshTokens, msInMinute);
        return () => clearInterval(ptr);
    }, [refreshTokens]);
};

export const useReplayGameState = () => {
    const isReplayLoading = useSelector(selectIsReplayLoading);
    const replay = useSelector(selectReplayData);

    const team1Players = useSelector(selectReplayTeamPlayers(1));
    const team2Players = useSelector(selectReplayTeamPlayers(2));
    const team1Clues = useSelector(selectReplayTeamClues(1));
    const team2Clues = useSelector(selectReplayTeamClues(2));

    const playerById = (replay && arrayToRecordById(replay.players)) || {};

    const votePlayers = replay.skipVotes.list.map(
        (playerId) => playerById[playerId]
    );
    const team1VoteSkipPlayers = votePlayers.filter((p) => p.teamId == 1);
    const team2VoteSkipPlayers = votePlayers.filter((p) => p.teamId == 2);

    let wordVotePlayers: Record<string, IPlayerUser[]> = {};
    replay.wordVotes.list.forEach((vote) => {
        if (wordVotePlayers[vote.word] === undefined) {
            wordVotePlayers[vote.word] = [];
        }
        const player = playerById[vote.playerId];
        if (player !== undefined) {
            wordVotePlayers[vote.word].push(player);
        }
    });

    const playerState = useMemo(() => {
        if (replay.isFinished) {
            return PlayerState.Wait;
        }
        switch (replay.gamePhase) {
            case GamePhase.guess: {
                return PlayerState.WatchGuess;
            }
            default: {
                return PlayerState.Wait;
            }
        }
    }, [replay.isFinished, replay.gamePhase]);
    const getTeamListState = useCallback(
        (teamId: number) => {
            if (playerState == PlayerState.Wait) {
                return PlayerState.Wait;
            }
            if (replay.activeTeamId == teamId) {
                return playerState;
            }
            return PlayerState.Wait;
        },
        [replay.activeTeamId, playerState]
    );

    return [
        {
            isLoading: isReplayLoading,
            team1Players: team1Players,
            team2Players: team2Players,
            team1Clues: team1Clues,
            team2Clues: team2Clues,
            team1VoteSkipPlayers: team1VoteSkipPlayers,
            team2VoteSkipPlayers: team2VoteSkipPlayers,
            wordVotePlayers: wordVotePlayers,
            playerState: playerState,
            team1ListState: getTeamListState(1),
            team2ListState: getTeamListState(2),
            replayProgress: replay.replayProgress,
            events: replay.events,
            isReplayPaused: replay.isReplayPaused,
            isGamePaused: replay.isPaused,
        },
    ];
};
