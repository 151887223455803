import { useTranslation } from "react-i18next";

import "./PlayerPageMenu.css";
import routes from "../../routes";
import { useMemo } from "react";
import { useNavigate } from "react-router";

export enum PlayerPageMenuState {
    overview,
    matchHistory,
}

interface PlayerPageMenuProps {
    playerId: string;
    state: PlayerPageMenuState;
}

class MenuItem {
    menuState: PlayerPageMenuState;
    route: string;
    text: string;

    constructor(menuState: PlayerPageMenuState, route: string, text: string) {
        this.menuState = menuState;
        this.route = route;
        this.text = text;
    }
}

export const PlayerPageMenu = ({ playerId, state }: PlayerPageMenuProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getBtnClass = (
        targetState: PlayerPageMenuState,
        curState: PlayerPageMenuState
    ) => {
        const className = "btn btn-sm btn-outline-secondary";
        if (targetState === curState) {
            return `${className} active`;
        }
        return className;
    };

    const menuItems = useMemo(() => {
        return [
            new MenuItem(
                PlayerPageMenuState.overview,
                routes.PLAYER_OVERVIEW.replace(":playerId", playerId),
                t("Overview", { ns: "profile" })
            ),
            new MenuItem(
                PlayerPageMenuState.matchHistory,
                routes.PLAYER_MATCH_HISTORY.replace(":playerId", playerId),
                t("Matches", { ns: "profile" })
            ),
        ];
    }, [t]);

    return (
        <div className="player-page-menu">
            {menuItems.map((item) => {
                return (
                    <a
                        // href={ item.route }
                        className={getBtnClass(item.menuState, state)}
                        onClick={() => navigate(item.route)}
                    >
                        {item.text}
                    </a>
                );
            })}
        </div>
    );
};
