import { useTranslation } from "react-i18next";

import "./MatchTable.css";

export interface IMatchTableProps {
    children: any;
}

export const MatchTable = ({ children }: IMatchTableProps) => {
    const { t } = useTranslation();

    return (
        <table className="table table-sm table-borderless matches-table">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};
