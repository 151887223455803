import { Loader } from "../Loader";
import "./LoadingButton.css";

interface LoadingButtonProps {
    isLoading: boolean;
    disabled: boolean;
    text: string;
}

export const LoadingButton = ({
    isLoading,
    text,
    disabled,
}: LoadingButtonProps) => {
    return (
        <button
            type="submit"
            className="btn btn-sm btn-outline-secondary"
            disabled={disabled || isLoading}
        >
            {isLoading && <Loader className="loading-button-loader" />}
            <span className={isLoading ? "hidden" : ""}>{text}</span>
        </button>
    );
};
