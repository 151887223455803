import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

import "./UserSection.css";
import { useAppDispatch } from "../reactExt/hooks";
import UserProfileModal from "./modals/UserProfileModal";
import {
    loadAuth,
    updateProfile,
    loginAuth,
    refreshAuth,
} from "../store/entities/identity/thunk";
import { IProfile } from "./modals/UserProfileUpdate";
import { IRegistrationData } from "./modals/UserRegistration";
import { ILoginData } from "./modals/UserLogin";
import i18n from "../i18n";
import { useSelector } from "react-redux";
import {
    selectAuthStore,
    selectIsAuthLoading,
} from "../store/entities/identity/selectors";
import { unwrapResult } from "@reduxjs/toolkit";
import { Loader, LoaderAlign } from "./Loader";
import { getUserLanguage } from "../utils";

export interface IUserSectionProps {
    className?: string;
    onProfileUpdate?: () => void;
    onLogin?: () => void;
    onLogout?: () => void;
}

const UserSection = (props: IUserSectionProps) => {
    const dispatch = useAppDispatch();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const authStore = useSelector(selectAuthStore);
    const userIsLoading = useSelector(selectIsAuthLoading);

    const user = authStore.data.user;
    const login = authStore.data.login;
    const language = getUserLanguage();

    const icon = useMemo((): IconDefinition => {
        if (authStore.data.login !== undefined) {
            return faUser;
        }
        return faUserSecret;
    }, [authStore.data.login]);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const onLogout = () => {
        const promise = dispatch(loadAuth(true));
        if (props.onLogout !== undefined) {
            promise.then(unwrapResult).then((response) => {
                props.onLogout?.();
            });
        }
    };

    const onSubmitProfileUpdate = (data: IProfile) => {
        localStorage.setItem("lang", data.language);
        i18n.changeLanguage(data.language);
        const promise = dispatch(
            updateProfile({
                refresh_token: authStore.data.refresh_token,
                data: {
                    username: data.username,
                    color: data.color,
                    email: data.email,
                },
            })
        );
        promise.then(unwrapResult).then((response) => {
            closeModal();
            props.onProfileUpdate?.();
        });
    };

    const onSubmitRegistration = (data: IRegistrationData) => {
        const promise = dispatch(
            updateProfile({
                refresh_token: authStore.data.refresh_token,
                data: {
                    username: data.username,
                    color: data.color,
                    login: data.login,
                    email: data.email,
                    password: data.password,
                },
            })
        );
        if (props.onProfileUpdate !== undefined) {
            promise.then(unwrapResult).then((response) => {
                props.onProfileUpdate?.();
            });
        }
    };

    const onSubmitLogin = (data: ILoginData) => {
        const promise = dispatch(loginAuth(data));
        if (props.onLogin !== undefined) {
            promise.then(unwrapResult).then((response) => {
                props.onLogin?.();
            });
        }
    };

    return (
        <div className={`user-section ${props.className}`}>
            {userIsLoading ? (
                <Loader align={LoaderAlign.default} />
            ) : (
                <>
                    <div
                        className="d-inline-block user-section-user"
                        onClick={() => setModalIsOpen(true)}
                    >
                        <FontAwesomeIcon
                            className="px-2"
                            icon={icon}
                            style={{ color: user.color }}
                        />
                        {user.username}
                    </div>
                    <UserProfileModal
                        isOpen={modalIsOpen}
                        onClose={closeModal}
                        onSubmitRegistration={onSubmitRegistration}
                        onSubmitProfileUpdate={onSubmitProfileUpdate}
                        onSubmitLogin={onSubmitLogin}
                        onLogout={onLogout}
                        email={user.email || ""}
                        username={user.username}
                        color={user.color}
                        login={login}
                        language={language}
                    />
                </>
            )}
        </div>
    );
};

export default UserSection;
