import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUserLanguage } from "./utils";

const resources = {
    ru: {
        err: {
            incorrect_password: "Неверный пароль",
        },
        lobby: {
            "Join as operative": "Зайти как оперативник",
            "Join as spymaster": "Зайти как мастер-шпион",
            "Slot is empty": "Слот свободен",
            "end of turn": "Конец хода",
            "Give your team a clue...": "Дайте подсказку команде...",
            "wait for RED team's clue": "красный мастер-шпион подбирает шифр",
            "wait for BLUE team's clue": "синий мастер-шпион подбирает шифр",
            "BLUE team has won": "победа синей команды",
            "RED team has won": "победа красной команды",
        },
        gameSettings: {
            "Start Game": "Запустить игру",
            "Pause Game": "Пауза",
            "Restart Game": "Рестарт",
            "Shuffle Teams": "Перетасовать",
            "Lock Teams": "Заблокировать команды",
            "Unlock Teams": "Разблокировать команды",
        },
        common: {
            shpiguni: "шпигуни",
            "Create Lobby": "Создать лобби",
            "Match History": "История матчей",
            "How to Play": "Как играть",
            "Page Not Found": "Страница не найдена",
            "User Profile": "Профиль пользователя",
            "Log in": "Авторизоваться",
            Login: "Логин",
            Email: "Почта",
            Logout: "Выйти",
            Register: "Зарегистрироваться",
            Username: "Никнейм",
            Save: "Сохранить",
            Password: "Пароль",
            Close: "Закрыть",
            Color: "Цвет",
            Back: "Назад",
            Language: "Язык",
            "Guest Account": "Гостевой аккаунт",
            Russian: "Русский",
            English: "Английский",
            "Lobby Settings": "Настройки лобби",
            "Words set": "Набор слов",
            "Seconds per round": "Секунд на раунд",
            "Bonus seconds": "Доп. секунд за слово",
            "Word confirmation seconds": "Секунд для подтверждения хода",
            "Perc. of players for confirmation":
                "Процент игроков для подтверждения хода",
            "Black words": "Черные слова",
            "Team #1 words": "Кол-во слов 1ой команды",
            "Team #2 words": "Кол-во слов 2ой команды",
            "Neutral words": "Нейтральные слова",
            Apply: "Применить",
            Cancel: "Отмена",
            "Has to be bigger than or equal": "Должно быть больше или равно",
            "Has to be lesser than or equal": "Должно быть меньше или равно",
            "Field is required": "Обязательное поле",
            "Not a valid email": "Невалидный email",
            already_registered: "Пользователь уже существует",
            "Has to be longer than or equal": "Символов должно быть не меньше",
            "Has to be shorter than or equal": "Символов должно быть не больше",
            "Has to be alphanumeric": "Неправильный формат",
            "Spy master can take operatives time":
                "Мастер-шпион может использовать время оперативников",
            "Table Presets": "Шаблоны настройки слов",
        },
        matchSummary: {
            Victory: "Победа",
            Defeat: "Поражение",
            "Team #1 Spy Master": "Мастер-шпион первой команды",
            "Team #2 Spy Master": "Мастер-шпион второй команды",
            "Game Score": "Игровой счет",
            "Team #1 words selected": "Выбрано слов первой команды",
            "Team #2 words selected": "Выбрано слов второй команды",
            "Neutral words selected": "Выбрано нейтральных слов",
            "Black words selected": "Выбрано черных слов",
        },
        matchReplay: {
            "Replay data not found": "Данные повтора отсутствуют",
        },
        profile: {
            "Match History is empty": "История матчей пуста",
            Overview: "Обзор",
            Matches: "Матчи",
            "Biggest Clues As Spy Master":
                "Самые большие шифры в роли мастер-шпиона",
            "Clues Stats": "Статистика шифров",
            "Total Clues": "Всего шифров",
            "Clues Per Match": "Шифров за матч в среднем",
            "Words Per Clue": "Размер шифра в среднем",
            "Winrate By Role": "Процент побед по роли",
            "Matches Played": "Игр сыграно",
            Winrate: "Процент побед",
            "As Operative": "Как оперативник",
            "As Spy Master": "Как мастер-шпион",

            "Max Continuous Guesses in 1 turn":
                "Макс. число верно выбранных слов за 1 ход",
            words: "слов",
            "Latest Matches": "Последние матчи",
        },
    },
    en: {
        err: {
            incorrect_password: "Incorrect password",
        },
        lobby: {
            "Join as operative": "Join as operative",
            "Join as spymaster": "Join as spymaster",
            "Slot is empty": "Slot is empty",
            "end of turn": "end of turn",
            "Give your team a clue...": "Give your team a clue...",
            "wait for RED team's clue": "wait for RED team's clue",
            "wait for BLUE team's clue": "wait for BLUE team's clue",
            "BLUE team has won": "BLUE team has won",
            "RED team has won": "RED team has won",
        },
        gameSettings: {
            "Start Game": "Start Game",
            "Pause Game": "Pause Game",
            "Restart Game": "Restart Game",
            "Shuffle Teams": "Shuffle Teams",
            "Lock Teams": "Lock Teams",
            "Unlock Teams": "Unlock Teams",
        },
        common: {
            shpiguni: "shpiguni",
            "Create Lobby": "Create Lobby",
            "Match History": "Match History",
            "How to Play": "How to Play",
            "Page Not Found": "Page Not Found",
            "User Profile": "User Profile",
            "Log in": "Log in",
            "Not a valid email": "Not a valid email",
            already_registered: "User already exixsts",
            Email: "Email",
            Login: "Login",
            Logout: "Logout",
            Register: "Register",
            Username: "Username",
            Save: "Save",
            Password: "Password",
            Close: "Close",
            Color: "Color",
            Back: "Back",
            Language: "Language",
            "Guest Account": "Guest Account",
            Russian: "Russian",
            English: "English",
            "Words set": "Words set",
            "Lobby Settings": "Lobby Settings",
            "Seconds per round": "Seconds per round",
            "Bonus seconds": "Bonus seconds",
            "Black words": "Black words",
            "Word confirmation seconds": "Word confirmation seconds",
            "Perc. of players for confirmation":
                "Perc. of players for confirmation",
            "Team #1 words": "Team #1 words",
            "Team #2 words": "Team #2 words",
            "Neutral words": "Neutral words",
            Apply: "Apply",
            Cancel: "Cancel",
            "Has to be bigger than or equal": "Has to be bigger than or equal",
            "Has to be lesser than or equal": "Has to be lesser than or equal",
            "Field is required": "Field is required",
            "Has to be longer than or equal": "Has to be longer than or equal",
            "Has to be shorter than or equal":
                "Has to be shorter than or equal",
            "Has to be alphanumeric": "Has to be alphanumeric",
            "Spy master can take operatives time":
                "Spy master can take operatives time",
            "Table Presets": "Table Presets",
        },
        matchSummary: {
            Victory: "Victory",
            Defeat: "Defeat",
            "Team #1 Spy Master": "Team #1 Spy Master",
            "Team #2 Spy Master": "Team #2 Spy Master",
            "Game Score": "Game Score",
            "Team #1 words selected": "Team #1 words selected",
            "Team #2 words selected": "Team #2 words selected",
            "Neutral words selected": "Neutral words selected",
            "Black words selected": "Black words selected",
        },
        matchReplay: {
            "Replay data not found": "Replay data is not present",
        },
        profile: {
            "Match History is empty": "Match History is empty",
            Overview: "Overview",
            Matches: "Matches",
            "Biggest Clues As Spy Master": "Biggest Clues As Spy Master",
            "Clues Stats": "Clues Stats",
            "Total Clues": "Total Clues",
            "Clues Per Match": "Clues Per Match",
            "Words Per Clue": "Words Per Clue",
            "Winrate By Role": "Winrate By Role",
            "Matches Played": "Matches Played",
            Winrate: "Winrate",
            "As Operative": "As Operative",
            "As Spy Master": "As Spy Master",

            "Max Continuous Guesses in 1 turn":
                "Max Continuous Guesses in 1 turn",
            words: "words",
            "Latest Matches": "Latest Matches",
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    ns: ["common", "err"],
    defaultNS: "common",
    lng: getUserLanguage(),
    debug: true,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
