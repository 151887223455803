import { useTranslation } from "react-i18next";

import "./PlayerPage.css";
import { useSelector } from "react-redux";
import { MatchHistory } from "./MatchHistory";
import { useEffect, useMemo } from "react";
import { getUsersCached } from "../../store/entities/users/thunk";
import { useAppDispatch } from "../../reactExt/hooks";
import { selectUserById } from "../../store/entities/users/selectors";
import { PlayerPageMenu, PlayerPageMenuState } from "./PlayerPageMenu";
import PlayerColor from "../PlayerColor";
import { IConnectedUser } from "../../store/entities/users/model";
import { Loader } from "../Loader";
import { PlayerPageOverview } from "./PlayerPageOverview";

interface PlayerPageProps {
    state: PlayerPageMenuState;
    playerId: string;
}

export const PlayerPage = ({ state, playerId }: PlayerPageProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const user = useSelector(selectUserById(playerId));

    useEffect(() => {
        const maxAge = 1 * 60 * 1000;
        dispatch(getUsersCached({ userIds: [playerId], maxAge: maxAge }));
    }, [dispatch, playerId]);

    const isLoading = useMemo(() => {
        return user === undefined;
    }, [user]);

    return isLoading === true ? (
        <Loader />
    ) : (
        <>
            <div>
                <PlayerColor player={user as IConnectedUser} />{" "}
                {user && user.username}
                <PlayerPageMenu playerId={playerId} state={state} />
            </div>
            {state === PlayerPageMenuState.matchHistory && (
                <MatchHistory playerId={playerId} />
            )}
            {state === PlayerPageMenuState.overview && (
                <PlayerPageOverview playerId={playerId} />
            )}
        </>
    );
};
