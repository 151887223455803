import * as gql from "gql-query-builder";
import axios, { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { IUserAuth, JWT } from "../store/entities/identity/model";
import { IUser } from "../store/entities/users/model";
import { ILobbySettings } from "../store/entities/lobby/model";
import { checkForGqlError } from "../errors";
import { Language } from "../language";
import { getUserLanguage } from "../utils";

const EXPIRE_IN_MINUTES = 10;

export interface IIdentityData {
    username: string;
    login?: string;
}

export interface IAuthResponse {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
}

export interface RegisterData {
    password?: string;
    username?: string;
    email?: string;
    login?: string;
    color?: string;
}

export interface ILobbyResponse {
    id: string;
    hostUserId: string;
    settings: {
        secondsPerRound: number;
        bonusSecondsPerWord: number;
        team1WordsNumber: number;
        team2WordsNumber: number;
        instaLoseWordsNumber: number;
        neutralWordsNumber: number;
        guessConfirmationSeconds: number;
        guessConfirmationMinPlayers: number;
        wordsCollectionId: string;
        clueCanTakeGuessTime: boolean;
    };
    gameServer: string;
}

export interface IWordCollectionResponse {
    id: string;
    name: string;
    language: Language;
}

export interface IUserResponse {
    id: string;
    color: string;
    username: string;
}

export interface IMatchEventsResponse {
    matchId: string;
    eventType: string;
    createdAt: string;
    data: string;
}

export interface IUserProfileUpdate extends RegisterData {}

export interface IMatchPlayerResponse {
    playerId: string;
    isSpymaster: boolean;
    teamId: number;
}

export interface IMatchWordResponse {
    text: string;
    isActive: boolean;
    teamId: number;
}

export interface IMatchResponse {
    id: string;
    lobbyId: string;
    createdAt: string;
    winnerTeamId: number;
    players: IMatchPlayerResponse[];
    words: IMatchWordResponse[];
}

export interface IMatchSearchResponse {
    matches: IMatchResponse[];
    nextCursor: string | null;
}

export interface IPlayerStatsReponseClues {
    matchId: string;
    timestamp: string;
    playerWon: boolean;
    clue: {
        text: string;
        playerId: string;
        teamId: number;
        position: number;
        wordsNum: number;
        addedOnTurnNumber: number;
    };
}

export interface IPlayerStatsReponse {
    gamesAsMaster: number;
    gamesAsOperative: number;
    wonAsMaster: number;
    wonAsOperative: number;
    avgWordsPerMatchMaster: number;
    avgCluesPerMatchMaster: number;
    totalCluesMaster: number;
    avgWordsPerMatchOperative: number;
    avgCluesPerMatchOperative: number;
    totalCluesOperative: number;
    biggestClues: IPlayerStatsReponseClues[];
}

export class ApiService {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    static fromConfig() {
        return new ApiService("/api");
    }

    async register(data: RegisterData) {
        let config = {
            method: "post",
            url: `${this.baseUrl}/id/register`,
            data: data,
        };
        const response = await axios(config);
        return response.data as IAuthResponse;
    }

    async login(login: string, password: string) {
        let config = {
            method: "post",
            url: `${this.baseUrl}/id/login`,
            data: { login: login, password: password },
        };
        const response = await axios(config);
        return response.data as IAuthResponse;
    }

    async refreshSession(refresh_token: string) {
        let config = {
            method: "post",
            url: `${this.baseUrl}/id/refresh`,
            data: { refresh_token: refresh_token },
        };
        const response = await axios(config);
        return response.data as IAuthResponse;
    }

    async updateProfile(refresh_token: string, data: IUserProfileUpdate) {
        let config = {
            method: "post",
            url: `${this.baseUrl}/id/update`,
            data: {
                refresh_token: refresh_token,
                new_color: data.color,
                new_email: data.email,
                new_username: data.username,
                new_login: data.login,
                new_password: data.password,
            },
        };
        const response = await axios(config);
        return response.data as IAuthResponse;
    }

    public static isExpiresSoon(exp: number): boolean {
        const now = moment();
        const expiration = moment.unix(exp);
        const diff = expiration.diff(now, "minutes");
        return diff < EXPIRE_IN_MINUTES;
    }

    saveTokensFromResponse(response: IAuthResponse) {
        localStorage.setItem("jwt", response.access_token);
        localStorage.setItem("refresh_token", response.refresh_token);
        if (localStorage.lang === undefined) {
            localStorage.lang = getUserLanguage();
        }
    }

    getUserAuth(): IUserAuth {
        const jwt = jwt_decode(localStorage.jwt) as JWT;
        jwt.id = jwt.sub;
        const userData = jwt as IUser;
        return {
            user: userData,
            login: jwt.login,
            jwt: localStorage.jwt,
            exp: jwt.exp,
            refresh_token: localStorage.refresh_token,
        };
    }

    async createLobby(jwt: string, settings: ILobbySettings) {
        const data = {
            query: `
            mutation {
                createLobby(
                  secondsPerRound: ${settings.secondsPerRound},
                  bonusSecondsPerWord: ${settings.bonusSecondsPerWord},
                  team1WordsNumber: ${settings.team1WordsNumber},
                  team2WordsNumber: ${settings.team2WordsNumber},
                  instaLoseWordsNumber: ${settings.instaLoseWordsNumber},
                  neutralWordsNumber: ${settings.neutralWordsNumber},
                  guessConfirmationSeconds: ${settings.guessConfirmationSeconds},
                  guessConfirmationMinPlayers: ${settings.guessConfirmationMinPlayers},
                  wordsCollectionId: "${settings.wordsCollectionId}",
                  clueCanTakeGuessTime: ${settings.clueCanTakeGuessTime},
                ) {
                  ... on ApiError {
                    code
                    fields {
                      field,
                      msg
                    }
                  }
                  ... on LobbyType {
                    id,
                    hostUserId,
                    settings {
                        secondsPerRound,
                        bonusSecondsPerWord,
                        team1WordsNumber,
                        team2WordsNumber,
                        instaLoseWordsNumber,
                        neutralWordsNumber,
                        guessConfirmationSeconds,
                        guessConfirmationMinPlayers,
                        wordsCollectionId,
                        clueCanTakeGuessTime,
                    }
                  }
                }
              }
            `,
        };
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            headers: { Authorization: jwt },
            data: data,
        };
        const response = await axios(config);
        const respData = response.data["data"]["createLobby"];
        checkForGqlError(respData);
        return respData as ILobbyResponse;
    }

    async updateLobby(jwt: string, lobbyId: string, settings: ILobbySettings) {
        const data = {
            query: `
            mutation {
                updateLobby(
                  id: "${lobbyId}",
                  secondsPerRound: ${settings.secondsPerRound},
                  bonusSecondsPerWord: ${settings.bonusSecondsPerWord},
                  team1WordsNumber: ${settings.team1WordsNumber},
                  team2WordsNumber: ${settings.team2WordsNumber},
                  instaLoseWordsNumber: ${settings.instaLoseWordsNumber},
                  neutralWordsNumber: ${settings.neutralWordsNumber},
                  guessConfirmationSeconds: ${settings.guessConfirmationSeconds},
                  guessConfirmationMinPlayers: ${settings.guessConfirmationMinPlayers},
                  wordsCollectionId: "${settings.wordsCollectionId}",
                  clueCanTakeGuessTime: ${settings.clueCanTakeGuessTime},
                ) {
                  ... on ApiError {
                    code
                    fields {
                      field,
                      msg
                    }
                  }
                  ... on LobbyType {
                    id,
                    hostUserId,
                    settings {
                        secondsPerRound,
                        bonusSecondsPerWord,
                        team1WordsNumber,
                        team2WordsNumber,
                        instaLoseWordsNumber,
                        neutralWordsNumber,
                        guessConfirmationSeconds,
                        guessConfirmationMinPlayers,
                        wordsCollectionId,
                        clueCanTakeGuessTime,
                    },
                  }
                }
              }
            `,
        };
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            headers: { Authorization: jwt },
            data: data,
        };
        const response = await axios(config);
        const respData = response.data["data"]["updateLobby"];
        checkForGqlError(respData);
        return respData as ILobbyResponse;
    }

    async getLobby(jwt: string, lobbyId: string) {
        const fields = [
            "id",
            "hostUserId",
            {
                settings: [
                    "secondsPerRound",
                    "bonusSecondsPerWord",
                    "team1WordsNumber",
                    "team2WordsNumber",
                    "instaLoseWordsNumber",
                    "neutralWordsNumber",
                    "guessConfirmationSeconds",
                    "guessConfirmationMinPlayers",
                    "wordsCollectionId",
                    "clueCanTakeGuessTime",
                ],
            },
        ];
        const query = gql.query({
            operation: "getLobby",
            variables: {
                id: {
                    name: "id",
                    type: "UUID!",
                    value: lobbyId,
                },
            },
            fields: [
                {
                    operation: "ApiError",
                    fields: [
                        "code",
                        {
                            fields: ["field", "msg"],
                        },
                    ],
                    fragment: true,
                },
                {
                    operation: "LobbyType",
                    fields: fields,
                    fragment: true,
                },
            ],
        });
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            headers: { Authorization: jwt },
            data: query,
        };
        const response = await axios(config);
        const respData = response.data["data"]["getLobby"];
        checkForGqlError(respData);
        return respData as ILobbyResponse;
    }

    async getWordCollections(jwt: string) {
        const query = gql.query({
            operation: "getWordsCollectionList",
            fields: [
                {
                    operation: "ApiError",
                    fields: [
                        "code",
                        {
                            fields: ["field", "msg"],
                        },
                    ],
                    fragment: true,
                },
                {
                    operation: "GetWordsCollectionList",
                    fields: [
                        {
                            wordsCollections: ["id", "name", "language"],
                        },
                    ],
                    fragment: true,
                },
            ],
        });
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            headers: { Authorization: jwt },
            data: query,
        };
        const response = await axios(config);
        const respData = response.data["data"]["getWordsCollectionList"];
        checkForGqlError(respData);
        return respData.wordsCollections as IWordCollectionResponse[];
    }

    async getUsers(userIds: string[]) {
        const data = {
            query: `
                query {
                    userSearch(ids:${JSON.stringify(userIds)}) {
                        users {
                            id
                            color
                            username
                        }
                    }
                }
            `,
        };
        let config = {
            method: "post",
            url: `${this.baseUrl}/id/graphql`,
            data: data,
        };
        const response = await axios(config);
        const respData = response.data["data"]["userSearch"];
        checkForGqlError(respData);
        return respData["users"] as IUserResponse[];
    }

    async getMatchEvents(matchId: string) {
        const fields = [
            {
                matchEvents: ["matchId", "eventType", "createdAt", "data"],
            },
        ];
        const query = gql.query({
            operation: "getMatchEvents",
            variables: {
                matchId: {
                    name: "matchId",
                    type: "UUID!",
                    value: matchId,
                },
            },
            fields: [
                {
                    operation: "ApiError",
                    fields: [
                        "code",
                        {
                            fields: ["field", "msg"],
                        },
                    ],
                    fragment: true,
                },
                {
                    operation: "GetMatchEventList",
                    fields: fields,
                    fragment: true,
                },
            ],
        });
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            data: query,
        };
        const response = await axios(config);
        const respData = response.data["data"]["getMatchEvents"];
        checkForGqlError(respData);
        return respData.matchEvents as IMatchEventsResponse[];
    }

    async searchMatches(
        playerId: string,
        cursor: string | null,
        limit: number = 20
    ) {
        const fields = [
            {
                matches: [
                    "id",
                    "lobbyId",
                    "winnerTeamId",
                    "createdAt",
                    {
                        players: ["teamId", "playerId", "isSpymaster"],
                        words: ["text", "isActive", "teamId"],
                    },
                ],
                pageMeta: ["nextCursor"],
            },
        ];
        const query = gql.query({
            operation: "matchSearch",
            variables: {
                playerId: {
                    name: "playerId",
                    type: "UUID!",
                    value: playerId,
                },
                cursor: {
                    name: "cursor",
                    type: "String",
                    value: cursor,
                },
                limit: {
                    name: "limit",
                    type: "Int",
                    value: limit,
                },
            },
            fields: [
                {
                    operation: "ApiError",
                    fields: [
                        "code",
                        {
                            fields: ["field", "msg"],
                        },
                    ],
                    fragment: true,
                },
                {
                    operation: "MatchSearchList",
                    fields: fields,
                    fragment: true,
                },
            ],
        });
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            data: query,
        };
        const response = await axios(config);
        const respData = response.data["data"]["matchSearch"];
        checkForGqlError(respData);
        return {
            matches: respData.matches,
            nextCursor: respData.pageMeta.nextCursor,
        } as IMatchSearchResponse;
    }

    async getPlayerStats(playerId: string) {
        const fields = [
            "wonAsMaster",
            "gamesAsMaster",
            "gamesAsOperative",
            "wonAsOperative",
            "avgWordsPerMatchMaster",
            "avgCluesPerMatchMaster",
            "totalCluesMaster",
            "avgWordsPerMatchOperative",
            "avgCluesPerMatchOperative",
            "totalCluesOperative",
            {
                biggestClues: [
                    "matchId",
                    "timestamp",
                    "playerWon",
                    {
                        clue: [
                            "text",
                            "playerId",
                            "position",
                            "teamId",
                            "addedOnTurnNumber",
                            "wordsNum",
                        ],
                    },
                ],
            },
        ];
        const query = gql.query({
            operation: "getPlayerStats",
            variables: {
                playerId: {
                    name: "playerId",
                    type: "UUID!",
                    value: playerId,
                },
            },
            fields: [
                {
                    operation: "ApiError",
                    fields: [
                        "code",
                        {
                            fields: ["field", "msg"],
                        },
                    ],
                    fragment: true,
                },
                {
                    operation: "PlayerStatsType",
                    fields: fields,
                    fragment: true,
                },
            ],
        });
        let config = {
            method: "post",
            url: `${this.baseUrl}/lobby/graphql`,
            data: query,
        };
        const response = await axios(config);
        const respData = response.data["data"]["getPlayerStats"];
        checkForGqlError(respData);
        return respData as IPlayerStatsReponse;
    }
}
