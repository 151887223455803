import { useMemo } from "react";
import { IPlayer, IPlayerUser } from "../../store/entities/game/model";
import PlayerColor from "../PlayerColor";
import "./TeamPlayerList.css";
import { useTranslation } from "react-i18next";
import routes from "../../routes";

interface TeamPlayerListProps {
    teamId: number;
    user?: IPlayerUser;
    players: IPlayerUser[];
    joinDisabled: boolean;
    isUserHost: boolean;
    onJoinTeam?: (team: number, isSpyMaster: boolean) => void;
    onRemovePlayer?: (playerId: string) => void;
}

const TeamPlayerList = ({
    teamId,
    players,
    user,
    joinDisabled,
    onJoinTeam,
    isUserHost,
    onRemovePlayer,
}: TeamPlayerListProps) => {
    const { t } = useTranslation();

    const operatives = useMemo(() => {
        return players.filter((player) => {
            return !player.isSpyMaster;
        });
    }, [players]);
    const spyMasters = useMemo(() => {
        return players.filter((player) => {
            return player.isSpyMaster;
        });
    }, [players]);

    const spyMaster = useMemo(() => {
        if (spyMasters.length > 0) {
            return spyMasters[0];
        }
    }, [spyMasters]);

    const getPlayerClassName = (player?: IPlayer) => {
        if (player && player.isConnected === false) {
            return "p-2 disconnected player-link";
        }
        return "p-2 player-link";
    };

    const getPlayerLink = (player?: IPlayer) => {
        if (player) {
            return routes.PLAYER_OVERVIEW.replace(":playerId", player.id);
        }
    };

    return (
        <div className="team-player-list">
            {!spyMasters.length && !joinDisabled ? (
                <p
                    className="team-join p-2 button unselectable pointer"
                    onClick={() => onJoinTeam?.(teamId, true)}
                >
                    {t("Join as spymaster", { ns: "lobby" })}
                </p>
            ) : (
                <p>
                    <a
                        className={getPlayerClassName(spyMaster)}
                        href={getPlayerLink(spyMaster)}
                        target="_blank"
                    >
                        {spyMaster
                            ? spyMaster.username
                            : t("Slot is empty", { ns: "lobby" })}
                    </a>
                    {spyMaster &&
                        user &&
                        (spyMaster.id == user.id || isUserHost) && (
                            <i
                                className="bi bi-x-lg remove-player-icon"
                                id="refresh"
                                onClick={() => {
                                    onRemovePlayer?.(spyMaster.id);
                                }}
                            ></i>
                        )}
                </p>
            )}
            <hr />
            {operatives.map((player) => {
                return (
                    <p key={player.id}>
                        <PlayerColor player={player} />
                        <a
                            className={getPlayerClassName(player)}
                            href={getPlayerLink(player)}
                            target="_blank"
                        >
                            {player.username}
                        </a>
                        {((user && player.id == user.id) || isUserHost) && (
                            <i
                                className="bi bi-x-lg remove-player-icon"
                                id="refresh"
                                onClick={() => {
                                    onRemovePlayer?.(player.id);
                                }}
                            ></i>
                        )}
                    </p>
                );
            })}
        </div>
    );
};

export default TeamPlayerList;
