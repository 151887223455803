import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../reactExt/hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { MatchTable } from "./MatchTable";
import {
    selectMatchesLoading,
    selectPlayerMatches,
} from "../../store/entities/match/selectors";
import MatchSummary from "./MatchSummary";
import {
    searchMatches,
    searchMatchesCached,
} from "../../store/entities/match/thunk";
import "./MatchHistory.css";
import { Loader } from "../Loader";
import routes from "../../routes";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface MatchHistoryProps {
    playerId: string;
    title?: string;
    chunkSize?: number;
    infinityLoading?: boolean;
    limit?: number | null;
}

export const MatchHistory = ({
    playerId,
    title,
    infinityLoading = true,
    chunkSize = 20,
    limit = null,
}: MatchHistoryProps) => {
    const { t } = useTranslation();
    const loading = useSelector(selectMatchesLoading(playerId));

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const matchStoreData = useSelector(selectPlayerMatches(playerId));

    useEffect(() => {
        const maxAge = 5 * 60 * 1000;
        dispatch(
            searchMatchesCached({ playerId, limit: chunkSize, maxAge: maxAge })
        );
    }, [dispatch, playerId]);

    const matchOnClick = useCallback(
        (matchId: string) => {
            navigate(routes.REPLAY.replace(":matchId", matchId));
        },
        [navigate]
    );

    const loadMore = useCallback(() => {
        dispatch(
            searchMatches({
                playerId,
                limit: chunkSize,
                cursor: loading.nextCursor,
            })
        );
    }, [dispatch, playerId, loading.nextCursor]);

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        hasNextPage: loading.nextCursor !== null,
        loading: loading.isLoading,
        onLoadMore: loadMore,
        disabled: !infinityLoading,
        rootMargin: "0px 0px 400px 0px",
    });

    const matches = useMemo(() => {
        if (limit) {
            return matchStoreData.matches.slice(0, limit);
        }
        return matchStoreData.matches;
    }, [limit, matchStoreData.matches]);

    return !loading.isLoading && matches.length == 0 ? (
        <div className="text-center empty-message">
            <span>
                {t("Match History is empty", {
                    ns: "profile",
                })}
            </span>
        </div>
    ) : (
        <>
            {matches.length > 0 && title && (
                <div className="match-history-title">{title}</div>
            )}
            <MatchTable>
                {matches.map((m) => {
                    return (
                        <MatchSummary
                            key={m.id}
                            match={m}
                            playerId={playerId as string}
                            onClick={matchOnClick}
                        />
                    );
                })}
            </MatchTable>
            <div ref={sentryRef} className="match-history-loading">
                {loading.isLoading && <Loader />}
            </div>
        </>
    );
};
