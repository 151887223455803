import { FormikContextType, useFormik } from "formik";
import { TFunction } from "i18next";

export interface IApiErrorItem {
    code: string;
    fields: string[] | null;
    message: string | null;
}

export interface IApiError {
    errors: IApiErrorItem[];
}

export interface FormErrors {
    [key: string]: string;
}

export function setFormikErrors(
    t: TFunction,
    formik: FormikContextType<any>,
    errors: FormErrors
) {
    const formikErrors: FormErrors = {};
    Object.keys(errors).forEach((item) => {
        formikErrors[item] = t(errors[item], { ns: "err" }) || errors[item];
    });
    formik.setErrors(formikErrors);
}

// TODO: Fix two websocket connections
// TODO: Add popup for non handled errors

export function transformApiErrorItem(error: IApiErrorItem): string {
    if (error.message === null) {
        return error.code;
    }
    if (error.code === "request_schema_validation_error") {
        return error.message;
    }
    return error.code;
}

export class GraphQLApiError extends Error {
    response: any;

    constructor(code: string, response: any) {
        super(code);
        this.name = "GraphQLApiError";
        this.response = response;
    }
}

export const checkForGqlError = (response: any) => {
    if (response["code"] !== undefined) {
        throw new GraphQLApiError(response["code"], response);
    }
};

export const checkErrorForCode = (error: IApiError, code: string): boolean => {
    const errorItem = error.errors.filter((err) => err.code == code);
    return errorItem !== undefined;
};
