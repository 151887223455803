import { ILobbySettings } from "../store/entities/lobby/model";

export class LobbySettingsService {
    default() {
        return {
            secondsPerRound: 60,
            bonusSecondsPerWord: 10,
            instaLoseWordsNumber: 1,
            team1WordsNumber: 8,
            team2WordsNumber: 9,
            neutralWordsNumber: 7,
            guessConfirmationSeconds: 2,
            guessConfirmationMinPlayers: 50,
            clueCanTakeGuessTime: false,
            wordsCollectionId: null,
        } as ILobbySettings;
    }

    static load(): ILobbySettings {
        return {
            secondsPerRound: LobbySettingsService.getItem(
                "secondsPerRound",
                60
            ),
            bonusSecondsPerWord: LobbySettingsService.getItem(
                "bonusSecondsPerWord",
                10
            ),
            instaLoseWordsNumber: LobbySettingsService.getItem(
                "instaLoseWordsNumber",
                1
            ),
            team1WordsNumber: LobbySettingsService.getItem(
                "team1WordsNumber",
                8
            ),
            team2WordsNumber: LobbySettingsService.getItem(
                "team2WordsNumber",
                9
            ),
            neutralWordsNumber: LobbySettingsService.getItem(
                "neutralWordsNumber",
                7
            ),
            guessConfirmationSeconds: LobbySettingsService.getItem(
                "guessConfirmationSeconds",
                2
            ),
            guessConfirmationMinPlayers: LobbySettingsService.getItem(
                "guessConfirmationMinPlayers",
                50
            ),
            clueCanTakeGuessTime: LobbySettingsService.getItem(
                "clueCanTakeGuessTime",
                false
            ),
            wordsCollectionId: LobbySettingsService.getItem(
                "wordsCollectionId",
                null
            ),
        } as ILobbySettings;
    }

    static getItem(key: string, def: any): any {
        const value = localStorage.getItem(`lobbySettings_${key}`);
        if (value !== null) {
            return JSON.parse(value);
        }
        return def;
    }

    static save(settings: ILobbySettings) {
        const keys = [
            "secondsPerRound",
            "bonusSecondsPerWord",
            "instaLoseWordsNumber",
            "team1WordsNumber",
            "team2WordsNumber",
            "neutralWordsNumber",
            "guessConfirmationSeconds",
            "guessConfirmationMinPlayers",
            "clueCanTakeGuessTime",
            "wordsCollectionId",
        ];
        keys.forEach((key) => {
            localStorage.setItem(
                `lobbySettings_${key}`,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                JSON.stringify(settings[key])
            );
        });
    }
}
