import { Language } from "../../../language";

export interface ILobbySettings {
    secondsPerRound: number;
    bonusSecondsPerWord: number;
    team1WordsNumber: number;
    team2WordsNumber: number;
    instaLoseWordsNumber: number;
    neutralWordsNumber: number;
    guessConfirmationSeconds: number;
    guessConfirmationMinPlayers: number;
    clueCanTakeGuessTime: boolean;
    wordsCollectionId: string | null;
}

export interface IWordCollection {
    id: string;
    name: string;
    language: Language;
}

export enum LobbyEventType {
    lobby_state_sync = "lobby_state_sync",
    player_team_updated = "player_team_updated",
    player_connection_updated = "player_connection_updated",
    player_removed = "player_removed",
    team_lock_updated = "team_lock_updated",
    game_server_updated = "game_server_updated",
    player_info_refreshed = "player_info_refreshed",
}

export interface ILobbyEvent {
    event_type: LobbyEventType;
    lobby_id: string;
}

export interface ILobbyEventPlayerData {
    is_spy_master: boolean;
    id: string;
    team_id: number;
    is_connected: boolean;
    // not returned by the backend
    username?: string;
    color?: string;
}

export interface ILobbyStateSyncEvent extends ILobbyEvent {
    event_type: LobbyEventType.lobby_state_sync;
    lobby_state: {
        lobby_id: string;
        host_user_id: string;
        settings: {
            seconds_per_round: number;
            bonus_seconds_per_word: number;
            insta_lose_words_number: number;
            neutral_words_number: number;
            team_1_words_number: number;
            team_2_words_number: number;
            guess_confirmation_seconds: number;
            words_collection_id: string;
        };
        game_server_location: string | null;
        game_server_name: string | null;
        match_id: string | null;
        is_team_locked: boolean;
        players: ILobbyEventPlayerData[];
    };
}

export interface ILobbyPlayerTeamUpdatedEvent extends ILobbyEvent {
    event_type: LobbyEventType.player_team_updated;
    team_id: number;
    player_id: string;
    is_spy_master: boolean;
    is_connected: boolean;
    // not returned by backend
    username?: string;
    color?: string;
}

export interface ILobbyPlayerRemoved extends ILobbyEvent {
    event_type: LobbyEventType.player_removed;
    by_user_id: string;
    player_id: string;
}

export interface ILobbyTeamLockUpdated extends ILobbyEvent {
    event_type: LobbyEventType.team_lock_updated;
    is_locked: boolean;
}

export interface ILobbyGameServerUpdated extends ILobbyEvent {
    event_type: LobbyEventType.game_server_updated;
    game_server_location?: string;
    match_id?: string;
}

export interface ILobbyPlayerInfoRefreshed extends ILobbyEvent {
    event_type: LobbyEventType.player_info_refreshed;
    player_id: string;
    // not returned by backend
    color: string;
    username: string;
}

export interface ILobbyPlayerConnectionUpdated extends ILobbyEvent {
    event_type: LobbyEventType.player_connection_updated;
    player_id: string;
    is_connected: boolean;
}
