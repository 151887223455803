import { useTranslation } from "react-i18next";

import "./NotFound.css";

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="vw-100 not-found">
            404 <br /> {t("Page Not Found")}
        </div>
    );
};

export default NotFound;
