import { IInputProps } from "./models";

const PasswordInput = (props: IInputProps) => {
    return (
        <input
            onBlur={props.onBlur}
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            type="password"
            className={props.className}
            onChange={props.onChange}
            value={props.value}
        />
    );
};

export default PasswordInput;
