import { useCallback } from "react";
import routes from "../../routes";
import { StatsService } from "../../services/statsService";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { IClueStat } from "../../store/entities/stats/model";
import "moment/locale/ru";
import moment from "moment";
import { getUserLanguage } from "../../utils";
import { UncontrolledTooltip } from "reactstrap";

interface PlayerBiggestCluesTableProps {
    stats: StatsService;
}

export const PlayerBiggestCluesTable = ({
    stats,
}: PlayerBiggestCluesTableProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const clueOnClick = useCallback(
        (clue: IClueStat) => {
            navigate({
                pathname: routes.REPLAY.replace(":matchId", clue.matchId),
                search: `?turn=${clue.clue.addedOnTurnNumber}`,
            });
        },
        [navigate]
    );

    if (!stats.biggestClues || !stats.biggestClues.length) {
        return <></>;
    }

    const getDate = (timestamp: string) => {
        return moment(timestamp)
            .locale(getUserLanguage())
            .format("MMMM D YYYY, HH:mm")
            .replace(/./, (c) => c.toUpperCase());
    };

    const getClueIconId = (clue: IClueStat) => {
        const turn = clue.clue.addedOnTurnNumber;
        return `clue-${clue.matchId}-${turn}-won`;
    };

    return (
        <table className="table table-sm overview-table">
            <thead>
                <tr>
                    <th>
                        {t("Biggest Clues As Spy Master", { ns: "profile" })}
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {stats.biggestClues.map((clue) => {
                    return (
                        <tr className="hoverable-row">
                            <td
                                onClick={() => {
                                    clueOnClick(clue);
                                }}
                                className="text-center"
                            >
                                {clue.clue.text}
                            </td>
                            <td className="text-center">
                                {clue.playerWon ? (
                                    <i
                                        className="bi bi-check-square-fill player-win"
                                        id={getClueIconId(clue)}
                                    ></i>
                                ) : (
                                    <i
                                        className="bi bi-x-square-fill player-lose"
                                        id={getClueIconId(clue)}
                                    ></i>
                                )}
                                <UncontrolledTooltip
                                    placement="top"
                                    target={getClueIconId(clue)}
                                >
                                    {clue.playerWon
                                        ? t("Victory", { ns: "matchSummary" })
                                        : t("Defeat", { ns: "matchSummary" })}
                                </UncontrolledTooltip>
                            </td>
                            <td>{getDate(clue.timestamp)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
