import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { ApiService, IPlayerStatsReponse } from "../../../services/apiService";
import { RootState } from "../../store";
import { selectStatsByid } from "./selectors";

interface IGetPlayerStatsResult {
    playerId: string;
    stats: IPlayerStatsReponse;
}

export const getPlayerStats = createAsyncThunk(
    "getPlayerStats",
    async (playerId: string) => {
        const service = ApiService.fromConfig();
        const response = await service.getPlayerStats(playerId);
        return {
            playerId: playerId,
            stats: response,
        } as IGetPlayerStatsResult;
    }
);

interface IGetPlayerStatsCached {
    maxAge: number;
    playerId: string;
}

export const getPlayerStatsCached = createAsyncThunk(
    "getPlayerStatsCond",
    async ({ playerId, maxAge }: IGetPlayerStatsCached, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const playerState = selectStatsByid(playerId)(state);
        const now = Date.now();
        let promise: Promise<IGetPlayerStatsResult>;
        if (
            playerState.timestamp === undefined ||
            now - playerState.timestamp > maxAge
        ) {
            promise = thunkAPI
                .dispatch(getPlayerStats(playerId))
                .then(unwrapResult);
        } else {
            promise = Promise.resolve({
                playerId: playerId,
                stats: playerState,
            });
        }

        const resultPromise = new Promise((resolve, reject) => {
            promise.then((response) => {
                resolve(response);
            });
        });
        const result = await resultPromise;
        return result as IGetPlayerStatsResult;
    }
);
