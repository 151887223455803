import { IClueStat, IPlayerStats } from "../store/entities/stats/model";

export class StatsService {
    gamesAsMaster: number;
    gamesAsOperative: number;
    wonAsMaster: number;
    wonAsOperative: number;
    avgWordsPerMatchMaster: number;
    avgCluesPerMatchMaster: number;
    totalCluesMaster: number;
    avgWordsPerMatchOperative: number;
    avgCluesPerMatchOperative: number;
    totalCluesOperative: number;
    biggestClues: IClueStat[];

    winrateAsMaster: number | null;
    winrateAsOperative: number | null;

    constructor(stats: IPlayerStats) {
        this.gamesAsMaster = stats.gamesAsMaster;
        this.gamesAsOperative = stats.gamesAsOperative;
        this.wonAsMaster = stats.wonAsMaster;
        this.wonAsOperative = stats.wonAsOperative;
        this.biggestClues = stats.biggestClues;
        this.avgWordsPerMatchMaster = stats.avgWordsPerMatchMaster;
        this.avgCluesPerMatchMaster = stats.avgCluesPerMatchMaster;
        this.totalCluesMaster = stats.totalCluesMaster;
        this.avgWordsPerMatchOperative = stats.avgWordsPerMatchOperative;
        this.avgCluesPerMatchOperative = stats.avgCluesPerMatchOperative;
        this.totalCluesOperative = stats.totalCluesOperative;

        if (this.gamesAsMaster > 0) {
            this.winrateAsMaster =
                100 * (this.wonAsMaster / this.gamesAsMaster);
        } else {
            this.winrateAsMaster = null;
        }
        if (this.gamesAsOperative > 0) {
            this.winrateAsOperative =
                100 * (this.wonAsOperative / this.gamesAsOperative);
        } else {
            this.winrateAsOperative = null;
        }
    }
}
