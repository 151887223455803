import {
    IClue,
    IPlayerUser,
    PlayerState,
} from "../../store/entities/game/model";
import TeamScore from "./TeamScore";
import "./GameTeam.css";
import { useMemo } from "react";
import TeamPlayerList from "./TeamPlayerList";
import TeamCluesList from "./TeamCluesList";
import { useTranslation } from "react-i18next";

interface GameTeamProps {
    user?: IPlayerUser;
    players: IPlayerUser[];
    voteSkipPlayers: IPlayerUser[];
    matchId: string;
    teamId: number;
    score: number;
    clues: IClue[];
    joinDisabled: boolean;
    state: PlayerState;
    isUserHost: boolean;
    onJoinTeam?: (team: number, isSpyMaster: boolean) => void;
    onRemovePlayer?: (playerId: string) => void;
}

const GameTeam = ({
    state,
    joinDisabled,
    onJoinTeam,
    ...props
}: GameTeamProps) => {
    const { t } = useTranslation();

    const teamStyle = `team-${props.teamId}-btn`;

    const userIsOperative = useMemo(() => {
        return (
            props.user !== undefined &&
            props.user.teamId === props.teamId &&
            props.user.isSpyMaster === false
        );
    }, [props.user]);

    return (
        <div className={"lobby-team " + teamStyle}>
            <TeamPlayerList
                teamId={props.teamId}
                players={props.players}
                joinDisabled={joinDisabled}
                onJoinTeam={onJoinTeam}
                user={props.user}
                isUserHost={props.isUserHost}
                onRemovePlayer={props.onRemovePlayer}
            />
            <TeamScore team={props.teamId} score={props.score} />
            <div className="lobby-team-bottom-container">
                {!userIsOperative && !joinDisabled && (
                    <p
                        className="team-join p-2 button unselectable pointer"
                        onClick={() => onJoinTeam?.(props.teamId, false)}
                    >
                        {t("Join as operative", { ns: "lobby" })}
                    </p>
                )}
                <hr />
                <div>
                    <TeamCluesList clues={props.clues} />
                </div>
            </div>
        </div>
    );
};

export default GameTeam;
