import { useState } from "react";
import "./ClueInput.css";
import { useTranslation } from "react-i18next";

export interface IClueInputProps {
    onSubmit?: (value: string) => void;
    className?: string;
}

const ClueInput = ({ onSubmit, className }: IClueInputProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState("");

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            onSubmit?.(value);
            setValue("");
        }
    };
    return (
        <div className={className}>
            <input
                className="game-bottom-input clue-input"
                type="text"
                placeholder={
                    t("Give your team a clue...", { ns: "lobby" }) || ""
                }
                onChange={handleChange}
                value={value}
                onKeyDown={handleKeyDown}
            ></input>
        </div>
    );
};

export default ClueInput;
