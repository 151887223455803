export interface IWord {
    text: string;
    teamId: number | null;
    isActive: boolean;
}

export interface IPlayer {
    id: string;
    teamId: number;
    isSpyMaster: boolean;
    isConnected: boolean;
}

export interface IScore {
    teamId: number;
    score: number;
}

export interface IPlayerUser extends IPlayer {
    username: string;
    color: string;
}

export interface IClue {
    teamId: number;
    text: string;
}

export interface IWordVote {
    playerId: string;
    word: string;
}

export enum GameEventType {
    game_state_updated = "game_state_updated",
    game_finished = "game_finished",
    game_deallocated = "game_deallocated",
    game_created = "game_created",
    game_pause_updated = "game_pause_updated",
    phase_changed = "phase_changed",
    clue_added = "clue_added",
    vote_updated = "vote_updated",
    candidate_changed = "candidate_changed",
    candidate_applied = "candidate_applied",
    players_changed = "players_changed",
    team_lock_updated = "team_lock_updated",
    clue_has_taken_guess_time = "clue_has_taken_guess_time",
}

export interface IGameEventApi {
    lobby_id: string;
    match_id: string;
    type: GameEventType;
    data: {
        words: IWordApi[];
        players: IPlayerApi[];
        word_votes: IWordVoteApi[];
        skip_votes: string[];
        clues: IClueApi[];
        score: IScoreApi[];
        game_phase: GamePhase;
        active_team_id: number;
        winner_team_id: number | null;
        game_clock: number;
        time_left: number;
        is_paused: boolean;
        is_finished: boolean;
        is_team_locked: boolean;
        turn_number: number;
        guess_candidate: IGuessCandidateApi | null;
        guess_confirmation_seconds: number;
        clue_can_take_guess_time: boolean;
        clue_has_taken_guess_time: boolean;
    };
    timestamp: number;
}

export interface IWordApi {
    text: string;
    team_id: number | null;
    is_active: boolean;
}

export interface IPlayerApi {
    id: string;
    team_id: number;
    is_connected: boolean;
    is_master: boolean;
}

export interface IScoreApi {
    team_id: number;
    score: number;
}

export interface IClueApi {
    team_id: number;
    text: string;
}

export interface IWordVoteApi {
    player_id: string;
    word: string;
}

export interface IGuessCandidateApi {
    word: string | null;
    time_left: number;
}

export enum GamePhase {
    clue = "clue",
    guess = "guess",
}

export interface IGameStateUpdatedEventApi extends IGameEventApi {
    type: GameEventType.game_state_updated;
}

export interface IGameFinishedEventApi
    extends Omit<IGameStateUpdatedEventApi, "type"> {
    type: GameEventType.game_finished;
}

export enum PlayerState {
    Guess,
    Clue,
    WatchGuess,
    Wait,
}

export interface IGameEvent {
    lobbyId: string;
    matchId: string;
    type: GameEventType;
    data: {
        words: IWord[];
        players: IPlayerUser[];
        wordVotes: IWordVote[];
        skipVotes: string[];
        clues: IClue[];
        score: IScore[];
        gamePhase: string;
        activeTeamId: number;
        winnerTeamId: number | null;
        gameClock: number;
        timeLeft: number;
        isPaused: boolean;
        isFinished: boolean;
        isTeamLocked: boolean;
        turnNumber: number;
        guessCandidate: IGuessCandidate | null;
        guessConfirmationSeconds: number;
        clueCanTakeGuessTime: boolean;
        clueHasTakenGuessTime: boolean;
    };
    timestamp: number;
}

export interface IGuessCandidate {
    word: string | null;
    timeLeft: number;
}

export interface IGameStateUpdatedEvent extends IGameEvent {
    type: GameEventType.game_state_updated;
}

export interface IGameFinishedEvent
    extends Omit<IGameStateUpdatedEvent, "type"> {
    type: GameEventType.game_finished;
}

export enum GameNotificaitonType {
    turn_changed = "turn_changed",
    word_guess_candidate = "word_guess_candidate",
    word_guess = "word_guess",
}

export interface IGameNotificaiton {
    type: GameNotificaitonType;
    gameClock: number;
}
