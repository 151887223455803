import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAppDispatch } from "../../reactExt/hooks";
import { LobbySocketService } from "../../services/lobbySocketService";
import { selectAuthStore } from "../../store/entities/identity/selectors";
import { getLobby, updateLobby } from "../../store/entities/lobby/thunk";

import "./LobbyGame.css";
import TopBar from "../TopBar";
import { ILobbySettings } from "../../store/entities/lobby/model";
import LobbySettings from "./LobbySettings";
import GameTeam from "../game/GameTeam";
import { selectGameStore } from "../../store/entities/game/selectors";
import WordsBoard from "../game/WordBoard";
import {
    IPlayerUser,
    IWord,
    PlayerState,
} from "../../store/entities/game/model";
import GameTimer from "../game/GameTimer";
import { unwrapResult } from "@reduxjs/toolkit";
import { GameSocketService } from "../../services/gameSocketService";
import { useLobbyGameState, useTurnAudio } from "../../store/hooks";
import GameBottom from "../game/GameBottom";
import { Loader } from "../Loader";

const LobbyGame = () => {
    const { lobbyId: lobbyId } = useParams<{ lobbyId: string }>();
    const [lobbySocket, setLobbySocket] = useState<LobbySocketService | null>(
        null
    );
    const [gameSocket, setGameSocket] = useState<GameSocketService | null>(
        null
    );

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [lobbyState] = useLobbyGameState(lobbyId as string);
    const {
        isLobbyLoading,
        isGameLoading,
        isPaused,
        hostUserId,
        matchId,
        gameServer,
        userPlayerVotes,
        playerState,
        team1Players,
        team2Players,
        team1Clues,
        team2Clues,
        team1VoteSkipPlayers,
        team2VoteSkipPlayers,
        wordVotePlayers,
        isTeamLocked,
        team1ListState,
        team2ListState,
        lobbySettings,
    } = lobbyState;

    const userPlayer = userPlayerVotes?.player;

    const authStore = useSelector(selectAuthStore);
    const gameStore = useSelector(selectGameStore);

    useEffect(() => {
        if (
            lobbyId &&
            !isLobbyLoading &&
            !lobbySettings &&
            userPlayer &&
            hostUserId == userPlayer.id
        ) {
            dispatch(
                getLobby({
                    jwt: authStore.data.jwt,
                    lobbyId,
                })
            );
        }
    }, [isLobbyLoading, hostUserId, userPlayer?.id, lobbyId]);

    useEffect(() => {
        if (authStore.data.user && authStore.data.user.id && lobbyId) {
            if (lobbySocket && lobbySocket.userId != authStore.data.user.id) {
                lobbySocket.leave();
            }
            setLobbySocket(
                LobbySocketService.fromConfig(
                    authStore.data.user.id,
                    lobbyId as string,
                    authStore.data.jwt,
                    dispatch
                )
            );
        }
    }, [authStore.data.user && authStore.data.user.id, lobbyId]);

    useEffect(() => {
        lobbySocket?.connect();
        return () => {
            lobbySocket?.close();
        };
    }, [lobbySocket && lobbySocket.id]);

    useEffect(() => {
        if (gameServer && matchId) {
            setGameSocket(
                new GameSocketService(
                    gameServer as string,
                    matchId as string,
                    authStore.data.jwt,
                    dispatch
                )
            );
        } else if (gameSocket) {
            gameSocket.close();
            setGameSocket(null);
        }
    }, [lobbySocket && lobbySocket.id, gameServer, matchId]);
    useEffect(() => {
        gameSocket?.connect();
        return () => {
            gameSocket?.close();
        };
    }, [gameSocket && gameSocket.id]);

    const onWordClick = useCallback(
        (word: IWord) => {
            if (userPlayerVotes?.player.isSpyMaster) {
                return;
            }
            if (
                userPlayerVotes?.wordVote === null ||
                userPlayerVotes?.wordVote !== word.text
            ) {
                gameSocket?.updateVoteWord(word.text);
            } else {
                gameSocket?.updateVoteWord(null);
            }
        },
        [userPlayerVotes, gameSocket]
    );

    const onSkipClick = useCallback(() => {
        if (userPlayerVotes?.player.isSpyMaster) {
            return;
        }
        const skip = !userPlayerVotes?.skipVote;
        gameSocket?.updateVoteSkip(skip);
    }, [userPlayerVotes, gameSocket]);
    const onPlayClicked = useCallback(() => {
        gameSocket?.updatePause(!isPaused);
    }, [gameSocket, isPaused]);
    const onSubmitClue = useCallback(
        (clue: string) => {
            gameSocket?.addClue(clue);
        },
        [gameSocket]
    );
    const onRandomClicked = useCallback(() => {
        lobbySocket?.shuffleTeams();
    }, [lobbySocket]);

    const onProfileUpdate = () => {
        lobbySocket?.refreshPlayerInfo();
    };
    const isUserHost = useMemo(() => {
        return (userPlayer && hostUserId === userPlayer.id) || false;
    }, [userPlayer, hostUserId]);

    const onSubmitSettings = useCallback(
        (settings: ILobbySettings) => {
            return dispatch(
                updateLobby({
                    jwt: authStore.data.jwt,
                    lobbyId: lobbyId as string,
                    settings: settings,
                })
            ).then(unwrapResult);
        },
        [dispatch, authStore.data.jwt, lobbyId]
    );
    const onLockClicked = useCallback(() => {
        lobbySocket?.updateTeamLock(!isTeamLocked);
    }, [lobbySocket, isTeamLocked]);
    const onRefreshClicked = useCallback(() => {
        lobbySocket?.restart();
    }, [lobbySocket]);
    const onJoinTeam = useCallback(
        (teamId: number, isSpyMaster: boolean) => {
            lobbySocket?.joinTeam(teamId, isSpyMaster);
        },
        [lobbySocket]
    );
    const onRemovePlayer = useCallback(
        (playerId: string) => {
            lobbySocket?.removePlayer(playerId);
        },
        [lobbySocket]
    );

    useTurnAudio(gameStore.notification);

    const teamStyle = useMemo(() => {
        if (gameStore.winnerTeamId === null) {
            return `team-${gameStore.activeTeamId}-bg`;
        }
        return `team-${gameStore.winnerTeamId}-bg`;
    }, [gameStore.activeTeamId, gameStore.winnerTeamId]);

    const rootClasses = `${teamStyle}`;

    return (
        <div className={rootClasses}>
            <TopBar
                onProfileUpdate={() => {
                    onProfileUpdate();
                }}
            />
            <title>{t("shpiguni")} </title>
            <div className="game-container">
                {isGameLoading ? (
                    <Loader />
                ) : (
                    <>
                        <GameTeam
                            players={team1Players}
                            voteSkipPlayers={team1VoteSkipPlayers}
                            teamId={1}
                            score={gameStore.score[1] as number}
                            clues={team1Clues}
                            matchId={matchId as string}
                            user={userPlayer as IPlayerUser}
                            onJoinTeam={onJoinTeam}
                            joinDisabled={isTeamLocked}
                            state={team1ListState as PlayerState}
                            isUserHost={isUserHost}
                            onRemovePlayer={onRemovePlayer}
                        />
                        <div className="board-container-wrap">
                            <div className="board-container">
                                <div>
                                    {gameStore.gameServer !== null && (
                                        <GameTimer
                                            isPlayerTurn={
                                                playerState ==
                                                    PlayerState.Guess ||
                                                playerState == PlayerState.Clue
                                            }
                                            clueHasTakenGuessTime={
                                                gameStore.clueHasTakenGuessTime
                                            }
                                            timeLeft={gameStore.timeLeft}
                                        />
                                    )}
                                </div>
                                <WordsBoard
                                    onWordClick={onWordClick}
                                    words={gameStore.words.list}
                                    votes={wordVotePlayers}
                                    disabled={playerState !== PlayerState.Guess}
                                    guessCandidate={gameStore.guessCandidate}
                                    timeLeft={gameStore.timeLeft}
                                    serverTimeLeft={gameStore.serverTimeLeft}
                                    guessConfirmationSeconds={
                                        gameStore.guessConfirmationSeconds
                                    }
                                    isPaused={
                                        isPaused || gameStore.gameServer == null
                                    }
                                />
                                <div>
                                    {gameStore.gameServer !== null && (
                                        <GameBottom
                                            playerState={playerState}
                                            onSkipClick={onSkipClick}
                                            onSubmitClue={onSubmitClue}
                                            voteSkipPlayers={
                                                gameStore.activeTeamId == 1
                                                    ? team1VoteSkipPlayers
                                                    : team2VoteSkipPlayers
                                            }
                                            activeTeamId={
                                                gameStore.activeTeamId
                                            }
                                            winnerTeamId={
                                                gameStore.winnerTeamId
                                            }
                                            guessConfirmationSeconds={
                                                gameStore.guessConfirmationSeconds
                                            }
                                            guessCandidate={
                                                gameStore.guessCandidate
                                            }
                                            timeLeft={gameStore.timeLeft}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <GameTeam
                            players={team2Players}
                            voteSkipPlayers={team2VoteSkipPlayers}
                            teamId={2}
                            score={gameStore.score[2] as number}
                            clues={team2Clues}
                            matchId={matchId as string}
                            user={userPlayer as IPlayerUser}
                            onJoinTeam={onJoinTeam}
                            joinDisabled={isTeamLocked}
                            state={team2ListState as PlayerState}
                            isUserHost={isUserHost}
                            onRemovePlayer={onRemovePlayer}
                        />
                    </>
                )}
            </div>
            {!isLobbyLoading && isUserHost && lobbySettings !== null ? (
                <LobbySettings
                    onSubmitSettings={onSubmitSettings}
                    isPaused={isPaused}
                    isLocked={isTeamLocked}
                    onPlayClicked={onPlayClicked}
                    onLockClicked={onLockClicked}
                    onRefreshClicked={onRefreshClicked}
                    onRandomClicked={onRandomClicked}
                    isFinished={gameStore.isFinished}
                    settings={lobbySettings}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default LobbyGame;
