import { useEffect, useMemo, useRef, useState } from "react";
import { ILobbySettings } from "../../store/entities/lobby/model";
import "./LobbySettings.css";
import LobbySettingsModal from "../modals/LobbySettingsModal";
import { useHover } from "usehooks-ts";
import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getIsTouchEnabled } from "../../utils";
import { useOnClickOutside } from "usehooks-ts";

interface ILobbySettingsProps {
    onSubmitSettings: (settings: ILobbySettings) => Promise<any>;
    onPlayClicked: () => void;
    onRefreshClicked: () => void;
    onLockClicked: () => void;
    onRandomClicked: () => void;
    isPaused: boolean;
    isLocked: boolean;
    isFinished: boolean;
    settings: ILobbySettings;
}

const LobbySettings = ({
    onSubmitSettings,
    onPlayClicked,
    onRefreshClicked,
    onLockClicked,
    onRandomClicked,
    isPaused,
    isLocked,
    isFinished,
    settings,
}: ILobbySettingsProps) => {
    const { t } = useTranslation();

    const settingsRef = useRef(null);
    const isHover = useHover(settingsRef);
    const isTouchEnabled = useMemo(getIsTouchEnabled, []);

    const [settingsIsOpen, setSettingsOpen] = useState(false);
    const [lobbySetttingsIsOpen, setLobbySettingsIsOpen] = useState(false);

    const openModal = () => {
        setLobbySettingsIsOpen(true);
    };
    const closeModal = () => {
        setLobbySettingsIsOpen(false);
    };
    const submitModal = (settings: ILobbySettings) => {
        onSubmitSettings(settings).then(closeModal);
    };

    const playClasses = useMemo(() => {
        if (isPaused) {
            return `bi bi-play-fill`;
        }
        return `bi bi-pause-fill`;
    }, [isPaused]);
    const lockClasses = useMemo(() => {
        if (!isLocked) {
            return `bi bi-lock-fill`;
        }
        return `bi bi-unlock-fill`;
    }, [isLocked]);
    useEffect(() => {
        if (!isTouchEnabled) {
            setSettingsOpen(isHover);
        }
    }, [isTouchEnabled, isHover]);
    useOnClickOutside(settingsRef, () => {
        if (isTouchEnabled && !lobbySetttingsIsOpen) {
            setSettingsOpen(false);
        }
    });

    return (
        <div ref={settingsRef} className="lobby-settings">
            <LobbySettingsModal
                isOpen={lobbySetttingsIsOpen}
                onSubmit={submitModal}
                onClose={closeModal}
                settings={settings}
            />
            <div className="button lobby-settings-icon">
                {settingsIsOpen && (
                    <>
                        {!isFinished && (
                            <>
                                <i
                                    className={playClasses}
                                    id="play"
                                    onClick={onPlayClicked}
                                ></i>
                                {!isTouchEnabled && (
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="play"
                                        popperClassName="lobby-settings-tooltip"
                                    >
                                        {isPaused
                                            ? t("Start Game", {
                                                  ns: "gameSettings",
                                              })
                                            : t("Pause Game", {
                                                  ns: "gameSettings",
                                              })}
                                    </UncontrolledTooltip>
                                )}
                            </>
                        )}
                        <i
                            className="bi bi-arrow-repeat"
                            id="refresh"
                            onClick={onRefreshClicked}
                        ></i>
                        <i
                            className={lockClasses}
                            id="lock"
                            onClick={onLockClicked}
                        ></i>
                        <i
                            className="bi bi-dice-5"
                            id="random"
                            onClick={onRandomClicked}
                        ></i>
                        {!isTouchEnabled && (
                            <>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="refresh"
                                    popperClassName="lobby-settings-tooltip"
                                >
                                    {t("Restart Game", { ns: "gameSettings" })}
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="lock"
                                    popperClassName="lobby-settings-tooltip"
                                >
                                    {isLocked
                                        ? t("Unlock Teams", {
                                              ns: "gameSettings",
                                          })
                                        : t("Lock Teams", {
                                              ns: "gameSettings",
                                          })}
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="random"
                                    popperClassName="lobby-settings-tooltip"
                                >
                                    {t("Shuffle Teams", { ns: "gameSettings" })}
                                </UncontrolledTooltip>
                            </>
                        )}
                    </>
                )}
                {(!isTouchEnabled || settingsIsOpen) && (
                    <i className="bi bi-gear-fill" onClick={openModal}></i>
                )}
                {isTouchEnabled && !settingsIsOpen && (
                    <i
                        className="bi bi-arrow-bar-left"
                        onClick={() => {
                            setSettingsOpen(true);
                        }}
                    ></i>
                )}
                {isTouchEnabled && settingsIsOpen && (
                    <i
                        className="bi bi-arrow-bar-right"
                        onClick={() => {
                            setSettingsOpen(false);
                        }}
                    ></i>
                )}
            </div>
        </div>
    );
};

export default LobbySettings;
