import { useMemo } from "react";
import "./ReplaySlider.css";

export interface IReplaySliderProps {
    onChange?: (value: number) => void;
    onReplayPause?: () => void;
    className?: string;
    progress?: number;
    isReplayPaused?: boolean;
}

const ReplaySlider = ({
    onChange,
    onReplayPause,
    isReplayPaused,
    className,
    progress,
}: IReplaySliderProps) => {
    const handleChange = (event: any) => {
        const value = event.target.value;
        onChange?.(value);
    };

    const playClasses = useMemo(() => {
        if (isReplayPaused) {
            return `bi bi-play-fill replay-pause`;
        }
        return `bi bi-pause-fill replay-pause`;
    }, [isReplayPaused]);

    return (
        <div className={className}>
            <div className="game-bottom-input">
                <i
                    className={playClasses}
                    id="play"
                    onClick={() => {
                        onReplayPause?.();
                    }}
                ></i>
                <input
                    className="form-range replay-slider"
                    type="range"
                    onChange={handleChange}
                    value={progress}
                ></input>
            </div>
        </div>
    );
};

export default ReplaySlider;
