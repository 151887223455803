import { createAction, unwrapResult } from "@reduxjs/toolkit";
import {
    ILobbyEvent,
    ILobbyEventPlayerData,
    ILobbyStateSyncEvent,
    ILobbyPlayerTeamUpdatedEvent,
    LobbyEventType,
    ILobbyPlayerInfoRefreshed,
    ILobbyPlayerRemoved,
} from "../lobby/model";
import { AppDispatch, RootState } from "../../store";
import { selectGameStore } from "../game/selectors";
import { getUsers } from "../users/thunk";
import { selectUsersById } from "../users/selectors";
import { selectLobbyById } from "./selectors";
import { selectAuthStore } from "../identity/selectors";

export const lobbyDisconnected = createAction<string>("lobby/disconnect");
export const lobbyEvent = createAction<ILobbyEvent>("lobby/lobbyEvent");

type GetState = () => RootState;

export const lobbyEventReceived = (event: ILobbyEvent) => {
    return (dispatch: AppDispatch, getState: GetState) => {
        const handler = new LobbyEventhandler(dispatch, getState);
        switch (event.event_type) {
            case LobbyEventType.player_team_updated: {
                handler.handlePlayerTeamUpdated(
                    event as ILobbyPlayerTeamUpdatedEvent
                );
                break;
            }
            case LobbyEventType.lobby_state_sync: {
                handler.handleFullSync(event as ILobbyStateSyncEvent);
                break;
            }
            case LobbyEventType.player_info_refreshed: {
                handler.handlePlayerInfoRefreshed(
                    event as ILobbyPlayerInfoRefreshed
                );
                break;
            }
            default:
                handler.default(event);
        }
    };
};

class LobbyEventhandler {
    dispatch: AppDispatch;
    getState: GetState;

    constructor(dispatch: AppDispatch, getState: GetState) {
        this.dispatch = dispatch;
        this.getState = getState;
    }

    condLoadUsers(ids: string[]) {
        const state = this.getState();
        const gameStore = selectGameStore(state);
        const lobbyId = gameStore.lobbyId;
        const lobbyStore = selectLobbyById(lobbyId as string)(state);
        const lobbyStorePlayers = (lobbyStore && lobbyStore.players) || [];
        const exIds = lobbyStorePlayers.map((pl) => pl.id);
        const newIds = ids.filter((id: string) => !exIds.includes(id));
        if (newIds.length > 0) {
            const promise = this.dispatch(getUsers(newIds)).then(unwrapResult);
            return promise;
        } else {
            return Promise.resolve();
        }
    }

    handlePlayerTeamUpdated(event: ILobbyPlayerTeamUpdatedEvent) {
        const userId = event.player_id;
        const promise = this.condLoadUsers([userId]);
        promise.then(() => {
            const usersById = selectUsersById(this.getState());
            const user = usersById[userId];
            event.username = user.username;
            event.color = user.color;
            this.dispatch(lobbyEvent(event));
        });
        return;
    }

    handleFullSync(event: ILobbyStateSyncEvent) {
        const userIds = event.lobby_state.players.map(
            (p: ILobbyEventPlayerData) => p.id
        );
        const promise = this.condLoadUsers(userIds);
        promise.then(() => {
            const usersById = selectUsersById(this.getState());
            event.lobby_state.players = event.lobby_state.players.map(
                (p: any) => {
                    const u = usersById[p.id];
                    return {
                        id: p.id,
                        team_id: p.team_id,
                        is_connected: p.is_connected,
                        is_spy_master: p.is_spy_master,
                        username: u.username,
                        color: u.color,
                    };
                }
            );
            this.dispatch(lobbyEvent(event));
        });
    }

    handlePlayerInfoRefreshed(event: ILobbyPlayerInfoRefreshed) {
        this.dispatch(getUsers([event.player_id]))
            .then(unwrapResult)
            .then(() => {
                const usersById = selectUsersById(this.getState());
                const user = usersById[event.player_id];
                event.username = user.username;
                event.color = user.color;
                this.dispatch(lobbyEvent(event));
            });
    }

    default(event: ILobbyEvent) {
        this.dispatch(lobbyEvent(event));
    }
}
