import { FormikProvider, useFormik } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import FormField from "../formFields/FormField";
import {
    ILobbySettings,
    IWordCollection,
} from "../../store/entities/lobby/model";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import {
    selectIsLobbyUpdating,
    selectLobbySettingsFormStore,
    selectWordCollections,
} from "../../store/entities/lobby/selectors";
import { FormErrors, setFormikErrors } from "../../errors";
import SelectInput from "../formFields/SelectInput";
import { selectAuthStore } from "../../store/entities/identity/selectors";
import { useAppDispatch } from "../../reactExt/hooks";
import { getWordCollections } from "../../store/entities/lobby/thunk";
import CheckBoxInput from "../formFields/CheckboxInput";
import "./LobbySettingsModal.css";
import { getUserLanguage, languageToUnicode } from "../../utils";
import { LoadingButton } from "../formFields/LoadingButton";

interface LobbySettingsModalProps {
    onClose: Function;
    onSubmit: (settings: ILobbySettings) => void;
    settings: ILobbySettings;
    isOpen: boolean;
}

const LobbySettingsModal = ({
    settings,
    ...props
}: LobbySettingsModalProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const auth = useSelector(selectAuthStore);
    const wordsCollections = useSelector(selectWordCollections);
    const isUpdating = useSelector(selectIsLobbyUpdating);

    useEffect(() => {
        dispatch(getWordCollections(auth.data.jwt));
    }, []);

    const locale = getUserLanguage();
    const getScore = (collection: IWordCollection) => {
        let value = 0;
        if (collection.language === locale) {
            value += 10;
        }
        if (collection.name === "shpiguni") {
            value += 9;
        }
        return value;
    };
    const wordCollectionOptions = useMemo(() => {
        const coll = [...wordsCollections];
        coll.sort((a, b) => {
            return getScore(b) - getScore(a);
        });
        return coll.map((c) => {
            return {
                text: `${languageToUnicode(c.language)} ${c.name}`,
                value: c.id,
            };
        });
    }, [wordsCollections]);

    if (
        settings.wordsCollectionId === null ||
        settings.wordsCollectionId == undefined
    ) {
        settings.wordsCollectionId =
            wordCollectionOptions[0] && wordCollectionOptions[0].value;
    }

    const minSecondsPerRound = 30;
    const maxMinSecondsPerRound = 240;
    const maxBonusSecondsPerWord = 120;
    const validationSchema = Yup.object().shape({
        secondsPerRound: Yup.number()
            .min(
                minSecondsPerRound,
                `${t("Has to be bigger than or equal")} ${minSecondsPerRound}`
            )
            .max(
                maxMinSecondsPerRound,
                `${t(
                    "Has to be lesser than or equal"
                )} ${maxMinSecondsPerRound}`
            )
            .required(t("Field is required") || "Required"),
        bonusSecondsPerWord: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 0`)
            .max(
                maxBonusSecondsPerWord,
                `${t(
                    "Has to be lesser than or equal"
                )} ${maxBonusSecondsPerWord}`
            )
            .required(t("Field is required") || "Required"),
        instaLoseWordsNumber: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 0`)
            .required(t("Field is required") || "Required"),
        team1WordsNumber: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 1`)
            .required(t("Field is required") || "Required"),
        team2WordsNumber: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 1`)
            .required(t("Field is required") || "Required"),
        neutralWordsNumber: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 0`)
            .required(t("Field is required") || "Required"),
        guessConfirmationSeconds: Yup.number()
            .min(1, `${t("Has to be bigger than or equal")} 1`)
            .required(t("Field is required") || "Required"),
        guessConfirmationMinPlayers: Yup.number()
            .min(0, `${t("Has to be bigger than or equal")} 0`)
            .max(100, `${t("Has to be lesser than or equal")} 100`)
            .required(t("Field is required") || "Required"),
        wordsCollectionId: Yup.string().required(
            t("Field is required") || "Required"
        ),
    });

    const formikData = useFormik({
        initialValues: settings,
        onSubmit: props.onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });
    const wordsSum = useMemo(
        () =>
            formikData.values.neutralWordsNumber +
            formikData.values.instaLoseWordsNumber +
            formikData.values.team1WordsNumber +
            formikData.values.team2WordsNumber,
        [formikData]
    );

    const settingsForm = useSelector(selectLobbySettingsFormStore);
    useEffect(() => {
        setFormikErrors(t, formikData, settingsForm.errors as FormErrors);
    }, [settingsForm.errors]);

    const { handleSubmit, setFieldValue } = formikData;

    const onClose = () => {
        Object.keys(settings).map((key: string) => {
            setFieldValue(key, settings[key as keyof ILobbySettings]);
        });
        props.onClose();
    };

    const on25PresetClick = useCallback(() => {
        setFieldValue("instaLoseWordsNumber", 1);
        setFieldValue("team1WordsNumber", 8);
        setFieldValue("team2WordsNumber", 9);
        setFieldValue("neutralWordsNumber", 7);
    }, [setFieldValue]);
    const on36PresetClick = useCallback(() => {
        setFieldValue("instaLoseWordsNumber", 1);
        setFieldValue("team1WordsNumber", 12);
        setFieldValue("team2WordsNumber", 13);
        setFieldValue("neutralWordsNumber", 10);
    }, [setFieldValue]);
    const on49PresetClick = useCallback(() => {
        setFieldValue("instaLoseWordsNumber", 1);
        setFieldValue("team1WordsNumber", 16);
        setFieldValue("team2WordsNumber", 17);
        setFieldValue("neutralWordsNumber", 15);
    }, [setFieldValue]);
    const on63PresetClick = useCallback(() => {
        setFieldValue("instaLoseWordsNumber", 1);
        setFieldValue("team1WordsNumber", 21);
        setFieldValue("team2WordsNumber", 22);
        setFieldValue("neutralWordsNumber", 19);
    }, [setFieldValue]);

    return (
        <Modal isOpen={props.isOpen} centered={true} animation={"false"}>
            <ModalHeader>{t("Lobby Settings")}</ModalHeader>
            <FormikProvider value={formikData}>
                <form className="form-inline" onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormField
                            name="wordsCollectionId"
                            label={t("Words set") || "Words set"}
                            options={wordCollectionOptions}
                            inputComponent={SelectInput}
                            type="select"
                            inputWidth={8}
                        />
                        <FormField
                            name="secondsPerRound"
                            label={
                                t("Seconds per round") || "Seconds per round"
                            }
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="bonusSecondsPerWord"
                            label={t("Bonus seconds") || "Bonus seconds"}
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="guessConfirmationMinPlayers"
                            label={
                                t("Perc. of players for confirmation") ||
                                "Perc. of players for confirmation"
                            }
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="guessConfirmationSeconds"
                            label={
                                t("Word confirmation seconds") ||
                                "Seconds to confirm"
                            }
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="clueCanTakeGuessTime"
                            label={
                                t("Spy master can take operatives time") ||
                                "Spy master can take operatives time"
                            }
                            inputComponent={CheckBoxInput}
                            type="checkbox"
                            inputWidth={4}
                        />
                        <div className="form-group row mb-2 pb-0 pt-0">
                            <div className="col-sm-4 col-form-label">
                                {t("Table Presets")}
                            </div>
                            <div className="col-sm-8">
                                <div className="row p-2 pt-0 pb-0">
                                    <div className="col-sm-3 p-1 m-0">
                                        <button
                                            className={
                                                "words-preset-btn btn btn-sm btn-outline-secondary mb-2 modal-form-button" +
                                                (wordsSum == 25
                                                    ? " active"
                                                    : "")
                                            }
                                            type="button"
                                            onClick={() => {
                                                on25PresetClick();
                                            }}
                                        >
                                            <i className="bi bi-view-stacked p-1"></i>{" "}
                                            25
                                        </button>
                                    </div>
                                    <div className="col-sm-3 p-1 m-0">
                                        <button
                                            className={
                                                "words-preset-btn btn btn-sm btn-outline-secondary mb-2 modal-form-button" +
                                                (wordsSum == 36
                                                    ? " active"
                                                    : "")
                                            }
                                            type="button"
                                            onClick={() => {
                                                on36PresetClick();
                                            }}
                                        >
                                            <i className="bi bi-grid p-1"></i>{" "}
                                            36
                                        </button>
                                    </div>
                                    <div className="col-sm-3 p-1 m-0">
                                        <button
                                            className={
                                                "words-preset-btn btn btn-sm btn-outline-secondary mb-2 modal-form-button" +
                                                (wordsSum == 49
                                                    ? " active"
                                                    : "")
                                            }
                                            type="button"
                                            onClick={() => {
                                                on49PresetClick();
                                            }}
                                        >
                                            <i className="bi bi-grid-3x2-gap p-1"></i>{" "}
                                            49
                                        </button>
                                    </div>
                                    <div className="col-sm-3 p-1 m-0">
                                        <button
                                            className={
                                                "words-preset-btn btn btn-sm btn-outline-secondary mb-2 modal-form-button" +
                                                (wordsSum == 63
                                                    ? " active"
                                                    : "")
                                            }
                                            type="button"
                                            onClick={() => {
                                                on63PresetClick();
                                            }}
                                        >
                                            <i className="bi bi-grid-3x3-gap p-1"></i>{" "}
                                            63
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormField
                            name="instaLoseWordsNumber"
                            label={t("Black words") || "Black words"}
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="team1WordsNumber"
                            label={t("Team #1 words") || "Team #1 words"}
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="team2WordsNumber"
                            label={t("Team #2 words") || "Team #2 words"}
                            type="number"
                            inputWidth={4}
                        />
                        <FormField
                            name="neutralWordsNumber"
                            label={t("Neutral words") || "Neutral words"}
                            type="number"
                            inputWidth={4}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <LoadingButton
                            disabled={!formikData.isValid}
                            isLoading={isUpdating}
                            text={t("Apply")}
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            data-dismiss="modal"
                            onClick={onClose}
                        >
                            {t("Cancel")}
                        </button>
                    </ModalFooter>
                </form>
            </FormikProvider>
        </Modal>
    );
};

export default LobbySettingsModal;
