import "./GameTimer.css";
import audioFile from "../../assets/countdown.mp3";
import { useEffect, useMemo } from "react";

interface IGameTimer {
    isPlayerTurn: boolean;
    timeLeft: number;
    clueHasTakenGuessTime: boolean;
}

const audio = new Audio(audioFile);

const GameTimer = ({
    timeLeft,
    isPlayerTurn,
    clueHasTakenGuessTime,
}: IGameTimer) => {
    const minute = Math.floor(timeLeft / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);
    let secondsString = ("0" + seconds).slice(-2);

    const lowTime = timeLeft <= 6000 && timeLeft >= 0;
    const beep = timeLeft <= 6500 && timeLeft >= 500;

    const className = useMemo(() => {
        const baseClass = "col game-timer";
        if (lowTime === true && isPlayerTurn == true) {
            return `${baseClass} game-timer-soon`;
        } else if (clueHasTakenGuessTime) {
            return `${baseClass} game-timer-guess-taken`;
        }
        return baseClass;
    }, [lowTime, isPlayerTurn, clueHasTakenGuessTime]);

    useEffect(() => {
        if (beep === true && isPlayerTurn == true) {
            const interval = setInterval(() => {
                audio.currentTime = 0;
                audio.pause();
                audio.play();
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [beep, isPlayerTurn]);

    return (
        <div className={className}>
            {minute}:{secondsString}
        </div>
    );
};

export default GameTimer;
