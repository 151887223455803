import { Input } from "reactstrap";
import { IInputProps } from "./models";

import "./CheckboxInput.css";

const CheckBoxInput = (props: IInputProps) => {
    return (
        <Input
            onBlur={props.onBlur}
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            type="checkbox"
            className="form-control form-check-input checkbox-input"
            onChange={props.onChange}
            checked={props.value}
        ></Input>
    );
};

export default CheckBoxInput;
