import { ModalBody, ModalFooter } from "reactstrap";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import FormField from "../formFields/FormField";
import PasswordInput from "../formFields/PasswordInput";
import { useSelector } from "react-redux";
import { selectLoginFormStore } from "../../store/entities/identity/selectors";
import { useEffect } from "react";
import { FormErrors, setFormikErrors } from "../../errors";
import { LoadingButton } from "../formFields/LoadingButton";

interface UserLoginProps {
    onClose: Function;
    onSubmit: (data: ILoginData) => void;
    onBack: () => void;
    isOpen: boolean;
}

export interface ILoginData {
    login: string;
    password: string;
}

const UserLogin = (props: UserLoginProps) => {
    const { t } = useTranslation();

    const loginForm = useSelector(selectLoginFormStore);

    let profile = {
        login: "",
        password: "",
    };

    const formikData = useFormik({
        initialValues: profile,
        onSubmit: props.onSubmit,
    });
    const { handleSubmit } = formikData;

    useEffect(() => {
        setFormikErrors(t, formikData, loginForm.errors as FormErrors);
    }, [loginForm.errors]);

    return (
        <FormikProvider value={formikData}>
            <form className="form-inline" onSubmit={handleSubmit}>
                <ModalBody>
                    <FormField name="login" label={t("Login") || "Login"} />
                    <FormField
                        name="password"
                        label={t("Password") || "Password"}
                        inputComponent={PasswordInput}
                    />
                </ModalBody>
                <ModalFooter>
                    <LoadingButton
                        disabled={!formikData.isValid}
                        isLoading={loginForm.isLoading}
                        text={t("Log in")}
                    />
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => props.onBack()}
                    >
                        {t("Back")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => props.onClose()}
                    >
                        {t("Close")}
                    </button>
                </ModalFooter>
            </form>
        </FormikProvider>
    );
};

export default UserLogin;
