import moment from "moment";
import { io, Socket } from "socket.io-client";
import { AppDispatch } from "../store/store";
import { IGameEventApi } from "../store/entities/game/model";
import {
    lobbyGameEventReceived,
    serverClockOffset,
} from "../store/entities/game/actions";

interface IGetServerClockResponse {
    server_clock: number;
}

export class GameSocketService {
    matchId: string;
    connected: boolean;
    socket: Socket;
    dispatch: AppDispatch;
    id: string;
    secure: boolean;
    clockInterval: ReturnType<typeof setInterval> | null;

    constructor(
        baseUrl: string,
        matchId: string,
        jwt: string,
        dispatch: AppDispatch
    ) {
        this.id =
            Date.now().toString(36) + Math.random().toString(36).substr(2);
        this.dispatch = dispatch;
        this.matchId = matchId;
        this.connected = false;
        this.secure = process.env.REACT_APP_SECURE === "1";

        const splitted = baseUrl.split("/");
        const url = splitted[0];

        let path;
        if (splitted.length > 1) {
            path = `/${splitted.slice(1).join("/")}/socket.io`;
        } else {
            path = "/socket.io";
        }

        const schema = this.secure ? "wss" : "ws";
        this.socket = io(`${schema}://${url}`, {
            auth: { token: `Bearer ${jwt}` },
            path: path,
            autoConnect: false,
            transports: ["websocket"],
        });
        this.clockInterval = null;

        this.socket.on("connect", () => {
            this.connected = true;
            this.join(this.matchId);

            this.clockInterval = setInterval(() => {
                this.getServerClock();
            }, 10000);
        });

        this.socket.on("disconnect", () => {
            this.connected = false;
        });

        this.socket.on("event", (event: IGameEventApi) => {
            this.dispatch(lobbyGameEventReceived(event));
        });
    }

    connect() {
        if (!this.connected) {
            this.socket.connect();
        }
    }

    static fromConfig(
        matchId: string,
        jwt: string,
        server: string,
        dispatch: AppDispatch
    ) {
        return new GameSocketService(server, matchId, jwt, dispatch);
    }

    close() {
        // this.clockInterval.clo
        this.socket.close();
    }

    updateVoteWord(word: string | null) {
        this.socket.emit("update_vote_word", {
            matchId: this.matchId,
            word: word,
        });
    }

    joinTeam(teamId: number, isSpyMaster: boolean) {
        this.socket.emit("change_team", {
            matchId: this.matchId,
            teamId: teamId,
            isSpyMaster: isSpyMaster,
        });
    }

    addClue(text: string) {
        this.socket.emit("add_clue", {
            matchId: this.matchId,
            text: text,
            wordsNum: null,
        });
    }

    updatePause(isPaused: boolean) {
        this.socket.emit("update_pause", {
            matchId: this.matchId,
            isPaused: isPaused,
        });
    }

    updateVoteSkip(voteSkip: boolean) {
        this.socket.emit("update_vote_skip", {
            matchId: this.matchId,
            voteSkip: voteSkip,
        });
    }

    join(matchId: string) {
        this.socket.emit("join", matchId);
    }

    getServerClock() {
        const now = moment();
        this.socket.emit(
            "get_server_clock",
            (resp: IGetServerClockResponse) => {
                const latency = moment().diff(now, "milliseconds");
                const serverTime = moment.unix(resp.server_clock);
                const diff = serverTime.diff(now, "milliseconds");
                const offset = diff - latency / 2;
                this.dispatch(serverClockOffset(offset));
            }
        );
    }
}
