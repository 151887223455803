import { Link } from "react-router-dom";
import "./TopBar.css";
import UserSection from "./UserSection";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ITopBarProps {
    linkOnClick?: () => void;
    onProfileUpdate?: () => void;
    onLogin?: () => void;
    onLogout?: () => void;
}

const TopBar = ({
    linkOnClick,
    onProfileUpdate,
    onLogin,
    onLogout,
}: ITopBarProps) => {
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        if (linkOnClick !== undefined) {
            linkOnClick();
        }
    }, [linkOnClick]);

    return (
        <div className="top-bar">
            <div className="row px-2">
                <div className="float-left text-start col">
                    <Link to={"/"} onClick={() => onClick()}>
                        {t("shpiguni")}
                    </Link>
                </div>
                <UserSection
                    onProfileUpdate={onProfileUpdate}
                    onLogin={onLogin}
                    onLogout={onLogout}
                    className="float-right text-end col"
                />
            </div>
        </div>
    );
};

export default TopBar;
