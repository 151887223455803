import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "./UserProfileModal.css";
import UserProfileUpdate from "./UserProfileUpdate";
import UserRegistration from "./UserRegistration";
import { IProfile } from "./UserProfileUpdate";
import { IRegistrationData } from "./UserRegistration";
import UserLogin, { ILoginData } from "./UserLogin";

interface UserProfileModalProps {
    onClose: Function;
    onSubmitRegistration: (submitted: IRegistrationData) => void;
    onSubmitProfileUpdate: (submitted: IProfile) => void;
    onSubmitLogin: (submitted: ILoginData) => void;
    onLogout: Function;
    isOpen: boolean;
    username: string;
    color: string;
    language: string;
    email: string;
    login?: string;
}

enum ModalState {
    ProfileUpdate,
    Registration,
    Login,
}

const UserProfileModal = (props: UserProfileModalProps) => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState(ModalState.ProfileUpdate);

    useMemo(() => {
        if (props.isOpen) {
            setModalState(ModalState.ProfileUpdate);
        }
    }, [props.isOpen]);

    useMemo(() => {
        if (props.login) {
            setModalState(ModalState.ProfileUpdate);
        }
    }, [props.login]);

    return (
        <Modal isOpen={props.isOpen} centered={true} animation={"false"}>
            <ModalHeader>{t("User Profile")}</ModalHeader>
            {modalState == ModalState.ProfileUpdate && (
                <UserProfileUpdate
                    onSubmit={props.onSubmitProfileUpdate}
                    onLogout={props.onLogout}
                    onClose={props.onClose}
                    isOpen={props.isOpen}
                    username={props.username}
                    email={props.email}
                    color={props.color}
                    login={props.login}
                    language={props.language}
                    onLoginClick={() => setModalState(ModalState.Login)}
                    onRegistrationClick={() =>
                        setModalState(ModalState.Registration)
                    }
                />
            )}
            {modalState == ModalState.Login && (
                <UserLogin
                    onSubmit={props.onSubmitLogin}
                    onClose={props.onClose}
                    isOpen={props.isOpen}
                    onBack={() => setModalState(ModalState.ProfileUpdate)}
                />
            )}
            {modalState == ModalState.Registration && (
                <UserRegistration
                    onClose={props.onClose}
                    isOpen={props.isOpen}
                    username={props.username}
                    color={props.color}
                    login={props.login}
                    onSubmit={props.onSubmitRegistration}
                    onBack={() => setModalState(ModalState.ProfileUpdate)}
                />
            )}
        </Modal>
    );
};

export default UserProfileModal;
