import "./PlayerPageOverview.css";
import { useAppDispatch } from "../../reactExt/hooks";
import { useEffect, useMemo } from "react";
import { getPlayerStatsCached } from "../../store/entities/stats/thunk";
import { useSelector } from "react-redux";
import {
    selectIsStatsLoading,
    selectStatsByid,
} from "../../store/entities/stats/selectors";
import { Loader } from "../Loader";
import { StatsService } from "../../services/statsService";
import { PlayerWinrateTable } from "./PlayerWinrateTable";
import { PlayerCluesStatsTable } from "./PlayerCluesStatsTable";
import { PlayerBiggestCluesTable } from "./PlayerBiggestCluesTable";
import { MatchHistory } from "./MatchHistory";
import { useTranslation } from "react-i18next";

interface PlayerPageOverviewProps {
    playerId: string;
}

export const PlayerPageOverview = ({ playerId }: PlayerPageOverviewProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectIsStatsLoading);
    const statsStore = useSelector(selectStatsByid(playerId));
    const stats = useMemo(() => {
        return new StatsService(statsStore);
    }, [statsStore]);
    const maxAge = 5 * 60 * 1000;

    useEffect(() => {
        dispatch(getPlayerStatsCached({ playerId, maxAge: maxAge }));
    }, [dispatch, playerId]);

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <PlayerWinrateTable stats={stats} />
            <PlayerCluesStatsTable stats={stats} />
            <PlayerBiggestCluesTable stats={stats} />
            <MatchHistory
                playerId={playerId}
                infinityLoading={false}
                chunkSize={5}
                limit={5}
                title={
                    t("Latest Matches", { ns: "profile" }) || "Latest Matches"
                }
            />
        </>
    );
};
