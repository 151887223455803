import { IStore } from "../../store";
import { GamePhase, IPlayerUser, PlayerState } from "./model";

export const selectGameStore = (state: IStore) => state.game;

export const filterByTeam = (teamId: number) => {
    const lambda = (item: any) => {
        return item.teamId == teamId;
    };
    return lambda;
};

export const selectTeamClues = (teamId: number) => (state: IStore) => {
    const filter = filterByTeam(teamId);
    return selectGameStore(state).clues.list.filter(filter);
};

export const selectPlayerState = (player: IPlayerUser) => (state: IStore) => {
    const gameStore = selectGameStore(state);
    if (gameStore.isFinished) {
        return PlayerState.Wait;
    }
    switch (gameStore.gamePhase) {
        case GamePhase.clue: {
            if (
                player &&
                player.isSpyMaster &&
                player.teamId == gameStore.activeTeamId
            ) {
                return PlayerState.Clue;
            }
            return PlayerState.Wait;
        }
        case GamePhase.guess: {
            if (
                player &&
                !player.isSpyMaster &&
                player.teamId == gameStore.activeTeamId
            ) {
                return PlayerState.Guess;
            }
            return PlayerState.WatchGuess;
        }
        default: {
            return PlayerState.Wait;
        }
    }
};
