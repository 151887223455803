import { useTranslation } from "react-i18next";

import "./MainMenu.css";
import { useSelector } from "react-redux";
import { selectAuthStore } from "../store/entities/identity/selectors";
import MainMenuSelectedIcon from "./MainMenuSelectedIcon";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { Loader } from "./Loader";
import routes from "../routes";

export enum MainMenuState {
    createLobby,
    profile,
    howToPlay,
}

interface MainMenuProps {
    onLobbyClick?: () => void;
    state?: MainMenuState;
}

export const MainMenu = ({ onLobbyClick, state }: MainMenuProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useSelector(selectAuthStore);

    const getMenuItemClass = (isActive: boolean) => {
        if (isActive) {
            return "main-menu-button main-menu-button-active";
        }
        return "main-menu-button";
    };

    const openMatchHistory = useCallback(() => {
        const userId = auth.data.user && auth.data.user.id;
        if (userId) {
            navigate(routes.USER_OVERVIEW);
        }
    }, [auth.data.user && auth.data.user.id]);

    const onSiteNameClick = () => {
        navigate(routes.HOME);
    };
    const openHowToPlay = () => {
        navigate(routes.HOW_TO_PLAY);
    };

    return (
        <div className="main-menu">
            <div
                className="main-menu-site-name"
                onClick={() => {
                    onSiteNameClick();
                }}
            >
                {t("shpiguni")}
            </div>

            <div className="main-menu-button-list">
                {auth.isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div
                            className={getMenuItemClass(
                                state === MainMenuState.howToPlay
                            )}
                            onClick={() => {
                                openHowToPlay();
                            }}
                        >
                            <MainMenuSelectedIcon
                                isActive={state === MainMenuState.howToPlay}
                            />
                            {t("How to Play", { ns: "common" })}
                        </div>
                        <div
                            className={getMenuItemClass(
                                state === MainMenuState.createLobby
                            )}
                            onClick={() => {
                                onLobbyClick?.();
                            }}
                        >
                            <MainMenuSelectedIcon
                                isActive={state === MainMenuState.createLobby}
                            />
                            {t("Create Lobby", { ns: "common" })}
                        </div>
                        <div
                            className={getMenuItemClass(
                                state === MainMenuState.profile
                            )}
                            onClick={() => {
                                openMatchHistory();
                            }}
                        >
                            <MainMenuSelectedIcon
                                isActive={state === MainMenuState.profile}
                            />
                            {t("Match History", { ns: "common" })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
