import "./HowToPlay.css";

import joinAsMasterRu from "../assets/join-as-master-ru.png";
import joinAsOperativeRu from "../assets/join-as-operative-ru.png";
import wordsRu from "../assets/words-ru.png";
import cluesRu from "../assets/clues-ru.png";
import animalsRu from "../assets/animals-2-ru.png";
import { getUserLanguage } from "../utils";

const Ru = () => {
    return (
        <>
            <h1>Правила игры</h1>
            <h4>Онлайн-игра в слова по мотивам codenames</h4>
            <p>
                В игре есть две команды: красные и синие. Есть сетка из слов,
                часть слов в ней принадлежит красной команде, часть — синей.
            </p>
            <p>
                Каждая команда имеет мастера шпионажа и агентов. Только мастер
                шпионажа видит, какие слова относятся к их команде. Его задача
                дать своим агентам подходящую подсказку (кодовое слово), чтобы
                они правильно выбрали слова.
            </p>
            <p>
                Цель каждой команды - первой угадать все свои слова. Команда,
                которая первой достигнет этой цели, выигрывает игру.
            </p>

            <h4>Команды</h4>
            <p>
                Сначала нужно разделить всех игроков на две команды: красную и
                синюю. Как это сделать?
            </p>
            <p>
                Игроки должны нажать “Зайти как оперативник”, чтобы вступить в
                одну из команд.
            </p>
            <div>
                <img src={joinAsMasterRu} className="img-fluid" />
                <img src={joinAsOperativeRu} className="img-fluid" />
            </div>
            <p>
                Один игрок из каждой команды должен нажать “Зайти как
                мастер-шпион”, чтобы стать мастером шпионажа. После этого он
                увидит цвета слов в сетке.
            </p>
            <h4>Слова</h4>
            <img src={wordsRu} className="img-fluid" />
            <p style={{ fontWeight: "bold" }}>Красные и синие слова</p>
            <p>
                Эти слова принадлежат командам. Если при угадывании слов игроки
                выбрали слово другой команды, то их ход завершается, а угаданное
                слово засчитывается другой команде.
            </p>
            <p style={{ fontWeight: "bold" }}>Белые слова</p>
            <p>
                Это нейтральные слова, они не принадлежат ни одной из команд.
                Если игроки при угадывании слов выбирают белое слово, то их ход
                завершается, и никому не засчитывается очко за это слово.
            </p>
            <p style={{ fontWeight: "bold" }}>Черные слова</p>
            <p>
                Если какая-либо команда при угадывании выбирает черное слово, то
                игра немедленно заканчивается, и победа присуждается
                команде-противнику.
            </p>

            <h4>Подсказки</h4>
            <p>
                Мастер шпионажа загадывает несколько своих слов из сетки и
                придумывает для них кодовое слово. Далее он дает своим агентам
                подсказку в виде придуманного кодового слова и цифры, которая
                указывает на количество загаданных слов.
            </p>
            <p>
                Например, мастер видит в сетке слова: “крыса”, “пума”, он
                объединяет их по смыслу и дает своим агентам подсказку:
                “животные 2”
            </p>
            <img src={animalsRu} className="img-fluid" />
            <p>
                Важно, что кодовое слово - это существительное и оно связано с
                выбранными словами только по смыслу!
            </p>
            <ul>
                <li>
                    Нельзя использовать слова со схожим написанием/произношением
                </li>
                <li>Нельзя использовать однокоренные с загаданным слова.</li>
                <li>
                    Нельзя говорить о первых буквах или геометрическом
                    расположении слов в сетке.
                </li>
                <li>Нельзя использовать несуществующие слова.</li>
                <li>
                    Кодовое слово на другом языке не должно являться прямым
                    перевозом загаданного слова.
                </li>
                <li>
                    В качестве кодового слова разрешено использовать имена
                    собственные, даже если они состоят из нескольких слов.
                </li>
            </ul>

            <h4>Отгадывание</h4>
            <p>
                Агенты могут обсудить между собой полученную от мастера шпионов
                подсказку. Чтобы выбрать слово и узнать какого оно цвета,
                игрокам нужно нажать на него.
            </p>
            <img src={cluesRu} className="img-fluid" />
            <p>
                Агенты должны стараться не просто угадывать слова своей команды,
                но и избежать слов противника и нейтральных слов, особенно
                черного слова.
            </p>
        </>
    );
};

const En = () => {
    return (
        <>
            <h1>Game rules</h1>
            <h4>A codenames-like online game</h4>
            <p>
                There are two teams: blue and red. Game has a word board where
                some words belong to the read team and some to the blue.
            </p>
            <p>
                Every team has a spy master and agents. Just the spy master sees
                what words belong to the team. His job is to give right clues to
                his team so they successfully select the team words.
            </p>
            <p>
                Teams compete with each other by who selects their words first.
            </p>

            <h4>Words</h4>
            <img src={wordsRu} className="img-fluid" />
            <p style={{ fontWeight: "bold" }}>Red and Blue words</p>
            <p>
                These words belong to teams. If players select a word from the
                enemy team, then the turn ends and the opponent's score
                decreases.
            </p>
            <p style={{ fontWeight: "bold" }}>White words</p>
            <p>
                White words are neutral and don't belong to any team. If players
                select a white word, then the turn instantly ends keeping the
                score as is.
            </p>
            <p style={{ fontWeight: "bold" }}>Black words</p>
            <p>
                If any team selects a black word, then game instantly ends with
                that team losing.
            </p>

            <h4>Clues</h4>
            <p>
                A spymaster picks several words from the board and comes up with
                a code word that describes them. After that, he gives that code
                to agents alongside a number of the coded words.
            </p>
            <p>
                Look at the following example. A spy master sees words "rat" and
                a "puma" on the board, unies them by its meaning and gives his
                team a clue "animals 2".
            </p>
            <img src={animalsRu} className="img-fluid" />
            <p>
                The code word is a noun and can be tied with the coded words by
                the meaning only.
            </p>
            <ul>
                <li>
                    Code word can't be used just because it's similar in
                    pronunciation / writing.
                </li>
                <li>
                    Code word can't speak about first symbols of the coded word
                    or its potiion on the board.
                </li>
                <li>You can't use words that don't exist.</li>
                <li>
                    Code word from another language can't be a direct
                    translation of words from the table
                </li>
            </ul>
        </>
    );
};

export const HowToPlay = () => {
    const locale = getUserLanguage();

    return (
        <div className="how-to-play">{locale == "ru" ? <Ru /> : <En />}</div>
    );
};
