import { useTranslation } from "react-i18next";
import "moment/locale/ru";

import "./MatchSummary.css";
import moment from "moment";
import { IMatch } from "../../store/entities/match/model";
import { useCallback, useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { getUserLanguage } from "../../utils";

const enum PlayerRole {
    spymaster,
    operative,
}

interface IMatchSummaryProps {
    match: IMatch;
    playerId: string;
    onClick?: (matchId: string) => void;
}

const MatchSummary = ({ match, playerId, onClick }: IMatchSummaryProps) => {
    const { t } = useTranslation();

    const matchId = match.id;

    const selectedWords = useMemo(() => {
        return match.words.filter((w) => !w.isActive);
    }, [match]);
    const team1Selected = useMemo(() => {
        return selectedWords.filter((w) => w.teamId === 1).length;
    }, [selectedWords]);
    const team2Selected = useMemo(() => {
        return selectedWords.filter((w) => w.teamId === 2).length;
    }, [selectedWords]);
    const neutralSelected = useMemo(() => {
        return selectedWords.filter((w) => w.teamId === 0).length;
    }, [selectedWords]);
    const blackSelected = useMemo(() => {
        return selectedWords.filter((w) => w.teamId === -1).length;
    }, [selectedWords]);

    const team1SpyMaster = useMemo(() => {
        const players = match.players.filter(
            (p) => p.isSpyMaster && p.teamId === 1
        );
        if (players.length > 0) {
            return players[0].username;
        }
        return null;
    }, [match]);
    const team2SpyMaster = useMemo(() => {
        const players = match.players.filter(
            (p) => p.isSpyMaster && p.teamId === 2
        );
        if (players.length > 0) {
            return players[0].username;
        }
        return null;
    }, [match]);

    const playerWon = useMemo(() => {
        let res = false;
        match.players.forEach((p) => {
            if (p.id == playerId) {
                res = match.winnerTeamId == p.teamId;
            }
        });
        return res;
    }, [match, playerId]);

    const date = moment(match.createdAt)
        .locale(getUserLanguage())
        .format("MMMM D YYYY, HH:mm")
        .replace(/./, (c) => c.toUpperCase());

    const getIconId = useCallback(
        (text: string) => {
            return `${text}-${matchId}`;
        },
        [matchId]
    );

    return (
        <tr
            className="match-container"
            onClick={() => {
                onClick?.(matchId);
            }}
        >
            <td className="text-center">
                {playerWon ? (
                    <i
                        className="bi bi-check-square-fill player-win"
                        id={getIconId("win")}
                    ></i>
                ) : (
                    <i
                        className="bi bi-x-square-fill player-lose"
                        id={getIconId("win")}
                    ></i>
                )}
                <UncontrolledTooltip placement="top" target={getIconId("win")}>
                    {playerWon
                        ? t("Victory", { ns: "matchSummary" })
                        : t("Defeat", { ns: "matchSummary" })}
                </UncontrolledTooltip>
            </td>
            <td className="text-center match-date">{date}</td>

            <td className="match-words-count">
                {team1Selected > 0 ? (
                    <>
                        <i
                            className="bi bi-check-square-fill match-red"
                            id={getIconId("t1Selected")}
                        ></i>
                        &nbsp;
                        {team1Selected}
                        <UncontrolledTooltip
                            placement="top"
                            target={getIconId("t1Selected")}
                        >
                            {t("Team #1 words selected", {
                                ns: "matchSummary",
                            })}
                        </UncontrolledTooltip>
                    </>
                ) : (
                    <></>
                )}
            </td>
            <td className="match-words-count">
                {team2Selected > 0 ? (
                    <>
                        <i
                            className="bi bi-check-square-fill match-blue"
                            id={getIconId("t2Selected")}
                        ></i>
                        &nbsp;
                        {team2Selected}
                        <UncontrolledTooltip
                            placement="top"
                            target={getIconId("t2Selected")}
                        >
                            {t("Team #2 words selected", {
                                ns: "matchSummary",
                            })}
                        </UncontrolledTooltip>
                    </>
                ) : (
                    <></>
                )}
            </td>
            <td className="match-words-count">
                {neutralSelected > 0 ? (
                    <>
                        <i
                            className="bi bi-check-square-fill"
                            id={getIconId("neutralSelected")}
                        ></i>
                        &nbsp;
                        {neutralSelected}
                        <UncontrolledTooltip
                            placement="top"
                            target={getIconId("neutralSelected")}
                        >
                            {t("Neutral words selected", {
                                ns: "matchSummary",
                            })}
                        </UncontrolledTooltip>
                    </>
                ) : (
                    <></>
                )}
            </td>
            <td className="match-words-count">
                {blackSelected > 0 ? (
                    <>
                        <i
                            className="bi bi-check-square-fill match-black"
                            id={getIconId("blackWordsSelected")}
                        ></i>
                        &nbsp;
                        {blackSelected}
                        <UncontrolledTooltip
                            placement="top"
                            target={getIconId("blackWordsSelected")}
                        >
                            {t("Black words selected", { ns: "matchSummary" })}
                        </UncontrolledTooltip>
                    </>
                ) : (
                    <></>
                )}
            </td>

            <td className="match-red">
                <span id={getIconId("team1SpyMaster")}>
                    {team1SpyMaster ? team1SpyMaster : ""}
                </span>
                <UncontrolledTooltip
                    placement="top"
                    target={getIconId("team1SpyMaster")}
                >
                    {t("Team #1 Spy Master", { ns: "matchSummary" })}
                </UncontrolledTooltip>
            </td>
            <td className="match-blue">
                <span id={getIconId("team2SpyMaster")}>
                    {team2SpyMaster ? team2SpyMaster : ""}
                </span>
                <UncontrolledTooltip
                    placement="top"
                    target={getIconId("team2SpyMaster")}
                >
                    {t("Team #2 Spy Master", { ns: "matchSummary" })}
                </UncontrolledTooltip>
            </td>
        </tr>
    );
};

export default MatchSummary;
