import { IInputProps } from "./models";

const ColorInput = (props: IInputProps) => {
    return (
        <input
            onBlur={props.onBlur}
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            type="color"
            className="form-control form-control-color"
            onChange={props.onChange}
            value={props.value}
        />
    );
};

export default ColorInput;
