import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkPayloadCreator, AsyncThunk } from "@reduxjs/toolkit";
import { IApiError, GraphQLApiError } from "../errors";

interface ThunkAPIConfig {}

export const createAsyncApiThunk = <Returned, ThunkArg = any>(
    type: string,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg> // <-- very unsure of this - have tried many things here
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
    return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(
        type,
        async (arg, thunkAPI) => {
            try {
                return await thunk(arg, thunkAPI);
            } catch (exc: any) {
                if (exc instanceof GraphQLApiError) {
                    const fields = exc.response.fields || [
                        {
                            fields: null,
                            msg: null,
                        },
                    ];
                    const respError = {
                        errors: fields.map((field: any) => {
                            return {
                                code: exc.response["code"],
                                fields: field["field"]
                                    ? [field["field"]]
                                    : null,
                                message: field["msg"],
                            };
                        }),
                    } as IApiError;
                    return thunkAPI.rejectWithValue(respError);
                }
                const respErr = getRestApiError(exc);
                return thunkAPI.rejectWithValue(respErr);
            }
        }
    );
};

export const getRestApiError = (exc: any) => {
    if (!axios.isAxiosError(exc) && !(exc instanceof GraphQLApiError)) {
        throw exc;
    }
    if (axios.isCancel(exc)) {
        throw exc;
    }
    if (!exc.response) {
        throw exc;
    }
    return exc.response.data as IApiError;
};
