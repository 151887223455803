import { hyphenateSync } from "hyphen/ru";
import PlayerColor from "../PlayerColor";
import "./WordCard.css";
import {
    IGuessCandidate,
    IPlayerUser,
    IWord,
} from "../../store/entities/game/model";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProgressBar from "../ProgressBar";

interface WordCardProps {
    word: IWord;
    guessCandidate: IGuessCandidate | null;
    players: IPlayerUser[];
    disabled: boolean;
    onWordClick?: (word: IWord) => void;
    timeLeft: number;
    divRef?: any;
    guessConfirmationSeconds: number;
}

const WordCard = (props: WordCardProps) => {
    const [prevPlayers, setPrevPlayers] = useState<IPlayerUser[]>([]);
    const [animation, setAnimation] = useState<boolean>(false);
    const [wordProgress, setWordProgress] = useState<number | null>(null);

    const text = useMemo(() => {
        return hyphenateSync(props.word.text, { minWordLength: 7 });
    }, [props.word.text]);

    useEffect(() => {
        if (props.players.length > prevPlayers.length) {
            if (animation) {
                setPrevPlayers(props.players);
                setAnimation(false);
                const timeout = setTimeout(() => {
                    setAnimation(true);
                }, 10);
                return () => {
                    clearTimeout(timeout);
                };
            } else {
                setAnimation(true);
            }
        } else {
            setAnimation(false);
        }
        setPrevPlayers(props.players);
    }, [JSON.stringify(props.players)]);

    const classes = useMemo(() => {
        let className = "p-2 word-card unselectable ";
        if (!props.word.isActive) {
            className += "word-card-inactive ";
        }
        if (props.word.teamId !== null) {
            const teamStyle = `team-${props.word.teamId}-btn`;
            className += teamStyle;
        } else {
            const teamStyle = "team-unknown";
            className += teamStyle;
        }
        if (!props.disabled) {
            className += " pointer";
        }
        if (animation) {
            className += " pulse";
        }
        return className;
    }, [props.word, props.disabled, animation]);

    const onClick = useCallback(() => {
        if (!props.disabled) {
            props.onWordClick?.(props.word);
        }
    }, [props.disabled, props.onWordClick]);

    const isWordCandidate = useMemo(() => {
        return (
            props.guessCandidate && props.guessCandidate.word == props.word.text
        );
    }, [props.guessCandidate, props.word]);

    useEffect(() => {
        if (props.guessCandidate && isWordCandidate) {
            const period = props.guessConfirmationSeconds;
            const guessCandidateTimeLeft = props.guessCandidate?.timeLeft || 0;
            const progress =
                (guessCandidateTimeLeft - props.timeLeft) / (period * 10);
            if (progress < 100) {
                setWordProgress(progress || 0);
            } else {
                setWordProgress(100);
            }
        } else {
            setWordProgress(null);
        }
    }, [
        isWordCandidate,
        props.guessCandidate,
        props.guessConfirmationSeconds,
        props.timeLeft,
    ]);

    return (
        <div className={classes} onClick={onClick} ref={props.divRef || null}>
            <span>{text}</span>
            {wordProgress != null && <ProgressBar value={wordProgress} />}
            <div className="word-guess-container w-100 d-flex flex-row-reverse p-2">
                {props.players.map((player) => {
                    return <PlayerColor key={player.id} player={player} />;
                })}
            </div>
        </div>
    );
};

export default WordCard;
