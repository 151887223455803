const routes = {
    HOME: "/",
    QUICK_START: "/quick-start",
    HOW_TO_PLAY: "/how-to-play",
    USER_MATCH_HISTORY: "/player/matches",
    USER_OVERVIEW: "/player/overview",
    PLAYER_MATCH_HISTORY: "/player/:playerId/matches",
    LOBBY: "/lobby/:lobbyId",
    REPLAY: "/replay/:matchId",
    PLAYER_OVERVIEW: "/player/:playerId/overview",
};

export default routes;
