import { useMemo } from "react";
import "./GameStatus.css";
import { useTranslation } from "react-i18next";

export interface IWaitingTurnProps {
    activeTeamId: number;
    winnerTeamId: number | null;
}

const GameStatus = ({ activeTeamId, winnerTeamId }: IWaitingTurnProps) => {
    const { t } = useTranslation();

    const text = useMemo(() => {
        if (winnerTeamId === null) {
            if (activeTeamId == 2) {
                return t("wait for BLUE team's clue", { ns: "lobby" });
            }
            return t("wait for RED team's clue", { ns: "lobby" });
        } else {
            if (winnerTeamId == 2) {
                return t("BLUE team has won", { ns: "lobby" });
            }
            return t("RED team has won", { ns: "lobby" });
        }
    }, [activeTeamId, winnerTeamId]);
    return (
        <div className="game-status">
            <span>{text}</span>
        </div>
    );
};

export default GameStatus;
