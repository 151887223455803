import { unwrapResult } from "@reduxjs/toolkit";
import { ApiService, IMatchPlayerResponse } from "../../../services/apiService";
import { RootState } from "../../store";
import { createAsyncApiThunk } from "../../utils";
import { selectUsersById } from "../users/selectors";
import { getUsersCached } from "../users/thunk";
import { IMatch } from "./model";
import { selectMatchesLoading, selectPlayerMatches } from "./selectors";

interface ISearchMatches {
    playerId: string;
    cursor?: string | null;
    limit?: number;
}

interface ISearchMatchesResult {
    playerId: string;
    nextCursor: string | null;
    cursor?: string | null;
    matches: IMatch[];
}

export const searchMatches = createAsyncApiThunk(
    "searchMatches",
    async ({ playerId, cursor, limit = 20 }: ISearchMatches, thunkAPI) => {
        const service = ApiService.fromConfig();
        const response = await service.searchMatches(
            playerId,
            cursor || null,
            limit
        );

        const players: IMatchPlayerResponse[] = [];
        response.matches
            .map((m) => m.players)
            .forEach((el) => players.push(...el));

        const newIds = players.map((p) => p.playerId);

        const maxUserAge = 60 * 60 * 1000;
        let promise: Promise<any>;
        if (newIds.length > 0) {
            promise = thunkAPI
                .dispatch(
                    getUsersCached({ userIds: newIds, maxAge: maxUserAge })
                )
                .then(unwrapResult);
        } else {
            promise = Promise.resolve();
        }

        const resultPromise = new Promise((resolve, reject) => {
            promise.then(() => {
                const usersById = selectUsersById(
                    thunkAPI.getState() as RootState
                );
                const matches = response.matches.map((m) => {
                    return {
                        id: m.id,
                        lobbyId: m.lobbyId,
                        winnerTeamId: m.winnerTeamId,
                        createdAt: m.createdAt,
                        words: m.words,
                        players: m.players.map((p) => {
                            const u = usersById[p.playerId];
                            return {
                                id: p.playerId,
                                teamId: p.teamId,
                                isSpyMaster: p.isSpymaster,
                                isConnected: true,
                                username: u.username,
                                color: u.color,
                            };
                        }),
                    };
                });
                resolve(matches);
            });
        });
        const matches = await resultPromise;
        return {
            playerId: playerId,
            nextCursor: response.nextCursor,
            cursor: cursor,
            matches: matches as IMatch[],
        } as ISearchMatchesResult;
    }
);

interface ISearchMatchesCached extends ISearchMatches {
    maxAge: number;
}

export const searchMatchesCached = createAsyncApiThunk(
    "searchMatchesCached",
    async (
        { playerId, cursor, limit = 20, maxAge }: ISearchMatchesCached,
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as RootState;
        const playerState = selectPlayerMatches(playerId)(state);
        const loadingState = selectMatchesLoading(playerId)(state);
        const now = Date.now();

        let promise: Promise<ISearchMatchesResult>;
        if (
            (cursor !== undefined && cursor !== null) ||
            now - (loadingState.timestamp || 0) > maxAge ||
            (playerState.matches.length < limit &&
                loadingState.nextCursor !== null)
        ) {
            promise = new Promise((resolve, reject) => {
                thunkAPI
                    .dispatch(searchMatches({ playerId, cursor, limit }))
                    .then(unwrapResult)
                    .then((response) => {
                        resolve(response);
                    });
            });
        } else {
            promise = Promise.resolve({
                playerId: playerId,
                nextCursor: loadingState.nextCursor,
                cursor: cursor,
                matches: playerState.matches,
            } as ISearchMatchesResult);
        }
        const result = await promise;
        return result as ISearchMatchesResult;
    }
);
