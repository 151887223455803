import React from "react";
import { useField } from "formik";
import { IBasicFormFieldProps, IInputOption } from "./models";
import { IInputProps } from "./models";
import { useTranslation } from "react-i18next";

export interface IFormFieldProps extends IBasicFormFieldProps {
    inputComponent?: (props: IInputProps) => JSX.Element;
    options?: IInputOption[];
}

const FormField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    inputWidth = 8,
    type = undefined,
    options = undefined,
    maxLength,
    ...props
}: IFormFieldProps) => {
    const { t } = useTranslation();
    const [field, meta, helper] = useField(name);
    const { value, onChange } = field;

    const hasError = Boolean(meta.error && meta.touched);
    const inputProps = {
        onBlur: (event: any) => helper.setTouched(true),
        disabled: disabled,
        id: id,
        name: name,
        className: "form-control " + (hasError ? "is-invalid" : ""),
        onChange: onChange,
        value: value,
        type: type,
        step: 1,
        maxLength: maxLength,
        options: options,
    };
    let fieldInput = React.createElement(
        props.inputComponent || "input",
        inputProps
    );
    return (
        <div key={id} className={"form-group row mb-2 " + className || ""}>
            <label
                htmlFor={id}
                className={`col-sm-${12 - inputWidth} col-form-label`}
            >
                {label}
            </label>
            <div className={`col-sm-${inputWidth}`}>
                {fieldInput}
                {hasError && (
                    <div className="invalid-feedback">
                        {t(meta.error || "")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormField;
