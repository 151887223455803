import { IStore } from "../../store";

export const selectAuthStore = (state: IStore) => state.auth.auth;

export const selectIsAuthLoading = (state: IStore) =>
    selectAuthStore(state).isLoading;

export const selectAuthFormStore = (state: IStore) => state.auth.forms;

export const selectLoginFormStore = (state: IStore) => state.auth.forms.login;

export const selectRegisterFormStore = (state: IStore) =>
    state.auth.forms.register;
