import { IClue } from "../../store/entities/game/model";
import "./TeamCluesList.css";

interface TeamCluesListProps {
    clues: IClue[];
}

const TeamCluesList = ({ clues }: TeamCluesListProps) => {
    return (
        <div className="team-clues">
            {clues.map((clue, index) => {
                return (
                    <div>
                        <span className="clue" key={index}>
                            {clue.text}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default TeamCluesList;
