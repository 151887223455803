import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "./entities/identity/reducers";
import lobbyReducer from "./entities/lobby/reducers";
import usersReducer from "./entities/users/reducers";
import gameReducer from "./entities/game/reducers";
import replayReducer from "./entities/replay/reducers";
import matchReducer from "./entities/match/reducers";
import statsReducer from "./entities/stats/reducers";

const rootReducer = combineReducers({
    auth: authReducer,
    lobby: lobbyReducer,
    users: usersReducer,
    game: gameReducer,
    replay: replayReducer,
    match: matchReducer,
    stats: statsReducer,
});
export const store = configureStore({
    reducer: rootReducer,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type IStore = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
