import "bootstrap/dist/css/bootstrap.css";
import { EntryPoint, EntryPointState } from "./components/EntryPoint";
import NotFound from "./components/NotFound";
import routes from "./routes";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import "./i18n";
import LobbyGame from "./components/lobby/LobbyGame";
import ReplayGame from "./components/replay/ReplayGame";
import { useAppDispatch } from "./reactExt/hooks";
import { lobbyGameHeartbeat } from "./store/entities/game/actions";
import { useLoadAuth } from "./store/hooks";

function App() {
    const dispatch = useAppDispatch();
    useLoadAuth(dispatch);

    setInterval(() => {
        dispatch(lobbyGameHeartbeat());
    }, 10);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={routes.HOME}
                    element={<EntryPoint state={EntryPointState.howToPlay} />}
                />
                <Route
                    path={routes.QUICK_START}
                    element={<EntryPoint state={EntryPointState.howToPlay} />}
                />
                <Route
                    path={routes.HOW_TO_PLAY}
                    element={<EntryPoint state={EntryPointState.howToPlay} />}
                />
                <Route
                    path={routes.USER_OVERVIEW}
                    element={
                        <EntryPoint state={EntryPointState.playerOverview} />
                    }
                />
                <Route
                    path={routes.USER_MATCH_HISTORY}
                    element={
                        <EntryPoint
                            state={EntryPointState.playerMatchHistory}
                        />
                    }
                />
                <Route
                    path={routes.PLAYER_MATCH_HISTORY}
                    element={
                        <EntryPoint
                            state={EntryPointState.playerMatchHistory}
                        />
                    }
                />
                <Route
                    path={routes.PLAYER_OVERVIEW}
                    element={
                        <EntryPoint state={EntryPointState.playerOverview} />
                    }
                />
                <Route path={routes.LOBBY} element={<LobbyGame />} />
                <Route path={routes.REPLAY} element={<ReplayGame />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
