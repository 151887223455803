import { checkErrorForCode } from "../../../errors";
import { ApiService, IUserProfileUpdate } from "../../../services/apiService";
import { createAsyncApiThunk, getRestApiError } from "../../utils";
import { IUserAuth } from "./model";

export const loadAuth = createAsyncApiThunk(
    "loadAuth",
    async (ignoreStorage: Boolean = false, thunkAPI) => {
        const service = await ApiService.fromConfig();
        if (!localStorage.jwt || ignoreStorage) {
            const response = await service.register({});
            service.saveTokensFromResponse(response);
        } else {
            const auth = service.getUserAuth();
            if (ApiService.isExpiresSoon(auth.exp)) {
                const resp: IUserAuth = await thunkAPI
                    .dispatch(refreshAuth(auth.refresh_token))
                    .unwrap();
                return resp;
            }
            return auth;
        }
        return service.getUserAuth();
    }
);

interface IUpdateProfile {
    refresh_token: string;
    data: IUserProfileUpdate;
}

export const updateProfile = createAsyncApiThunk(
    "updateProfile",
    async ({ refresh_token, data }: IUpdateProfile) => {
        const service = ApiService.fromConfig();
        const response = await service.updateProfile(refresh_token, data);
        service.saveTokensFromResponse(response);
        return service.getUserAuth();
    }
);

interface ILoginData {
    login: string;
    password: string;
}

export const loginAuth = createAsyncApiThunk(
    "login",
    async ({ login, password }: ILoginData) => {
        const service = ApiService.fromConfig();
        const response = await service.login(login, password);
        service.saveTokensFromResponse(response);
        return service.getUserAuth();
    }
);

export const refreshAuth = createAsyncApiThunk(
    "refreshAuth",
    async (refresh_token: string, thunkAPI) => {
        const service = ApiService.fromConfig();
        try {
            const response = await service.refreshSession(refresh_token);
            service.saveTokensFromResponse(response);
            return service.getUserAuth();
        } catch (exc) {
            const respErr = getRestApiError(exc);
            if (checkErrorForCode(respErr, "invalid_refresh_token")) {
                const auth = await thunkAPI.dispatch(loadAuth(true)).unwrap();
                return auth;
            }
            throw exc;
        }
    }
);
