import { useMemo } from "react";
import "./Loader.css";
import { Spinner } from "reactstrap";

export enum LoaderAlign {
    center,
    default,
}

interface LoaderProps {
    align?: LoaderAlign;
    className?: string;
}

export const Loader = ({ align, className }: LoaderProps) => {
    const containerClass = useMemo(() => {
        if (align !== LoaderAlign.default) {
            return `loader-container-center ${className}`;
        }
        return className;
    }, [align, className]);

    return (
        <div className={containerClass}>
            <Spinner className="spinner-border-sm loader" />
        </div>
    );
};
