import { Language } from "./language";

export const arrayToRecordById = <T extends { id: string }>(
    array: T[]
): Record<string, T> => {
    let mapping: Record<string, T> = {};
    array.forEach((item: T) => {
        mapping[item.id] = item;
    });
    return mapping;
};

export const languageToUnicode = (lang: Language) => {
    switch (lang) {
        case Language.ru:
            return "🇷🇺";
        case Language.en:
            return "🇺🇸";
    }
    return "";
};

export const getUserLanguage = (): Language => {
    if (localStorage.lang) {
        return localStorage.lang;
    }

    var availableLanguages = [Language.ru as string, Language.en as string];

    const locale =
        [...(window.navigator.languages || []), window.navigator.language]
            .filter(Boolean)
            .map((language) => language.substr(0, 2))
            .find((language) => availableLanguages.includes(language)) || "en";

    return locale as Language;
};

export const getIsTouchEnabled = (): boolean => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
