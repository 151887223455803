import { Input } from "reactstrap";
import { IInputOption, IInputProps } from "./models";

const SelectInput = (props: IInputProps) => {
    return (
        <Input
            onBlur={props.onBlur}
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            type="select"
            className="form-control form-control-color"
            onChange={props.onChange}
            value={props.value}
        >
            {props.options?.map((option: IInputOption) => {
                return (
                    <option value={option.value} key={option.value}>
                        {option.text}
                    </option>
                );
            })}
        </Input>
    );
};

export default SelectInput;
