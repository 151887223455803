import { combineReducers, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { getUsers } from "./thunk";
import { IUser } from "./model";

interface IUserStore extends IUser {
    timestamp: number;
}

interface IUserStore {
    userById: Record<string, IUserStore>;
}

const usersReducer = createReducer(
    { userById: {} } as IUserStore,
    (builder) => {
        builder.addMatcher(isAnyOf(getUsers.fulfilled), (state, action) => {
            const users = action.payload;
            const timestamp = Date.now();
            users.forEach((user) => {
                state.userById[user.id] = {
                    ...user,
                    timestamp,
                } as IUserStore;
            });
            return state;
        });
    }
);

const isUsersLoadingReducer = createReducer(false, (builder) => {
    builder
        .addMatcher(isAnyOf(getUsers.pending), (state, action) => {
            return true;
        })
        .addMatcher(isAnyOf(getUsers.fulfilled), (state, action) => {
            return false;
        });
});

const reducer = combineReducers({
    data: usersReducer,
    isLoading: isUsersLoadingReducer,
});
export default reducer;
