import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "../../../services/apiService";
import { LobbySocketService } from "../../../services/lobbySocketService";
import { ILobbySettings } from "./model";
import { createAsyncApiThunk } from "../../utils";

interface IJoinLobby {
    lobbySocket: LobbySocketService;
    lobbyId: string;
}

export const joinLobby = createAsyncThunk(
    "joinLobby",
    async ({ lobbySocket, lobbyId }: IJoinLobby) => {
        await lobbySocket.joinLobby(lobbyId);
    }
);

interface ICreateLobby {
    jwt: string;
    settings: ILobbySettings;
}

interface IUpdateLobby {
    jwt: string;
    lobbyId: string;
    settings: ILobbySettings;
}

interface IGetLobby {
    jwt: string;
    lobbyId: string;
}

export const createLobby = createAsyncApiThunk(
    "createLobby",
    async ({ jwt, settings }: ICreateLobby) => {
        const service = ApiService.fromConfig();
        const response = await service.createLobby(jwt, settings);
        return response;
    }
);

export const updateLobby = createAsyncApiThunk(
    "updateLobby",
    async ({ jwt, lobbyId, settings }: IUpdateLobby) => {
        const service = ApiService.fromConfig();
        const response = await service.updateLobby(jwt, lobbyId, settings);
        return response;
    }
);

export const getLobby = createAsyncApiThunk(
    "getLobby",
    async ({ jwt, lobbyId }: IGetLobby) => {
        const service = ApiService.fromConfig();
        const response = await service.getLobby(jwt, lobbyId);
        return response;
    }
);

export const getWordCollections = createAsyncApiThunk(
    "getWordCollections",
    async (jwt: string) => {
        const service = ApiService.fromConfig();
        const response = await service.getWordCollections(jwt);
        return response;
    }
);
