import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { searchMatches } from "./thunk";
import { IMatch } from "./model";

interface IPlayerMatches {
    matches: IMatch[];
}

interface IMatchesState {
    matchesByPlayerId: Record<string, IPlayerMatches>;
}

const initialMatchesState = {
    matchesByPlayerId: {},
} as IMatchesState;

const matchesReducer = createReducer(initialMatchesState, (builder) => {
    builder.addCase(searchMatches.fulfilled, (state, action) => {
        const data = action.payload;
        if (data.cursor) {
            state.matchesByPlayerId[data.playerId].matches = [
                ...state.matchesByPlayerId[data.playerId].matches,
                ...data.matches,
            ];
        } else {
            state.matchesByPlayerId[data.playerId] = {
                matches: data.matches,
            };
        }
    });
});

interface IMatchesLoading {
    isLoading: boolean;
    nextCursor: string | null;
    timestamp: number | null;
}
interface ILoadingState {
    loadingByPlayerId: Record<string, IMatchesLoading>;
}

const initialLoadingState = {
    loadingByPlayerId: {},
} as ILoadingState;

const matchesLoadingReducer = createReducer(initialLoadingState, (builder) => {
    builder
        .addCase(searchMatches.pending, (state, action) => {
            const playerId = action.meta.arg.playerId;
            state.loadingByPlayerId[playerId] = {
                ...(state.loadingByPlayerId[playerId] || {}),
                isLoading: true,
            };
            return state;
        })
        .addCase(searchMatches.fulfilled, (state, action) => {
            const data = action.payload;
            const playerState = state.loadingByPlayerId[data.playerId];
            const cursor = action.payload.cursor;
            const timestamp =
                cursor !== null && cursor !== undefined
                    ? playerState.timestamp
                    : Date.now();
            state.loadingByPlayerId[data.playerId] = {
                isLoading: false,
                nextCursor: action.payload.nextCursor,
                timestamp: timestamp,
            };
            return state;
        });
});

const reducer = combineReducers({
    data: matchesReducer,
    loading: matchesLoadingReducer,
});

export default reducer;
