import { io, Socket } from "socket.io-client";
import { AppDispatch } from "../store/store";
import {
    lobbyDisconnected,
    lobbyEventReceived,
} from "../store/entities/lobby/actions";
import { ILobbyEvent } from "../store/entities/lobby/model";

export class LobbySocketService {
    dispatch: AppDispatch;
    socket: Socket;
    userId: string;
    lobbyId: string;
    connected: boolean;
    secure: boolean;
    id: string;

    constructor(
        userId: string,
        lobbyId: string,
        jwt: string,
        dispatch: AppDispatch
    ) {
        this.id =
            Date.now().toString(36) + Math.random().toString(36).substr(2);
        this.dispatch = dispatch;
        this.userId = userId;
        this.lobbyId = lobbyId;
        this.connected = false;
        this.secure = process.env.REACT_APP_SECURE === "1";
        const baseUrl = process.env.REACT_APP_LOBBY_SOCKET_URL;
        const schema = this.secure ? "wss" : "ws";
        this.socket = io(`${schema}://${baseUrl}`, {
            auth: { token: `Bearer ${jwt}` },
            path: "/socket.io",
            autoConnect: false,
            transports: ["websocket"],
        });

        this.socket.on("event", (event: ILobbyEvent) => {
            this.dispatch(lobbyEventReceived(event));
        });

        this.socket.on("connect", () => {
            this.connected = true;
            this.socket.emit("joinLobby", this.lobbyId);
        });

        this.socket.on("disconnect", () => {
            this.connected = false;
        });
    }

    static fromConfig(
        userId: string,
        lobbyId: string,
        jwt: string,
        dispatch: AppDispatch
    ) {
        return new LobbySocketService(userId, lobbyId, jwt, dispatch);
    }

    connect() {
        this.socket.connect();
    }

    leave() {
        this.socket.emit("leaveLobby", this.lobbyId);
    }

    close() {
        this.socket.close();
        this.dispatch(lobbyDisconnected(this.lobbyId));
    }

    removePlayer(playerId: string) {
        this.socket.emit("removePlayer", {
            lobbyId: this.lobbyId,
            playerId: playerId,
        });
    }

    shuffleTeams() {
        this.socket.emit("shuffleTeams", this.lobbyId);
    }

    refreshPlayerInfo() {
        this.socket.emit("refreshPlayerInfo");
    }

    joinTeam(teamId: number, isSpyMaster: boolean) {
        this.socket.emit("changeTeam", {
            lobbyId: this.lobbyId,
            teamId: teamId,
            isSpyMaster: isSpyMaster,
        });
    }

    updateTeamLock(isLocked: boolean) {
        this.socket.emit("updateTeamLock", {
            lobbyId: this.lobbyId,
            isLocked: isLocked,
        });
    }

    restart() {
        this.socket.emit("restart", this.lobbyId);
    }

    joinLobby(lobbyId: string) {
        this.socket.emit("joinLobby", lobbyId);
    }
}
