import { createReducer } from "@reduxjs/toolkit";
import {
    lobbyGameHeartbeat,
    lobbyGameEvent,
    serverClockOffset,
} from "./actions";
import { lobbyDisconnected, lobbyEvent } from "../lobby/actions";
import { WritableDraft } from "immer/dist/internal";
import {
    ILobbyStateSyncEvent,
    ILobbyPlayerRemoved,
    ILobbyPlayerTeamUpdatedEvent,
    LobbyEventType,
    ILobbyGameServerUpdated,
} from "../lobby/model";
import {
    IClue,
    IWord,
    IWordVote,
    IGuessCandidate,
    GameNotificaitonType,
    IGameNotificaiton,
    GamePhase,
    IGameEvent,
} from "./model";

interface GameState {
    lobbyId: string | null;
    matchId: string | null;
    gameServer: string | null;
    words: {
        list: IWord[];
    };
    wordVotes: {
        list: IWordVote[];
    };
    skipVotes: {
        list: string[];
    };
    clues: {
        list: IClue[];
    };
    score: Record<number, number>;
    gamePhase: string;
    activeTeamId: number;
    winnerTeamId: number | null;
    serverGameClock: number;
    serverClockOffset: number;
    serverTimeLeft: number;
    gameClock: number;
    timeLeft: number;
    isPaused: boolean;
    isFinished: boolean;
    clueCanTakeGuessTime: boolean;
    clueHasTakenGuessTime: boolean;
    guessCandidate: IGuessCandidate | null;
    guessConfirmationSeconds: number;
    notification: IGameNotificaiton | null;
}

const initialState = {
    lobbyId: null,
    matchId: null,
    gameServer: null,
    guessCandidate: null,
    words: {
        list: [] as IWord[],
    },
    wordVotes: {
        list: [],
    },
    skipVotes: {
        list: [],
    },
    clues: {
        list: [] as IClue[],
    },
    score: {},
    gamePhase: "hint",
    activeTeamId: 0,
    winnerTeamId: null,
    serverClockOffset: 0,
    serverGameClock: 0,
    serverTimeLeft: 0,
    gameClock: 0,
    timeLeft: 0,
    isPaused: false,
    isFinished: true,
    guessConfirmationSeconds: 2,
    clueCanTakeGuessTime: false,
    clueHasTakenGuessTime: false,
    notification: null,
} as GameState;

const lobbyReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(lobbyGameHeartbeat, (state, action) => {
            if (state.isPaused || state.isFinished) {
                return;
            }
            return {
                ...state,
                timeLeft: getLocalTimeLeft(
                    state.gameClock,
                    state.serverTimeLeft
                ),
            };
        })
        .addCase(lobbyDisconnected, (state, action) => {
            const lobbyId = action.payload;
            if (lobbyId == state.lobbyId) {
                return initialState;
            }
        })
        .addCase(lobbyEvent, (state, action) => {
            switch (action.payload.event_type) {
                case LobbyEventType.player_removed: {
                    const event = action.payload as ILobbyPlayerRemoved;
                    const newState = removePlayerVotes(event.player_id, state);
                    return checkGameNotificaiton(state, newState);
                }
                case LobbyEventType.player_team_updated: {
                    const event =
                        action.payload as ILobbyPlayerTeamUpdatedEvent;
                    const newState = removePlayerVotes(event.player_id, state);
                    return checkGameNotificaiton(state, newState);
                }
                case LobbyEventType.lobby_state_sync: {
                    const event = action.payload as ILobbyStateSyncEvent;
                    const newState = {
                        ...state,
                        words: {
                            list: [...state.words.list],
                        },
                        clues: {
                            list: [...state.clues.list],
                        },
                        lobbyId: event.lobby_state.lobby_id,
                        matchId: event.lobby_state.match_id,
                        gameServer: event.lobby_state.game_server_location,
                    };
                    if (!newState.gameServer) {
                        newState.words.list = [];
                        newState.clues.list = [];
                        newState.score = {};
                        newState.gamePhase = "hint";
                        newState.activeTeamId = 0;
                        newState.timeLeft = 0;
                        newState.gameClock = 0;
                        newState.serverGameClock = 0;
                        newState.serverTimeLeft = 0;
                        newState.isFinished = true;
                        newState.guessCandidate = null;
                        newState.winnerTeamId = null;
                    }
                    return checkGameNotificaiton(state, newState);
                }
                case LobbyEventType.game_server_updated: {
                    const event = action.payload as ILobbyGameServerUpdated;
                    if (event.lobby_id == state.lobbyId) {
                        const newState = {
                            ...state,
                            matchId: event.match_id || null,
                            gameServer: event.game_server_location || null,
                        };
                        if (!newState.gameServer) {
                            newState.words.list = [];
                            newState.clues.list = [];
                            newState.score = {};
                            newState.gamePhase = "hint";
                            newState.activeTeamId = 0;
                            newState.timeLeft = 0;
                            newState.gameClock = 0;
                            newState.serverGameClock = 0;
                            newState.serverTimeLeft = 0;
                            newState.isFinished = true;
                            newState.guessCandidate = null;
                            newState.winnerTeamId = null;
                        }
                        return checkGameNotificaiton(state, newState);
                    }
                    break;
                }
                default:
                    break;
            }
        })
        .addCase(lobbyGameEvent, (state, action) => {
            const newState = useHandleSync(state, action.payload);
            return checkGameNotificaiton(state, newState);
        })
        .addCase(serverClockOffset, (state, action) => {
            const newState = {
                ...state,
                serverClockOffset: action.payload,
            };
            newState.gameClock =
                newState.serverGameClock - newState.serverClockOffset;
            if (!newState.isPaused && !newState.isFinished) {
                newState.timeLeft = getLocalTimeLeft(
                    newState.gameClock,
                    newState.serverTimeLeft
                );
            }
            return newState;
        });
});

function useHandleSync(state: WritableDraft<GameState>, event: IGameEvent) {
    if (state.lobbyId != null && state.lobbyId !== event.lobbyId) {
        return state;
    }
    const newState = {
        ...state,
        matchId: event.matchId,
        lobbyId: event.lobbyId,
        words: { list: event.data.words },
        wordVotes: { list: event.data.wordVotes },
        skipVotes: { list: event.data.skipVotes },
        clues: { list: event.data.clues },
        guessCandidate: event.data.guessCandidate,
        guessConfirmationSeconds: event.data.guessConfirmationSeconds,
        clueCanTakeGuessTime: event.data.clueCanTakeGuessTime,
        clueHasTakenGuessTime: event.data.clueHasTakenGuessTime,
        gamePhase: event.data.gamePhase,
        activeTeamId: event.data.activeTeamId,
        winnerTeamId: event.data.winnerTeamId,
        isPaused: event.data.isPaused,
        isFinished: event.data.isFinished,
        score: {},
        serverTimeLeft: event.data.timeLeft,
        serverGameClock: event.data.gameClock,
    } as GameState;
    newState.gameClock = newState.serverGameClock - newState.serverClockOffset;
    event.data.score.forEach((team) => {
        newState.score[team.teamId] = team.score;
    });

    if (newState.isPaused || newState.isFinished) {
        newState.timeLeft = newState.serverTimeLeft;
    } else {
        newState.timeLeft = getLocalTimeLeft(
            newState.gameClock,
            newState.serverTimeLeft
        );
    }
    return newState;
}

const removePlayerVotes = (playerId: string, state: GameState) => {
    return {
        ...state,
        wordVotes: {
            list: state.wordVotes.list.filter((v) => {
                return v.playerId != playerId;
            }),
        },
        guessCandidate: null,
    };
};

const getLocalTimeLeft = (
    gameClock: number,
    serverTimeLeft: number
): number => {
    const now = Date.now();
    const diff = now - gameClock;
    const timeLeft = serverTimeLeft - diff;
    if (timeLeft < 0) {
        return 0;
    }
    return timeLeft;
};

const checkGameNotificaiton = (oldState: GameState, newState: GameState) => {
    if (
        oldState.activeTeamId > 0 &&
        newState.activeTeamId != oldState.activeTeamId
    ) {
        return {
            ...newState,
            notification: {
                gameClock: newState.gameClock,
                type: GameNotificaitonType.turn_changed,
            },
        };
    }
    if (oldState.gamePhase && oldState.gamePhase != newState.gamePhase) {
        return {
            ...newState,
            notification: {
                gameClock: newState.gameClock,
                type: GameNotificaitonType.turn_changed,
            },
        };
    }
    if (newState.gamePhase == GamePhase.guess) {
        const oldVotes = getVotes(oldState);
        const newVotes = getVotes(newState);
        if (JSON.stringify(oldVotes) != JSON.stringify(newVotes)) {
            return {
                ...newState,
                notification: {
                    gameClock: newState.gameClock,
                    type: GameNotificaitonType.word_guess,
                },
            };
        }
    }
    if (oldState.guessCandidate != newState.guessCandidate) {
        return {
            ...newState,
            notification: {
                gameClock: newState.gameClock,
                type: GameNotificaitonType.word_guess_candidate,
            },
        };
    }
    return newState;
};

const getVotes = (state: GameState) => {
    return state.wordVotes.list
        .map((vote) => vote.word)
        .concat(state.skipVotes.list.map((vote) => ""))
        .sort();
};

export default lobbyReducer;
