import { useTranslation } from "react-i18next";
import { StatsService } from "../../services/statsService";

interface PlayerWinrateTableProps {
    stats: StatsService;
}

export const PlayerWinrateTable = ({ stats }: PlayerWinrateTableProps) => {
    const { t } = useTranslation();

    if (!stats.gamesAsMaster && !stats.gamesAsOperative) {
        return <></>;
    }
    return (
        <table className="table table-sm overview-table">
            <thead>
                <tr>
                    <th>{t("Winrate By Role", { ns: "profile" })}</th>
                    <th>{t("Matches Played", { ns: "profile" })}</th>
                    <th>{t("Winrate", { ns: "profile" })}</th>
                </tr>
            </thead>
            <tbody>
                {stats.gamesAsOperative > 0 && (
                    <tr>
                        <td>{t("As Operative", { ns: "profile" })}</td>
                        <td>{stats.gamesAsOperative}</td>
                        <td>{stats.winrateAsOperative?.toFixed(2)}%</td>
                    </tr>
                )}
                {stats.gamesAsMaster > 0 && (
                    <tr>
                        <td>{t("As Spy Master", { ns: "profile" })}</td>
                        <td>{stats.gamesAsMaster}</td>
                        <td>{stats.winrateAsMaster?.toFixed(2)}%</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};
