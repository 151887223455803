import { useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";

import { IInputProps } from "./models";
import { Language } from "../../language";
import { languageToUnicode } from "../../utils";

const LanguageInput = (props: IInputProps) => {
    const { t } = useTranslation();

    return (
        <FormGroup>
            <Input
                type="select"
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                value={props.value}
                className="form-control"
            >
                <option value={Language.ru}>
                    {languageToUnicode(Language.ru)} {t("Russian")}
                </option>
                <option value={Language.en}>
                    {languageToUnicode(Language.en)} {t("English")}
                </option>
            </Input>
        </FormGroup>
    );
};

export default LanguageInput;
