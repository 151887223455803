import "./OverlayIcon.css";

export interface IOverlayIconProps {
    className: string;
}

const OverlayIcon = ({ className }: IOverlayIconProps) => {
    return (
        <div className="overlay-icon">
            <i className={className}></i>
        </div>
    );
};

export default OverlayIcon;
