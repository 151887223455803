import { combineReducers, createReducer, isAnyOf } from "@reduxjs/toolkit";
import {
    ILobbyGameServerUpdated,
    ILobbyPlayerConnectionUpdated,
    ILobbyPlayerInfoRefreshed,
    ILobbyPlayerRemoved,
    ILobbyPlayerTeamUpdatedEvent,
    ILobbySettings,
    ILobbyStateSyncEvent,
    ILobbyTeamLockUpdated,
    IWordCollection,
    LobbyEventType,
} from "./model";
import {
    createLobby,
    getLobby,
    getWordCollections,
    updateLobby,
} from "./thunk";
import { IApiError, transformApiErrorItem } from "../../../errors";
import { lobbyEvent } from "./actions";
import { IGameEvent, IPlayerUser } from "../game/model";
import { getUsers } from "../users/thunk";
import { lobbyGameEvent } from "../game/actions";

interface ILobby {
    id: string;
    hostUserId: string;
    isTeamLocked: boolean;
    players: IPlayerUser[];
    settings: ILobbySettings | null;
    matchId: string | null;
    gameServer: string | null;
}

interface ILobbyStore {
    lobbyById: Record<string, ILobby>;
    wordsCollections: IWordCollection[];
}

const lobbyReducer = createReducer(
    { lobbyById: {}, wordsCollections: [] } as ILobbyStore,
    (builder) => {
        builder
            .addCase(lobbyEvent, (state, action) => {
                switch (action.payload.event_type) {
                    case LobbyEventType.lobby_state_sync: {
                        const event = action.payload as ILobbyStateSyncEvent;
                        const lobby_state = event.lobby_state;
                        const curState = state.lobbyById[lobby_state.lobby_id];
                        state.lobbyById[lobby_state.lobby_id] = {
                            id: lobby_state.lobby_id,
                            hostUserId: lobby_state.host_user_id,
                            matchId: event.lobby_state.match_id,
                            players: event.lobby_state.players.map((p) => {
                                return {
                                    id: p.id,
                                    teamId: p.team_id,
                                    isConnected: p.is_connected,
                                    isSpyMaster: p.is_spy_master,
                                    username: p.username as string,
                                    color: p.color as string,
                                };
                            }),
                            gameServer: lobby_state.game_server_location,
                            isTeamLocked: lobby_state.is_team_locked,
                            settings: (curState && curState.settings) || null,
                        };
                        break;
                    }
                    case LobbyEventType.game_server_updated: {
                        const event = action.payload as ILobbyGameServerUpdated;
                        const lobbyState = state.lobbyById[event.lobby_id];
                        if (lobbyState !== undefined) {
                            lobbyState.matchId = event.match_id || null;
                            lobbyState.gameServer =
                                event.game_server_location || null;
                        }
                        break;
                    }
                    case LobbyEventType.player_removed: {
                        const event = action.payload as ILobbyPlayerRemoved;
                        const lobbyState = state.lobbyById[event.lobby_id];
                        if (lobbyState !== undefined) {
                            lobbyState.players.forEach((p) => {
                                if (p.id == event.player_id) {
                                    p.teamId = 0;
                                    p.isSpyMaster = false;
                                }
                            });
                        }
                        break;
                    }
                    case LobbyEventType.player_team_updated: {
                        const event =
                            action.payload as ILobbyPlayerTeamUpdatedEvent;
                        let playerUpdated = false;
                        const lobbyState = state.lobbyById[event.lobby_id];
                        if (lobbyState !== undefined) {
                            lobbyState.players.forEach((p) => {
                                if (p.id == event.player_id) {
                                    p.username = event.username as string;
                                    p.color = event.color as string;
                                    p.teamId = event.team_id;
                                    p.isConnected = event.is_connected;
                                    p.isSpyMaster = event.is_spy_master;
                                    playerUpdated = true;
                                }
                            });
                            if (playerUpdated === false) {
                                lobbyState.players.push({
                                    id: event.player_id,
                                    username: event.username as string,
                                    color: event.color as string,
                                    teamId: event.team_id,
                                    isConnected: event.is_connected,
                                    isSpyMaster: event.is_spy_master,
                                });
                            }
                        }
                        break;
                    }
                    case LobbyEventType.player_info_refreshed: {
                        const event =
                            action.payload as ILobbyPlayerInfoRefreshed;
                        const lobbyState = state.lobbyById[event.lobby_id];
                        if (lobbyState !== undefined) {
                            lobbyState.players.forEach((p) => {
                                if (p.id == event.player_id) {
                                    p.username = event.username as string;
                                    p.color = event.color as string;
                                }
                            });
                        }
                        break;
                    }
                    case LobbyEventType.team_lock_updated: {
                        const event = action.payload as ILobbyTeamLockUpdated;
                        const lobbyState = state.lobbyById[event.lobby_id];

                        if (lobbyState !== undefined) {
                            lobbyState.isTeamLocked = event.is_locked;
                        }
                        break;
                    }
                    case LobbyEventType.player_connection_updated: {
                        const event =
                            action.payload as ILobbyPlayerConnectionUpdated;
                        const lobbyState = state.lobbyById[event.lobby_id];

                        if (lobbyState !== undefined) {
                            lobbyState.players.forEach((pl) => {
                                if (pl.id == event.player_id) {
                                    pl.isConnected = event.is_connected;
                                }
                            });
                        }
                        break;
                    }
                    default:
                        break;
                }
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                Object.keys(state.lobbyById).forEach((lobbyId) => {
                    const lobbyState = state.lobbyById[lobbyId];
                    lobbyState.players.forEach((p: IPlayerUser) => {
                        action.payload.forEach((user) => {
                            lobbyState.players.map((pl: IPlayerUser) => {
                                if (pl.id == user.id) {
                                    pl.color = user.color;
                                    pl.username = user.username;
                                }
                            });
                        });
                    });
                });
            })
            .addCase(lobbyGameEvent, (state, action) => {
                const event = action.payload as IGameEvent;
                const lobbyState = state.lobbyById[event.lobbyId];
                if (lobbyState !== undefined) {
                    // handle the case when game server
                    // didn't get the lobby state yet
                    if (event.data.players.length > 0) {
                        lobbyState.players = event.data.players;
                        lobbyState.isTeamLocked = event.data.isTeamLocked;
                    }
                }
            })
            .addCase(getWordCollections.fulfilled, (state, action) => {
                state.wordsCollections = action.payload as IWordCollection[];
            })
            .addMatcher(
                isAnyOf(
                    createLobby.fulfilled,
                    getLobby.fulfilled,
                    updateLobby.fulfilled
                ),
                (state, action) => {
                    const lobby = action.payload;
                    const settings = lobby.settings;
                    const lobbyState = state.lobbyById[lobby.id];
                    if (lobbyState == undefined) {
                        state.lobbyById[lobby.id] = {
                            id: lobby.id,
                            hostUserId: lobby.hostUserId,
                            isTeamLocked: false,
                            gameServer: null,
                            players: [],
                            matchId: null,
                            settings: {
                                secondsPerRound: settings.secondsPerRound,
                                bonusSecondsPerWord:
                                    settings.bonusSecondsPerWord,
                                team1WordsNumber: settings.team1WordsNumber,
                                team2WordsNumber: settings.team2WordsNumber,
                                instaLoseWordsNumber:
                                    settings.instaLoseWordsNumber,
                                neutralWordsNumber: settings.neutralWordsNumber,
                                guessConfirmationSeconds:
                                    settings.guessConfirmationSeconds,
                                guessConfirmationMinPlayers:
                                    settings.guessConfirmationMinPlayers,
                                wordsCollectionId: settings.wordsCollectionId,
                                clueCanTakeGuessTime:
                                    settings.clueCanTakeGuessTime,
                            },
                        };
                    } else {
                        lobbyState.hostUserId = lobby.hostUserId;
                        lobbyState.settings = {
                            secondsPerRound: settings.secondsPerRound,
                            bonusSecondsPerWord: settings.bonusSecondsPerWord,
                            team1WordsNumber: settings.team1WordsNumber,
                            team2WordsNumber: settings.team2WordsNumber,
                            instaLoseWordsNumber: settings.instaLoseWordsNumber,
                            neutralWordsNumber: settings.neutralWordsNumber,
                            guessConfirmationSeconds:
                                settings.guessConfirmationSeconds,
                            guessConfirmationMinPlayers:
                                settings.guessConfirmationMinPlayers,
                            wordsCollectionId: settings.wordsCollectionId,
                            clueCanTakeGuessTime: settings.clueCanTakeGuessTime,
                        };
                    }
                }
            );
    }
);

interface lobbySettingsFormErrors {
    secondsPerRound?: string;
    bonusSecondsPerWord?: string;
    instaLoseWordsNumber?: string;
    team1WordsNumber?: string;
    team2WordsNumber?: string;
    neutralWordsNumber?: string;
}

interface lobbySettingsFormState {
    errors: lobbySettingsFormErrors;
}

const lobbySettingsFormReducer = createReducer(
    { errors: {} } as lobbySettingsFormState,
    (builder) => {
        builder.addMatcher(
            isAnyOf(createLobby.rejected, updateLobby.rejected),
            (state, action) => {
                console.log(action.payload);
                const payload = action.payload as IApiError;
                const newState = { errors: {} } as lobbySettingsFormState;
                payload.errors.forEach((item) => {
                    item.fields?.forEach((field) => {
                        if (
                            field == "secondsPerRound" ||
                            field == "bonusSecondsPerWord" ||
                            field == "instaLoseWordsNumber" ||
                            field == "team1WordsNumber" ||
                            field == "team2WordsNumber" ||
                            field == "neutralWordsNumber"
                        ) {
                            newState.errors[field] =
                                transformApiErrorItem(item);
                        }
                    });
                });
                return newState;
            }
        );
    }
);

const isLobbyUpdatingReducer = createReducer(false, (builder) => {
    builder
        .addMatcher(
            isAnyOf(
                createLobby.fulfilled,
                updateLobby.fulfilled,
                createLobby.rejected,
                updateLobby.rejected
            ),
            (state, action) => {
                return false;
            }
        )
        .addMatcher(
            isAnyOf(createLobby.pending, updateLobby.pending),
            (state, action) => {
                return true;
            }
        );
});
const isLobbyLoadingReducer = createReducer(false, (builder) => {
    builder
        .addMatcher(
            isAnyOf(createLobby.fulfilled, getLobby.fulfilled),
            (state, action) => {
                return false;
            }
        )
        .addMatcher(
            isAnyOf(createLobby.pending, getLobby.pending),
            (state, action) => {
                return true;
            }
        );
});

const formsReducer = combineReducers({
    lobbySettings: lobbySettingsFormReducer,
});

const reducer = combineReducers({
    data: lobbyReducer,
    isLoading: isLobbyLoadingReducer,
    isUpdating: isLobbyUpdatingReducer,
    forms: formsReducer,
});
export default reducer;
