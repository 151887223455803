import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../reactExt/hooks";
import { createLobby } from "../store/entities/lobby/thunk";
import { useCallback, useMemo, useState } from "react";
import LobbySettingsModal from "./modals/LobbySettingsModal";
import { useSelector } from "react-redux";
import { selectAuthStore } from "../store/entities/identity/selectors";
import { ILobbySettings } from "../store/entities/lobby/model";
import { unwrapResult } from "@reduxjs/toolkit";
import UserSection from "./UserSection";
import { MainMenu, MainMenuState } from "./MainMenu";
import { HowToPlay } from "./HowToPlay";
import "./EntryPoint.css";
import routes from "../routes";
import { LobbySettingsService } from "../services/lobbySettingsService";
import { PlayerPage } from "./playerPage/PlayerPage";
import { PlayerPageMenuState } from "./playerPage/PlayerPageMenu";

export enum EntryPointState {
    playerMatchHistory,
    playerOverview,
    howToPlay,
}

interface EntryPointProps {
    state?: EntryPointState;
}

export const EntryPoint = ({ state }: EntryPointProps) => {
    const { playerId: playerId } = useParams<{ playerId: string }>();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const auth = useSelector(selectAuthStore);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const lobbySettings = useMemo(() => LobbySettingsService.load(), []);

    const onSubmitLobby = useCallback(
        (settings: ILobbySettings) => {
            dispatch(createLobby({ jwt: auth.data.jwt, settings: settings }))
                .then(unwrapResult)
                .then((response) => {
                    LobbySettingsService.save(settings);
                    closeModal();
                    const lobbyId = response.id;
                    navigate(routes.LOBBY.replace(":lobbyId", lobbyId));
                });
        },
        [auth.data.jwt]
    );
    const menuState = useMemo(() => {
        switch (state) {
            case EntryPointState.howToPlay: {
                return MainMenuState.howToPlay;
            }
            case EntryPointState.playerMatchHistory: {
                return MainMenuState.profile;
            }
            case EntryPointState.playerOverview: {
                return MainMenuState.profile;
            }
            default:
                return undefined;
        }
    }, [state]);
    const historyPlayerId = useMemo(() => {
        if (playerId) {
            return playerId;
        }
        return auth.data.user && auth.data.user.id;
    }, [playerId, auth.data.user && auth.data.user.id]);

    return (
        <div className="vw-100">
            <UserSection className="float-right text-end entry-point-user-section" />

            <div className="vw-100 entry-point-body">
                <title>{t("shpiguni")} </title>

                <div className="entry-point-container">
                    <MainMenu
                        onLobbyClick={() => {
                            setModalIsOpen(true);
                        }}
                        state={menuState}
                    />

                    <div className="entry-point-content-wrap">
                        {state === EntryPointState.howToPlay && <HowToPlay />}
                        {state == EntryPointState.playerMatchHistory &&
                            historyPlayerId && (
                                <PlayerPage
                                    playerId={historyPlayerId}
                                    state={PlayerPageMenuState.matchHistory}
                                />
                            )}
                        {state == EntryPointState.playerOverview &&
                            historyPlayerId && (
                                <PlayerPage
                                    playerId={historyPlayerId}
                                    state={PlayerPageMenuState.overview}
                                />
                            )}
                    </div>
                </div>
            </div>

            <LobbySettingsModal
                isOpen={modalIsOpen}
                onSubmit={onSubmitLobby}
                onClose={closeModal}
                settings={lobbySettings}
            />
        </div>
    );
};
