import { combineReducers, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IApiError, transformApiErrorItem } from "../../../errors";
import { IUserAuth } from "./model";
import { loadAuth, loginAuth, updateProfile, refreshAuth } from "./thunk";

const isAuthLoadingReducer = createReducer(true, (builder) => {
    builder
        .addMatcher(isAnyOf(loadAuth.pending), (state, action) => {
            return true;
        })
        .addMatcher(
            isAnyOf(
                loadAuth.fulfilled,
                updateProfile.fulfilled,
                loginAuth.fulfilled,
                loadAuth.rejected,
                updateProfile.rejected,
                loginAuth.rejected
            ),
            (state, action) => {
                return false;
            }
        );
});

const authDataReducer = createReducer({} as IUserAuth, (builder) => {
    builder.addMatcher(
        isAnyOf(
            loadAuth.fulfilled,
            updateProfile.fulfilled,
            loginAuth.fulfilled,
            refreshAuth.fulfilled
        ),
        (state, action) => {
            return action.payload;
        }
    );
});

interface loginFormErrors {
    login?: string;
    password?: string;
}

interface loginFormState {
    errors: loginFormErrors;
    isLoading: boolean;
}

const loginFormReducer = createReducer(
    { errors: {}, isLoading: false } as loginFormState,
    (builder) => {
        builder
            .addCase(loginAuth.rejected, (state, action) => {
                const payload = action.payload as IApiError;
                const newState = {
                    errors: {},
                    isLoading: false,
                } as loginFormState;
                payload.errors.forEach((item) => {
                    item.fields?.forEach((field) => {
                        if (field == "login" || field == "password") {
                            newState.errors[field] =
                                transformApiErrorItem(item);
                        }
                    });
                });
                return newState;
            })
            .addCase(loginAuth.pending, (state, action) => {
                return { errors: {}, isLoading: true };
            })
            .addCase(loginAuth.fulfilled, (state, action) => {
                return { errors: {}, isLoading: false };
            });
    }
);

interface updateFormErrors {
    login?: string;
    password?: string;
    username?: string;
    color?: string;
    email?: string;
}

interface updateFormState {
    errors: updateFormErrors;
    isLoading: boolean;
}

const registerFormReducer = createReducer(
    { errors: {}, isLoading: false } as updateFormState,
    (builder) => {
        builder
            .addCase(updateProfile.rejected, (state, action) => {
                const payload = action.payload as IApiError;
                const newState = {
                    errors: {},
                    isLoading: false,
                } as updateFormState;
                payload.errors.forEach((item) => {
                    item.fields?.forEach((field) => {
                        const f = field.replace("new_", "");
                        if (
                            f == "login" ||
                            f == "password" ||
                            f == "username" ||
                            f == "color" ||
                            f == "email"
                        ) {
                            newState.errors[f] = transformApiErrorItem(item);
                        }
                    });
                });
                return newState;
            })
            .addCase(updateProfile.pending, (state, action) => {
                return { errors: {}, isLoading: true };
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                return { errors: {}, isLoading: false };
            });
    }
);

const authReducer = combineReducers({
    data: authDataReducer,
    isLoading: isAuthLoadingReducer,
});
const formsReducer = combineReducers({
    login: loginFormReducer,
    register: registerFormReducer,
});

const reducer = combineReducers({
    auth: authReducer,
    forms: formsReducer,
});
export default reducer;
