import { FormikProvider, useFormik } from "formik";
import { ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import "./UserProfileModal.css";
import FormField from "../formFields/FormField";
import ColorInput from "../formFields/ColorInput";
import PasswordInput from "../formFields/PasswordInput";
import { useSelector } from "react-redux";
import { selectRegisterFormStore } from "../../store/entities/identity/selectors";
import { useEffect } from "react";
import { FormErrors, setFormikErrors } from "../../errors";
import { LoadingButton } from "../formFields/LoadingButton";

interface UserRegistrationProps {
    onClose: Function;
    onSubmit: (submitted: IRegistrationData) => void;
    onBack: () => void;
    isOpen: boolean;
    username: string;
    color: string;
    login?: string;
}

export interface IRegistrationData {
    username: string;
    color: string;
    login: string;
    email: string;
    password: string;
}

const UserRegistration = (props: UserRegistrationProps) => {
    const { t } = useTranslation();

    let profile = {
        username: props.username,
        color: props.color,
        email: "",
        login: "",
        password: "",
    };

    const minLoginLength = 6;
    const maxLoginLength = 20;
    const minPasswordLength = 6;
    const maxPasswordLength = 20;
    const minNameLen = 1;
    const maxNameLen = 64;
    const validationSchema = Yup.object().shape({
        login: Yup.string()
            .min(
                minLoginLength,
                `${t("Has to be longer than or equal")} ${minLoginLength}`
            )
            .max(
                maxLoginLength,
                `${t("Has to be shorter than or equal")} ${maxLoginLength}`
            )
            .required(t("Field is required") || "Required"),
        password: Yup.string()
            .min(
                minPasswordLength,
                `${t("Has to be longer than or equal")} ${minPasswordLength}`
            )
            .max(
                maxPasswordLength,
                `${t("Has to be shorter than or equal")} ${maxPasswordLength}`
            )
            .required(t("Field is required") || "Required"),
        username: Yup.string()
            .min(
                minNameLen,
                `${t("Has to be longer than or equal")} ${minNameLen}`
            )
            .max(
                maxNameLen,
                `${t("Has to be shorter than or equal")} ${maxNameLen}`
            ),
        email: Yup.string().email("Not a valid email"),
    });
    const formikData = useFormik({
        initialValues: profile,
        onSubmit: props.onSubmit,
        validationSchema: validationSchema,
    });
    const { handleSubmit } = formikData;

    const registerForm = useSelector(selectRegisterFormStore);
    useEffect(() => {
        setFormikErrors(t, formikData, registerForm.errors as FormErrors);
    }, [registerForm.errors]);

    return (
        <FormikProvider value={formikData}>
            <form className="form-inline" onSubmit={handleSubmit}>
                <ModalBody>
                    <FormField name="login" label={t("Login") || "Login"} />
                    <FormField name="email" label={t("Email") || "Email"} />
                    <FormField
                        name="password"
                        label={t("Password") || "Password"}
                        inputComponent={PasswordInput}
                    />
                    <FormField
                        name="username"
                        maxLength={64}
                        label={t("Username") || "Username"}
                    />
                    <FormField
                        name="color"
                        label={t("Color") || "Color"}
                        inputComponent={ColorInput}
                    />
                </ModalBody>
                <ModalFooter>
                    <LoadingButton
                        disabled={!formikData.isValid || !formikData.isValid}
                        isLoading={registerForm.isLoading}
                        text={t("Register")}
                    />
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => props.onBack()}
                    >
                        {t("Back")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => props.onClose()}
                    >
                        {t("Close")}
                    </button>
                </ModalFooter>
            </form>
        </FormikProvider>
    );
};

export default UserRegistration;
