import "./GameBottom.css";
import {
    IGuessCandidate,
    IPlayerUser,
    PlayerState,
} from "../../store/entities/game/model";
import EndTurnButton from "./EndTurnButton";
import ClueInput from "./ClueInput";
import GameStatus from "./GameStatus";
import ReplaySlider from "../replay/ReplaySlider";

interface IGameBottom {
    playerState: PlayerState;
    voteSkipPlayers: IPlayerUser[];
    onSkipClick?: () => void;
    onSubmitClue?: (value: string) => void;
    guessCandidate: IGuessCandidate | null;
    guessConfirmationSeconds: number;
    timeLeft: number;
    activeTeamId: number;
    winnerTeamId: number | null;
    isReplay?: boolean;
    replayProgress?: number;
    replayProgressOnChange?: (value: number) => void;
    onReplayPause?: () => void;
    isReplayPaused?: boolean;
}

const GameBottom = ({
    playerState,
    voteSkipPlayers,
    onSkipClick,
    onSubmitClue,
    timeLeft,
    guessCandidate,
    guessConfirmationSeconds,
    activeTeamId,
    winnerTeamId,
    isReplay,
    replayProgress,
    replayProgressOnChange,
    isReplayPaused,
    onReplayPause,
}: IGameBottom) => {
    return (
        <div className="game-bottom">
            {(playerState == PlayerState.Guess ||
                playerState == PlayerState.WatchGuess) && (
                <EndTurnButton
                    guessCandidate={guessCandidate}
                    guessConfirmationSeconds={guessConfirmationSeconds}
                    timeLeft={timeLeft}
                    voteSkipPlayers={voteSkipPlayers}
                    onClick={onSkipClick}
                    disabled={playerState == PlayerState.WatchGuess}
                />
            )}
            {playerState == PlayerState.Clue && (
                <ClueInput onSubmit={onSubmitClue} />
            )}
            {playerState == PlayerState.Wait && (
                <GameStatus
                    activeTeamId={activeTeamId}
                    winnerTeamId={winnerTeamId}
                />
            )}
            {isReplay && (
                <ReplaySlider
                    progress={replayProgress}
                    onChange={replayProgressOnChange}
                    onReplayPause={onReplayPause}
                    isReplayPaused={isReplayPaused}
                />
            )}
        </div>
    );
};

export default GameBottom;
