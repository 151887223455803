import { IStore } from "../../store";

export const selectLobbyStore = (state: IStore) => state.lobby;

export const selectLobbyById = (lobbyId: string) => (state: IStore) =>
    selectLobbyStore(state).data.lobbyById[lobbyId];

export const selectLobbyFormStore = (state: IStore) =>
    selectLobbyStore(state).forms;

export const selectLobbySettingsFormStore = (state: IStore) =>
    selectLobbyStore(state).forms.lobbySettings;

export const selectIsLobbyLoading = (state: IStore) =>
    selectLobbyStore(state).isLoading;

export const selectIsLobbyUpdating = (state: IStore) =>
    selectLobbyStore(state).isUpdating;

export const filterByTeam = (teamId: number) => {
    const lambda = (item: any) => {
        return item.teamId == teamId;
    };
    return lambda;
};

export const selectTeamPlayers =
    (lobbyId: string, teamId: number) => (state: IStore) => {
        const filter = filterByTeam(teamId);
        const lobbyState = selectLobbyById(lobbyId)(state);
        if (lobbyState === undefined) {
            return [];
        }
        return lobbyState.players.filter(filter);
    };

export const selectWordCollections = (state: IStore) =>
    selectLobbyStore(state).data.wordsCollections;

export const selectLobbyPlayerIds = (lobbyId: string) => (state: IStore) => {
    const lobbyStore = selectLobbyById(lobbyId)(state);
    const lobbyStorePlayers = (lobbyStore && lobbyStore.players) || [];
    const exIds = lobbyStorePlayers.map((pl) => pl.id);
    return exIds;
};
