import { useTranslation } from "react-i18next";
import { StatsService } from "../../services/statsService";

interface PlayerCluesStatsTableProps {
    stats: StatsService;
}

export const PlayerCluesStatsTable = ({
    stats,
}: PlayerCluesStatsTableProps) => {
    const { t } = useTranslation();

    if (!stats.totalCluesMaster) {
        return <></>;
    }
    return (
        <table className="table table-sm  overview-table">
            <thead>
                <tr>
                    <th>{t("Clues Stats", { ns: "profile" })}</th>
                    <th>{t("As Operative", { ns: "profile" })}</th>
                    <th>{t("As Spy Master", { ns: "profile" })}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t("Total Clues", { ns: "profile" })}</td>
                    <td>{stats.totalCluesOperative}</td>
                    <td>{stats.totalCluesMaster}</td>
                </tr>
                <tr>
                    <td>{t("Clues Per Match", { ns: "profile" })}</td>
                    <td>{stats.avgCluesPerMatchOperative}</td>
                    <td>{stats.avgCluesPerMatchMaster}</td>
                </tr>
                <tr>
                    <td>{t("Words Per Clue", { ns: "profile" })}</td>
                    <td>{stats.avgWordsPerMatchOperative}</td>
                    <td>{stats.avgWordsPerMatchMaster}</td>
                </tr>
            </tbody>
        </table>
    );
};
