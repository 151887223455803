import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import {
    IGuessCandidate,
    IPlayerUser,
    IWord,
} from "../../store/entities/game/model";
import WordCard from "./WordCard";
import OverlayIcon from "./OverlayIcon";

interface WordsBoardProps {
    words: IWord[];
    serverTimeLeft: number;
    timeLeft: number;
    guessCandidate: IGuessCandidate | null;
    votes: Record<string, IPlayerUser[]>;
    disabled: boolean;
    onWordClick?: (word: IWord) => void;
    isPaused: boolean;
    guessConfirmationSeconds: number;
}

const WordsBoard = (props: WordsBoardProps) => {
    const wordRef = useRef<HTMLDivElement>(null);
    const [style, setStyle] = useState<CSSProperties>({});

    useEffect(() => {
        const wordWidth = wordRef.current && wordRef.current.offsetWidth;
        if (wordWidth) {
            const wordCount = props.words.length;
            const rowSize = Math.floor(Math.sqrt(wordCount));
            const maxWidth = Math.floor(rowSize * (wordWidth + 20));
            setStyle({ maxWidth: maxWidth + "px" });
        }
    }, [wordRef.current && wordRef.current.offsetWidth, props.words.length]);

    return (
        <div
            className="d-flex flex-wrap align-content-start board justify-content-center"
            style={style}
        >
            {props.isPaused && <OverlayIcon className="bi bi-pause-fill" />}
            {props.words.map((word, index) => {
                return (
                    <WordCard
                        divRef={index == 0 && wordRef}
                        players={props.votes[word.text] || []}
                        word={word}
                        key={word.text}
                        disabled={props.disabled}
                        onWordClick={props.onWordClick}
                        guessCandidate={props.guessCandidate}
                        timeLeft={props.timeLeft}
                        guessConfirmationSeconds={
                            props.guessConfirmationSeconds
                        }
                    />
                );
            })}
        </div>
    );
};

export default WordsBoard;
