import { combineReducers, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { getPlayerStats } from "./thunk";
import { IPlayerStats } from "./model";

interface IPlayerStatsStore extends IPlayerStats {
    timestamp: number;
}

interface IStatsStore {
    statsByPlayerId: Record<string, IPlayerStatsStore>;
}

const usersReducer = createReducer(
    { statsByPlayerId: {} } as IStatsStore,
    (builder) => {
        builder.addMatcher(
            isAnyOf(getPlayerStats.fulfilled),
            (state, action) => {
                const stats = action.payload.stats;
                const playerId = action.payload.playerId;
                state.statsByPlayerId[playerId] = {
                    ...stats,
                    timestamp: Date.now(),
                };
            }
        );
    }
);

const isLoadingReducer = createReducer(false, (builder) => {
    builder
        .addMatcher(isAnyOf(getPlayerStats.pending), (state, action) => {
            return true;
        })
        .addMatcher(isAnyOf(getPlayerStats.fulfilled), (state, action) => {
            return false;
        });
});

const reducer = combineReducers({
    data: usersReducer,
    isLoading: isLoadingReducer,
});
export default reducer;
