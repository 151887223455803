import { FormikProvider, useFormik } from "formik";
import { ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import "./UserProfileModal.css";
import FormField from "../formFields/FormField";
import ColorInput from "../formFields/ColorInput";
import LanguageInput from "../formFields/LanguageInput";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { FormErrors, setFormikErrors } from "../../errors";
import { selectRegisterFormStore } from "../../store/entities/identity/selectors";
import { LoadingButton } from "../formFields/LoadingButton";

interface UserProfileUpdateProps {
    onClose: Function;
    onSubmit: (submitted: IProfile) => void;
    onRegistrationClick: () => void;
    onLoginClick: () => void;
    onLogout: Function;
    isOpen: boolean;
    username: string;
    color: string;
    language: string;
    email: string;
    login?: string;
}

export interface IProfile {
    email: string;
    username: string;
    color: string;
    language: string;
}

const UserProfileUpdate = (props: UserProfileUpdateProps) => {
    const { t } = useTranslation();

    let profile = {
        email: props.email,
        username: props.username,
        color: props.color,
        language: props.language,
    };
    const minNameLen = 1;
    const maxNameLen = 64;
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .min(
                minNameLen,
                `${t("Has to be longer than or equal")} ${minNameLen}`
            )
            .max(
                maxNameLen,
                `${t("Has to be shorter than or equal")} ${maxNameLen}`
            ),
        email: Yup.string().email("Not a valid email"),
    });

    const formikData = useFormik({
        initialValues: profile,
        onSubmit: props.onSubmit,
        validationSchema,
    });
    const { handleSubmit } = formikData;
    const form = useSelector(selectRegisterFormStore);
    useEffect(() => {
        setFormikErrors(t, formikData, form.errors as FormErrors);
    }, [form.errors]);

    return (
        <FormikProvider value={formikData}>
            <form className="form-inline" onSubmit={handleSubmit}>
                <ModalBody>
                    {props.login ? (
                        <div className="form-group row mb-2">
                            <div className="col-sm-2 col-form-label">
                                {props.login}
                            </div>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary col-sm-8 offset-sm-2"
                                style={{ width: "300px", marginLeft: "95px" }}
                                onClick={() => props.onLogout()}
                            >
                                {t("Logout")}
                            </button>
                        </div>
                    ) : (
                        <div className="form-group row mb-2">
                            <div className="col-sm-2 col-form-label">
                                {t("Guest Account")}
                            </div>
                            <div className="col-sm-8 offset-sm-2 p-2">
                                <button
                                    className="btn btn-sm btn-outline-secondary mb-2 modal-form-button"
                                    style={{ width: "150px" }}
                                    onClick={() => props.onLoginClick()}
                                >
                                    {t("Log in")}
                                </button>
                                <button
                                    className="btn btn-sm btn-outline-secondary mb-2 modal-form-button"
                                    style={{
                                        marginLeft: "10px",
                                        width: "150px",
                                    }}
                                    onClick={() => props.onRegistrationClick()}
                                >
                                    {t("Register")}
                                </button>
                            </div>
                        </div>
                    )}
                    <FormField
                        name="username"
                        maxLength={64}
                        label={t("Username") || "Username"}
                    />
                    {props.login && (
                        <FormField
                            name="email"
                            label={t("Email") || "Email"}
                            disabled={props.email.length > 0}
                        />
                    )}
                    <FormField
                        name="color"
                        label={t("Color") || "Color"}
                        inputComponent={ColorInput}
                    />
                    <FormField
                        name="language"
                        label={t("Language") || "Language"}
                        inputComponent={LanguageInput}
                    />
                </ModalBody>
                <ModalFooter>
                    <LoadingButton
                        disabled={!formikData.isValid}
                        isLoading={form.isLoading}
                        text={t("Save")}
                    />
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => props.onClose()}
                    >
                        {t("Close")}
                    </button>
                </ModalFooter>
            </form>
        </FormikProvider>
    );
};

export default UserProfileUpdate;
