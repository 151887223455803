import { IConnectedUser } from "../store/entities/users/model";
import "./PlayerColor.css";

interface PlayerColorProps {
    player: IConnectedUser;
}

const PlayerColor = ({ player }: PlayerColorProps) => {
    const style = {
        backgroundColor: player.color,
    };

    const className = player.isConnected
        ? "player-color"
        : "player-color disconnected";

    return <span className={className} style={style}></span>;
};

export default PlayerColor;
