import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../reactExt/hooks";

import TopBar from "../TopBar";
import GameTeam from "../game/GameTeam";
import WordsBoard from "../game/WordBoard";
import { PlayerState } from "../../store/entities/game/model";
import GameTimer from "../game/GameTimer";
import { useReplayGameState } from "../../store/hooks";
import GameBottom from "../game/GameBottom";
import { getMatchEvents } from "../../store/entities/replay/thunk";
import {
    replayProgressChange,
    replaySetTurn,
    resetReplay,
} from "../../store/entities/replay/action";
import { selectReplayData } from "../../store/entities/replay/selectors";
import { replaySetPause } from "../../store/entities/replay/action";
import { Loader } from "../Loader";
import { unwrapResult } from "@reduxjs/toolkit";

const ReplayGame = () => {
    const { matchId: matchId } = useParams<{ matchId: string }>();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const turnNumber = useMemo(() => {
        const turn = queryParams.get("turn");
        if (turn !== null) {
            return parseInt(turn);
        }
        return turn;
    }, [queryParams]);

    const [replayExist, setReplayExist] = useState(true);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [replayState] = useReplayGameState();
    const {
        isLoading,
        playerState,
        team1Players,
        team2Players,
        team1Clues,
        team2Clues,
        team1VoteSkipPlayers,
        team2VoteSkipPlayers,
        wordVotePlayers,
        team1ListState,
        team2ListState,
        replayProgress,
        events,
        isReplayPaused,
        isGamePaused,
    } = replayState;

    const replayData = useSelector(selectReplayData);

    useEffect(() => {
        if (matchId && !isLoading && events === null) {
            const promise = dispatch(getMatchEvents({ matchId }));
            if (turnNumber !== null) {
                promise.then(unwrapResult).then((response) => {
                    dispatch(replaySetTurn(turnNumber));
                });
            }
        } else if (events?.length === 0) {
            setReplayExist(false);
        }
    }, [isLoading, matchId, events]);

    useEffect(() => {
        return () => {
            dispatch(resetReplay());
        };
    }, []);

    const teamStyle = useMemo(() => {
        if (replayData.winnerTeamId === null) {
            return `team-${replayData.activeTeamId}-bg`;
        }
        return `team-${replayData.winnerTeamId}-bg`;
    }, [replayData.activeTeamId, replayData.winnerTeamId]);

    const replayProgressOnChange = useCallback(
        (value: number) => {
            dispatch(replayProgressChange(value));
        },
        [dispatch]
    );
    const onReplayPause = useCallback(() => {
        dispatch(replaySetPause(!isReplayPaused));
    }, [dispatch, isReplayPaused]);

    const rootClasses = `${teamStyle}`;

    return (
        <div className={rootClasses}>
            <TopBar />
            <title>{t("shpiguni")} </title>
            <div className="game-container">
                {isLoading ? (
                    <Loader />
                ) : replayExist ? (
                    <>
                        <GameTeam
                            players={team1Players}
                            voteSkipPlayers={team1VoteSkipPlayers}
                            teamId={1}
                            score={replayData.score[1] as number}
                            clues={team1Clues}
                            matchId={matchId as string}
                            joinDisabled={true}
                            state={team1ListState as PlayerState}
                            isUserHost={false}
                        />
                        <div className="board-container-wrap">
                            <div className="board-container">
                                <div>
                                    <GameTimer
                                        isPlayerTurn={
                                            playerState == PlayerState.Guess ||
                                            playerState == PlayerState.Clue
                                        }
                                        clueHasTakenGuessTime={
                                            replayData.clueHasTakenGuessTime
                                        }
                                        timeLeft={replayData.timeLeft}
                                    />
                                </div>
                                <WordsBoard
                                    words={replayData.words.list}
                                    votes={wordVotePlayers}
                                    disabled={playerState !== PlayerState.Guess}
                                    guessCandidate={replayData.guessCandidate}
                                    timeLeft={replayData.timeLeft}
                                    serverTimeLeft={replayData.serverTimeLeft}
                                    guessConfirmationSeconds={
                                        replayData.guessConfirmationSeconds
                                    }
                                    isPaused={isGamePaused}
                                />
                                <div>
                                    <GameBottom
                                        playerState={playerState}
                                        voteSkipPlayers={
                                            replayData.activeTeamId == 1
                                                ? team1VoteSkipPlayers
                                                : team2VoteSkipPlayers
                                        }
                                        activeTeamId={replayData.activeTeamId}
                                        winnerTeamId={replayData.winnerTeamId}
                                        guessConfirmationSeconds={
                                            replayData.guessConfirmationSeconds
                                        }
                                        guessCandidate={
                                            replayData.guessCandidate
                                        }
                                        timeLeft={replayData.timeLeft}
                                        isReplay={true}
                                        replayProgress={replayProgress}
                                        replayProgressOnChange={
                                            replayProgressOnChange
                                        }
                                        onReplayPause={onReplayPause}
                                        isReplayPaused={isReplayPaused}
                                    />
                                </div>
                            </div>
                        </div>
                        <GameTeam
                            players={team2Players}
                            voteSkipPlayers={team2VoteSkipPlayers}
                            teamId={2}
                            score={replayData.score[2] as number}
                            clues={team2Clues}
                            matchId={matchId as string}
                            joinDisabled={true}
                            state={team2ListState as PlayerState}
                            isUserHost={false}
                        />
                    </>
                ) : (
                    <div className="text-center empty-message">
                        <span>
                            {t("Replay data not found", {
                                ns: "matchReplay",
                            })}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReplayGame;
